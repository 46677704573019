import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { Workbook } from 'exceljs/dist/exceljs.min.js';
import * as fs from 'file-saver';
import {BursaryService} from '../services/bursary/bursary.service';

@Component({
  selector: 'app-documents-table',
  templateUrl: './documents-table.component.html',
  styleUrls: ['./documents-table.component.scss']
})
export class DocumentsTableComponent implements OnInit {

  @Input() tableHeader: string;
  @Input() adminType: string;
  @Input() students: any;
  printing: any = true;
  @ViewChild('divToPrint', {static: false}) divToPrint: ElementRef;
  currentDate: any;
  currentTime: any;
  opportunityRecommendedApplications: any;
  opportunityApprovedApplications: any;
  partner = 'Fasset';
  constructor(
      private bursaryService: BursaryService,
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this.bursaryService.getProfilesCount('Recommended', this.partner).subscribe((data) => {
      this.opportunityRecommendedApplications = data;
    });
    this.bursaryService.getProfilesCount('Approved', this.partner).subscribe((data) => {
      console.log(data);
      this.opportunityApprovedApplications = data;
    });
  }

  print() {
    this.printing = true;
    const printContents = this.divToPrint.nativeElement.innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    setTimeout(() => {
      this.printing = false;
    }, 3000);
  }

  async downloadExcel(extension = '.xlsx') {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('My Sheet');
    console.log(worksheet);
    worksheet.columns = [
      { key: 'id', width: 20, header: 'ID' },
      { key: 'names', width: 20, header: 'NAMES' },
      { key: 'email', width: 20, header: 'EMAIL' },
      { key: 'contact', width: 20, header: 'CONTACT' },
      { key: 'gender', width: 20, header: 'GENDER' },
      { key: 'province', width: 20, header: 'PROVINCE' },
      { key: 'address', width: 20, header: 'ADDRESS' },
      { key: 'status', width: 20, header: 'STATUS' },
    ];

    this.students?.application?.data.forEach( (student) => {
      worksheet.addRow({
        id: student?.id_number ,
        names: student?.first_name,
        email: student?.email,
        contact: student?.contact_number,
        gender: student?.gender,
        province: student?.province,
        address: student?.address,
        status: student?.status,
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, 'Students Full Report ' + this.currentDate + ' - ' + this.currentTime + extension);
  }

  extractDateOfBirth(idNumber: string): Date | null {
    if (!idNumber || idNumber.length !== 13) {
      return null; // ID number is invalid
    }

    // Extract year, month, and day from the ID number
    let year = parseInt(idNumber.substring(0, 2), 10);
    const month = parseInt(idNumber.substring(2, 4), 10);
    const day = parseInt(idNumber.substring(4, 6), 10);

    // Adjust the year if necessary (based on the first two digits)
    const currentYear = new Date().getFullYear();
    year += (year < currentYear % 100) ? 2000 : 1900;

    // Create a Date object
    const dateOfBirth = new Date(year, month - 1, day);

    return dateOfBirth;
  }
}
