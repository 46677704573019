import { Component, OnInit } from '@angular/core';
import {ApiService} from '../services/api.service';
import {NotificationService} from '../services/notification.service';
import {UserModel} from '../models/user.model';
import {UserManagementService} from '../services/user-management/user-management.service';
import {environment} from '../../environments/environment';
import {PartnerModel} from '../models/partner.model';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
  allUsersTab = true;
  allPartnersTab = true;
  addPartnerTab = false;
  archivedPartnersTab = false;
  addPartnersTabText = 'Add Partner';
  addUsersTab: boolean;
  addUsersTabText = 'Add User';
  archivedUsersTab: boolean;
  userModel: UserModel;
  partnerModel: PartnerModel;
  users: any;
  partners: any;
  // tslint:disable-next-line:variable-name
  action_string: string;
  private baseUrl;
  private helpdeskBaseUrl;
  internalUsersTab = true;
  partnersManagementTab = false;

  constructor(
      private apiService: ApiService,
      private notify: NotificationService,
      private userManagementService: UserManagementService
  ) {
    this.userModel = new UserModel(null, null);
    this.partnerModel = new PartnerModel(null, null);
    this.baseUrl = environment.baseUrl;
    this.helpdeskBaseUrl = environment.helpdeskBaseUrl;
  }

  ngOnInit(): void {
    this.getUsers('all');
    this.getPartners(0);
  }

  // tslint:disable-next-line:variable-name
  onTab(number) {
    this.allUsersTab = false;
    this.addUsersTab = false;
    this.archivedUsersTab = false;
    this.addUsersTabText = 'Add User';
    this.userModel = new UserModel(null, null);

    if (number === '1') {
      this.allUsersTab = true;
      this.getUsers('all');
    } else if (number === '2') {
      this.archivedUsersTab = true;
      this.getUsers('archived');
    } else if (number === '3') {
      this.addUsersTab = true;
    }
  }

  // tslint:disable-next-line:variable-name
  onPartnersTab(number) {
    this.allPartnersTab = false;
    this.addPartnerTab = false;
    this.archivedPartnersTab = false;
    this.addPartnersTabText = 'Add Partner';
    this.partnerModel = new PartnerModel(null, null);
    this.userModel = new UserModel(null, null);

    if (number === '1') {
      this.allPartnersTab = true;
      this.getPartners(0);
    } else if (number === '2') {
      this.archivedPartnersTab = true;
      this.getPartners(1);
    } else if (number === '3') {
      this.addPartnerTab = true;
    }
  }

  saveOrUpdateUser() {
    if (this.userModel.uuid === '') {
      this.saveUser();
    } else {
      this.updateUser();
    }
  }

  saveOrUpdatePartner() {
    if (this.partnerModel.uuid === '') {
      this.savePartner();
    } else {
      this.updateUser();
    }
  }

  saveUser() {
    this.userModel.type = 'ADMIN';
    this.apiService.post_private(`register`, this.userModel).then((res) => {
      console.log('Register Response : ', res);
      if (res) {
        this.apiService.helpdeskRegister(`add_user`, res);
        this.onTab('3');
        this.notify.successBasicNotification(
            'Success',
            () => {
            }
        );
      }
    }).catch((error) => {
      if (error.error && error.error.errors && error.error.errors.email) {
        const emailErrorMessage = error.error.errors.email[0];
        this.notify.errorBasicNotification('Registration Failed', emailErrorMessage);
      } else if (error.error && error.error.errors && error.error.errors.password) {
        const passwordErrorMessage = error.error.errors.password[0];
        this.notify.errorBasicNotification('Registration Failed', passwordErrorMessage);
      } else {
        console.error('Error during registration:', error);
      }
    });
  }

  savePartner() {
    this.partnerModel.user_uuid = localStorage.getItem('user_uuid');
    this.apiService.post_private(`partner/store`, this.partnerModel).then((res) => {
      console.log('Partner Response : ', res);
      if (res) {
        this.userModel.type = 'ADMIN';
        this.userModel.admin_type = 'CLIENT';
        this.userModel.related_uuid = res?.partner?.uuid;
        this.saveUser();
        this.onPartnersTab('3');
      }
    }).catch((error) => {
      if (error.error && error.error.errors && error.error.errors.email) {
        const emailErrorMessage = error.error.errors.email[0];
        this.notify.errorBasicNotification('Registration Failed', emailErrorMessage);
      } else if (error.error && error.error.errors && error.error.errors.password) {
        const passwordErrorMessage = error.error.errors.password[0];
        this.notify.errorBasicNotification('Registration Failed', passwordErrorMessage);
      } else {
        console.error('Error during registration:', error);
      }
    });
  }

  updateUser() {
    this.userManagementService.post(`user-management/update-user`, this.userModel).then((res) => {
      console.log('Update Response : ', res);
      if (res) {
        this.apiService.helpdeskRegister(`update-user`, res);
        this.userModel = new UserModel(res?.user, null);
        this.notify.successBasicNotification(
            'Success',
            () => {
            }
        );
      }
    }).catch((error) => {
      if (error.error && error.error.errors && error.error.errors.email) {
        const emailErrorMessage = error.error.errors.email[0];
        this.notify.errorBasicNotification('Update Failed', emailErrorMessage);
      } else if (error.error && error.error.errors && error.error.errors.password) {
        const passwordErrorMessage = error.error.errors.password[0];
        this.notify.errorBasicNotification('User Update Failed', passwordErrorMessage);
      } else {
        console.error('Error during update:', error);
      }
    });
  }

  getUsers(status: string) {
    this.userManagementService.get(null, `user-management/users/${status}`).subscribe(
        (res: any) => {
          this.users = res.users;
        },
        (error: any) => {
          console.error('Error:', error);
        }
    );
  }

  getPartners(status: number) {
    this.userManagementService.get(null, `partner/get/${status}`).subscribe(
        (res: any) => {
          this.partners = res.partners;
        },
        (error: any) => {
          console.error('Error:', error);
        }
    );
  }

  deleteOrActivateUser(uuid: string, action: string) {
    this.action_string = 'Delete';
    if (action === 'activate') {
      this.action_string = 'Activate';
    }
    this.notify.confirmDialog(`${this.action_string} this user?.`, () => {
      // Delete From Ubuntu Main System
      this.userManagementService.get(this.baseUrl, `user-management/${action}-user/${uuid}`).subscribe(
          // tslint:disable-next-line:variable-name
          (first_res: any) => {
            if (first_res.status === 'success') {
              // NowDelete User From Helpdesk
              this.userManagementService.get(this.helpdeskBaseUrl, `user-management/${action}-user/${uuid}`).subscribe(
                  () => {
                    if (first_res.status === 'success') {
                      this.notify.successBasicNotification('Success', `User ${this.action_string}d Successfully`);
                      if (action === 'activate') {
                        this.getUsers('archived');
                      } else {
                        this.ngOnInit();
                      }
                    }
                  },
                  (error: any) => {
                    console.log(error);
                    this.notify.errorBasicNotification(`Failed to ${action} user`, 'Please refresh the page');
                  }
              );
            }
          },
          (error: any) => {
            console.log(error);
            this.notify.errorBasicNotification(`Failed to ${action} user`, 'Please refresh the page');
          }
      );
    });
  }

  deleteOrActivatePartner(uuid: string, action: string) {
    this.action_string = 'Delete';
    if (action === 'activate') {
      this.action_string = 'Activate';
    }
    this.notify.confirmDialog(`${this.action_string} this partner?.`, () => {
      // Delete From Ubuntu Main System
      this.userManagementService.get(this.baseUrl, `partner/${action}-user/${uuid}`).subscribe(
          // tslint:disable-next-line:variable-name
          (res: any) => {
            if (res.status === 'success') {
              this.notify.successBasicNotification('Success', `Partner ${this.action_string}d Successfully`);
              if (action === 'activate') {
                this.getPartners(0);
              } else {
                this.ngOnInit();
              }
            }
          },
          (error: any) => {
            console.log(error);
            this.notify.errorBasicNotification(`Failed to ${action} partner`, 'Please refresh the page');
          }
      );
    });
  }

  viewUser(user: any) {
    this.onTab('3');
    this.addUsersTabText = 'Update User';
    this.userModel = new UserModel(user, null);
  }

  viewPartner(partner: any) {
    this.onPartnersTab('3');
    this.addPartnersTabText = 'Update Partner';
    this.partnerModel = new PartnerModel(partner, null);
    this.userModel = new UserModel(partner?.company_admin, null);
    console.log(this.partnerModel);
    console.log(this.userModel);
  }

  onKPIClick(type: string) {
    this.internalUsersTab = false;
    this.partnersManagementTab = false;

    if (type === '1') {
      this.internalUsersTab = true;
    } else if (type === '2') {
      this.partnersManagementTab = true;
    }
  }
}
