import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-element',
  templateUrl: './form-element.component.html',
  styleUrls: ['./form-element.component.scss']
})
export class FormElementComponent implements OnInit {
  ckeConfig: any;
  public editorValue: string = '';
  constructor() {
    this.ckeConfig = {
      allowedContent: false,
      // extraPlugins: 'divarea',
      forcePasteAsPlainText: true,
      removeButtons: 'Maximize,Save'
    };
  }

  ngOnInit(): void {
  }

}
