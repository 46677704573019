import { Component, ViewChild, OnInit } from "@angular/core";
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexFill,
  ApexMarkers,
  ApexYAxis,
  ApexXAxis,
  ApexTooltip,
  ApexStroke,
  ApexAnnotations
} from "ng-apexcharts";
import { dataSeries } from "./series-data";
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  annotations: ApexAnnotations;
  colors: any;
  toolbar: any;
};

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {
  public series: ApexAxisChartSeries;
  public chart: ApexChart;
  public dataLabels: ApexDataLabels;
  public markers: ApexMarkers;
  public title: ApexTitleSubtitle;
  public fill: ApexFill;
  public yaxis: ApexYAxis;
  public xaxis: ApexXAxis;
  public tooltip: ApexTooltip;
  public chartOptions: Partial<ChartOptions>;

  constructor() {
    this.chartOptions = {
      colors: ["#9367b4"],
    }
    this.initChartData();
  }
  ngOnInit(): void {
  }

  public initChartData(): void {
    let ts2 = 1484418609090;
    let dates = [];
    for (let i = 0; i < 120; i++) {
      ts2 = ts2 + 86400000;
      dates.push([ts2, dataSeries[1][i].value]);
    }

    this.series = [
      {
        name: "Ubuntu Edulink",
        data: dates
      }
    ];
    this.chart = {
      type: "area",
      stacked: false,
      height: 450,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        show: true,
        autoSelected: "zoom"
      }
    };
    this.dataLabels = {
      enabled: true
    };
    this.markers = {
      size: 0
    };
    this.title = {
      text: "",
      align: "left"
    };
    this.fill = {
      type: "gradient",
      colors: ['#9367B4'],
      gradient: {
        gradientToColors: ['#9367B4', '#17c2d7'],
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100]
      }
    };
    this.yaxis = {
      labels: {
        formatter: function (val) {
          return (val / 1000000).toFixed(0);
        }
      },
      title: {
        text: "Submitted Applications Volume"
      }
    };
    this.xaxis = {
      type: "datetime"
    };
    // this.tooltip = {
    //   shared: false,
    //   y: {
    //     formatter: function (val) {
    //       return (val / 1000000).toFixed(0);
    //     }
    //   }
    // };
  }
}
