export class Profile {

  public uuid = '';
  // tslint:disable-next-line:variable-name
  public user_uuid = '';
  public initials = '';
  // tslint:disable-next-line:variable-name
  public first_name = '';
  // tslint:disable-next-line:variable-name
  public middle_name = '';
  public surname = '';
  public gender = '';
  public ethnicity = '';
  // tslint:disable-next-line:variable-name
  public id_type = '';
  // tslint:disable-next-line:variable-name
  public id_number = '';
  public disability = '';
  public province = '';
  public city = '';
  public address = '';
  // tslint:disable-next-line:variable-name
  public profile_picture = '';
  // tslint:disable-next-line:variable-name
  public hobbies_json = '';
  // tslint:disable-next-line:variable-name
  public archievements_json = '';
  // tslint:disable-next-line:variable-name
  public household_joint_income = '';
  // tslint:disable-next-line:variable-name
  public created_at = '';
  // tslint:disable-next-line:variable-name
  public updated_at = '';
  // tslint:disable-next-line:variable-name
  public contact_number = '';
  // tslint:disable-next-line:variable-name
  public contact_number_2 = '';
  public occupation = '';
  // tslint:disable-next-line:variable-name
  public student_number = '';
  // tslint:disable-next-line:variable-name
  public disability_evidence = '';
  public email = '';
  public password = '';
  // tslint:disable-next-line:variable-name
  public bursary_agreement_signature;
  // tslint:disable-next-line:variable-name
  public password_confirmation = '';
  public status = '';


  // tslint:disable-next-line:variable-name
  constructor(data, user_uuid = '', user_type = '', user: any) {
    this.user_uuid = user_uuid;
    if (data !== null) {
      this.uuid = data.uuid?.toString();
      if (user_type === 'ADMIN') {
        this.email = data.email;
        this.first_name = data.name;
      } else {
        this.bursary_agreement_signature = data?.bursary_agreement_signature;
        this.initials = data.initials;
        this.first_name = data.first_name;
        this.middle_name = data.middle_name;
        this.gender = data.gender;
        this.ethnicity = data.ethnicity;
        this.id_type = data.id_type;
        this.id_number = data.id_number;
        this.disability = data.disability;
        this.province = data.province;
        this.city = data.city;
        this.address = data.address;
        this.profile_picture = data.profile_picture;
        this.hobbies_json = data.hobbies_json;
        this.archievements_json = data.archievements_json;
        this.household_joint_income = data.household_joint_income;
        this.email = data.user.email;
        this.contact_number_2 = data.contact_number_2;
        this.occupation = data.occupation;
        this.student_number = data.student_number;
        this.status = data.student_number;
      }
      this.surname = data.surname;
      this.contact_number = data.contact_number;
      this.created_at = data.created_at;
      this.updated_at = data.updated_at;
    } else {
      this.email = user?.email;
    }
  }
}
