import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class QualificationNameService {
    private baseUrl;

    constructor(private http: HttpClient) {
        this.baseUrl = environment.baseUrl;
    }

    // tslint:disable-next-line:variable-name
    getQualificationNames(qualification_type): Observable<string[]> {
        const universitiesUrl = `${this.baseUrl}qualification-names/${qualification_type}`;
        return this.http.get<string[]>(universitiesUrl);
    }
}
