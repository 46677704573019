import {Component, ElementRef, Input, NgModule, OnInit, QueryList, TemplateRef, ViewChildren} from '@angular/core';
import {NotificationService} from '../../../../../services/notification.service';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {ApiService} from '../../../../../services/api.service';
import {FormsModule} from '@angular/forms';
import {AuthService} from '../../../../../services/auth.service';
import {environment} from '../../../../../../environments/environment';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.scss']
})

@NgModule({
  imports: [
    FormsModule,
  ]
})
export class ApplyComponent implements OnInit {

  @Input() opportunity: any;
  // tslint:disable-next-line:variable-name
  @Input() profile_completion: string;
  @Input() userType: string;
  @ViewChildren('checkboxes') checkboxes: QueryList<ElementRef>;

  // tslint:disable-next-line:variable-name
  student_motivation = '';
  attachments: any = [];
  student: any;
  apiBaseUrl: any = environment.apiPublic;
  homeEX4Tab = true;
  motivationEX4Tab = false;
  termsEX4Tab = false;
  unemploymentDeclarationTab = false;
  private modalRef: BsModalRef;
  declareUnemployment = false;
  testString: any = 'I, learn, coding, from, codedamn'

  constructor(
    private modalService: BsModalService,
    private notify: NotificationService,
    public router: Router,
    public apiService: ApiService,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.loadUser();

    const documentsObject = this.opportunity.document_checklist_json;

    const documentsArray = Object.keys(documentsObject).map(key => ({
      name: documentsObject[key]
    }));

    console.log(documentsArray);
  }
  loadUser(): void {
    this.authService.fetchUser().then((user) => {
      console.log('STUDENT DETAILS : ');
      console.log(user);
      this.student = user;
    });
  }
  uploadNewDocuments() {
    this.notify.confirmDialog('By clicking yes you will be redirected to your file management.', () => {
      // this.router.navigate(['/student/filemanager']);
      window.open('/student/filemanager', '_blank');
    });
  }
  submitApplication(opportunityUuid: string) {

    if (this.student_motivation === '') {
      Swal.fire('Student Application Motivation Should Be Filled In to Submit', '', 'warning');
      return;
    }
    const dataToSend = {
      opportunity_uuid: opportunityUuid,
      profile_uuid: localStorage.getItem('profile_uuid'),
      related_uuid: '',
      student_motivation: this.student_motivation,
      status: 'SUBMITTED',
      attachments: this.attachments
    };
    this.apiService.post_private('application/store', dataToSend).then((response) => {
      console.log(response);
      if (response?.status === 'success') {
        Swal.fire('Application Submitted!', response?.message || 'Your application has been submitted successfully!', 'success');
      } else {
        Swal.fire('Application Not Submitted!', response?.message || 'Failed!', 'error');
      }
    }, (error) => {
      console.log('Error Submitting Application:', error);
    });
  }
  selectAttchment(ev, uuid, attachment) {
    if (ev.target.checked) {
      // Pushing the object into array
      this.attachments.push(attachment);
      console.log('ADDED TO SELECTED FILES : \n'); console.log(this.attachments);
    } else {
      const removeIndex = this.attachments.findIndex(itm => itm.uuid === attachment.uuid);
      console.log(removeIndex);
      if (removeIndex !== -1) {
        this.attachments.splice(removeIndex, 1);
        console.log('REMOVED FROM SELECTED FILES : \n'); console.log(this.attachments);
      }
    }

    // Duplicates the obj if we uncheck it
    // How to remove the value from array if we uncheck it
  }

  // application wizzard / tabs
  // tslint:disable-next-line:variable-name
  onTabEX4(number) {
    this.homeEX4Tab = false;
    this.motivationEX4Tab = false;
    this.termsEX4Tab = false;
    this.unemploymentDeclarationTab = false;

    if (number === '1') {
      this.homeEX4Tab = true;
    } else if (number === '2') {
      this.motivationEX4Tab = true;
    } else if (number === '3') {
      this.termsEX4Tab = true;
    } else if (number === '4') {
      this.unemploymentDeclarationTab = true;
    }
  }

  resetSelection() {
    this.attachments = [];
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-xl',
      backdrop: 'static',
    });
  }

  jsonParse(str) {
    return JSON.parse(str);
  }
}
