import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {Router} from '@angular/router';
import {SessionHandlerService} from '../../../services/session/session-handler.service';
import {NotificationService} from '../../../services/notification.service';
import {FileUploadService} from '../../../services/upload/file-upload.service';
import {Profile} from '../../../models/profile.model';
import {environment} from '../../../../environments/environment';
import {Attachment} from '../../../models/attachment.model';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class StudentEmailComponent implements OnInit {
  user: any;
  apiBaseUrl: any;
  profileModel: any;
  // tslint:disable-next-line:max-line-length
  constructor(public apiService: ApiService, public router: Router, public sessionHandler: SessionHandlerService,
              private notify: NotificationService, private uploadService: FileUploadService) {
    this.profileModel = new Profile(null, null, null, this.user);
    this.apiBaseUrl = environment.apiPublic;
  }
  ngOnInit(): void {
    this.fetchUser();
  }
  fetchUser() {
    return this.apiService.get_private('user').then( data => {
      this.user = data;
      this.profileModel = new Profile(data.profile, data.uuid, null, null);
    });
  }

}
