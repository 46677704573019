import {Component, NgZone, OnInit} from '@angular/core';
import {AuthService} from '../../../../services/auth.service';
import {Profile} from '../../../../models/profile.model';
import Swal from 'sweetalert2';
import {ApiService} from '../../../../services/api.service';
import {NotificationService} from '../../../../services/notification.service';
import {Router} from '@angular/router';
import {FormValidatorService} from '../../../../services/form-validator/form-validator.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  isFull: boolean;
  isFull1: any;
  userType: any;
  user: any;
  profileModel: Profile;

  // tslint:disable-next-line:ban-types
  address: Object;
  // tslint:disable-next-line:ban-types
  establishmentAddress: Object;
  formattedAddress: string;
  formattedEstablishmentAddress: string;
  phone: string;

  constructor(
    public authService: AuthService,
    public apiService: ApiService,
    private notify: NotificationService,
    public router: Router,
    public zone: NgZone,
    public formValidator: FormValidatorService
  ) {
    this.userType = localStorage.getItem('user_type');
  }
  // tslint:disable-next-line:variable-name
  fullScreenSection(number) {
    if (number === 1) {
      if (this.isFull) {
        this.isFull = false;
      } else {
        this.isFull = true;
      }
    } else if (number === 2) {
      if (this.isFull1) {
        this.isFull1 = false;
      } else {
        this.isFull1 = true;
      }
    }
  }
  ngOnInit(): void {
    this.loadUser();
  }
  loadUser(): void {
    this.authService.fetchUser().then((user) => {
      this.user = this.authService.getUser();
      this.profileModel = new Profile(this.user, this.user.uuid, 'ADMIN', null);
      console.log(this.profileModel);
    });
  }
  updateProfile(type) {
    if (this.profileModel.email !== this.user.email && type === 'email') {
      Swal.fire({
        title: 'Email Update Confirmation',
        text: 'If you update your email, you will be required to verify the new email and log in again. Are you sure you want to proceed?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, update email & logout',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.isConfirmed) {
          this.performUpdate(type);
          this.router.navigate(['/login']);
        } else {
          Swal.fire('Email Update Canceled', 'Your email has not been updated.', 'info');
        }
      });
    } else {
      this.performUpdate(type);
    }
  }
  performUpdate(type) {
    // @ts-ignore
    this.profileModel.type = type;
    this.apiService.post_private('profile/update/' + this.profileModel.uuid + '/admin', this.profileModel).then((response) => {
      console.log(response);
      if (response.status === 'success') {
        Swal.fire('Profile Updated!', response?.message || 'Nice! Your profile has been safely updated!', 'success');
        this.loadUser();
      } else {
        Swal.fire('Profile Failed To Update!', response?.message, 'error');
      }
    }, (error) => {
      console.log(error);
      this.notify.confirmDialog('Some Error Occurred. Do You Want to reload your profile and try again?', this.loadUser(),
          'Create Student Profile', 'error',
      );
    });
  }
  getAddress(place: object) {
    this.address = place['formatted_address'];
    this.phone = this.getPhone(place);
    this.formattedAddress = place['formatted_address'];
    this.zone.run(() => this.formattedAddress = place['formatted_address']);
  }
  getEstablishmentAddress(place: object) {
    this.establishmentAddress = place['formatted_address'];
    this.phone = this.getPhone(place);
    this.formattedEstablishmentAddress = place['formatted_address'];
    this.zone.run(() => {
      this.formattedEstablishmentAddress = place['formatted_address'];
      this.phone = place['formatted_phone_number'];
    });
  }
  getAddrComponent(place, componentTemplate) {
    let result;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];
      if (componentTemplate[addressType]) {
        result = place.address_components[i][componentTemplate[addressType]];
        return result;
      }
    }
    return;
  }
  getStreetNumber(place) {
    // tslint:disable-next-line:one-variable-per-declaration
    const COMPONENT_TEMPLATE = { street_number: 'short_name' },
      streetNumber = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return streetNumber;
  }
  getStreet(place) {
    // tslint:disable-next-line:one-variable-per-declaration
    const COMPONENT_TEMPLATE = { route: 'long_name' },
      street = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return street;
  }
  getCity(place) {
    // tslint:disable-next-line:one-variable-per-declaration
    const COMPONENT_TEMPLATE = { locality: 'long_name' },
      city = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return city;
  }
  getState(place) {
    // tslint:disable-next-line:one-variable-per-declaration
    const COMPONENT_TEMPLATE = { administrative_area_level_1: 'short_name' },
      state = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return state;
  }
  getDistrict(place) {
    // tslint:disable-next-line:one-variable-per-declaration
    const COMPONENT_TEMPLATE = { administrative_area_level_2: 'short_name' },
      state = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return state;
  }
  getCountryShort(place) {
    // tslint:disable-next-line:one-variable-per-declaration
    const COMPONENT_TEMPLATE = { country: 'short_name' },
      countryShort = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return countryShort;
  }
  getCountry(place) {
    // tslint:disable-next-line:one-variable-per-declaration
    const COMPONENT_TEMPLATE = { country: 'long_name' },
      country = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return country;
  }
  getPostCode(place) {
    // tslint:disable-next-line:one-variable-per-declaration
    const COMPONENT_TEMPLATE = { postal_code: 'long_name' },
      postCode = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return postCode;
  }
  getPhone(place) {
    // tslint:disable-next-line:one-variable-per-declaration
    const COMPONENT_TEMPLATE = { formatted_phone_number: 'formatted_phone_number' },
      phone = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return phone;
  }
  // Validation
  onKeyUp(event: any) {
    const inputValue: string = event.target.value;
    // Check if the length of the input value is greater than or equal to 10
    if (inputValue.length >= 13) {
      // Your logic after 13 letters here
      this.formValidator.validateID(inputValue);
    }
  }
}
