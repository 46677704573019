import { Component, OnInit } from '@angular/core';
import {ApiService} from '../services/api.service';

@Component({
  selector: 'app-document400',
  templateUrl: './document400.component.html',
  styleUrls: ['./document400.component.scss']
})
export class Document400Component implements OnInit {
  students: any;
  adminType = localStorage.getItem('admin_type');
  tableHeader = 'Approved & Recommended';
  constructor(
      public apiService: ApiService,
  ) {}

  ngOnInit(): void {
    this.loadStudents();
  }

  loadStudents() {
    this.apiService.get_public(`profile/by_status/Approved-Recommended/Fasset`).then((response) => {
      console.log(response);
      this.students = response;
    }, (error) => {
      console.log(error);
    });
  }
}
