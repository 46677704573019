export class InstitutionModel {

  public uuid = '';
  // tslint:disable-next-line:variable-name
  public profile_uuid = '';
  // tslint:disable-next-line:variable-name
  public student_number = '';
  // tslint:disable-next-line:variable-name
  public bursary_type = 'Financial Assistance';
  // tslint:disable-next-line:variable-name
  public year_of_study = '';
  // tslint:disable-next-line:variable-name
  public academic_year = '';
  // tslint:disable-next-line:variable-name
  public institution_type = '';
  // tslint:disable-next-line:variable-name
  public institution_name = '';
  // tslint:disable-next-line:variable-name
  public qualification_type = '';
  // tslint:disable-next-line:variable-name
  public qualification_name = '';


  // tslint:disable-next-line:variable-name
  constructor(data, profile_uuid = '' ) {
    this.profile_uuid = profile_uuid;
    if (data !== null && data?.student_number) {
      console.log(data);
      this.uuid = data.uuid?.toString();
      this.student_number = data?.student_number || '';
      this.bursary_type = data?.bursary_type;
      this.year_of_study = data?.year_of_study;
      this.academic_year = data?.academic_year;
      this.institution_type = data?.institution_type;
      this.institution_name = data?.university?.institution_name;
      this.qualification_type = data?.qualification_type;
      this.qualification_name = data?.qualification_name;
    } else {
      const today = new Date();
      const currentMonth = today.getMonth() + 1;

      const academicYear = currentMonth <= 7 ? today.getFullYear() : today.getFullYear() + 1;

      this.academic_year = academicYear.toString();
    }
  }
}
