import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import {SessionHandlerService} from '../../../services/session/session-handler.service';
import {NotificationService} from '../../../services/notification.service';
import {ApiService} from '../../../services/api.service';
import {Attachment} from '../../../models/attachment.model';
import {AuthService} from '../../../services/auth.service';
import {Profile} from '../../../models/profile.model';
import {environment} from '../../../../environments/environment';
@Component({
  selector: 'app-leftmenu',
  templateUrl: './leftmenu.component.html',
  styleUrls: ['./leftmenu.component.scss']
})
export class LeftmenuComponent implements OnInit {

  isCollapsed = true;
  Pagecollapse = true;
  userType: any;
  adminType: any;
  companyLogo: any;
  user: any;
  profileModel: Profile;
  attachmentModel: Attachment;
  attachmentFiles: any;
  profilePercentage: any;

  // tslint:disable-next-line:max-line-length
  apiBaseUrl: any;
  helpdeskPublic: any;
  partnerOpportunities: any;
  companyUuid: any;
  constructor(
    private router: Router,
    @Inject(AppComponent) private app: AppComponent,
    private sessionHandle: SessionHandlerService,
    private notify: NotificationService,
    private apiService: ApiService,
    public authService: AuthService
  ) {
    this.userType = localStorage.getItem('user_type');
    this.adminType = localStorage.getItem('admin_type');
    this.companyLogo = localStorage.getItem('company_logo');
    this.companyUuid = localStorage.getItem('related_uuid');
    this.apiBaseUrl = environment.apiPublic;
    this.helpdeskPublic = environment.helpdeskPublic;
    if ((this.router.url).includes('ui-element')) {
      this.isCollapsed = false;
    }
    if ((this.router.url).includes('pages')) {
      this.Pagecollapse = false;
    }
  }

  ngOnInit(): void {
    this.loadUser();
  }

  loadUser(): void {
    this.authService.fetchUser().then((user) => {
      this.user = this.authService.getUser();
      this.profileModel = this.authService.getProfileModel();

      if (this.profileModel?.uuid) {
        this.attachmentModel = new Attachment(null, this.profileModel.uuid);
        this.attachmentFiles = this.authService.getAttachments();
      }
    }).catch();
    this.getPartnerOpportunity(this.companyUuid);
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    setTimeout(() => {

      if (this.router.url.includes('cryptocurrency')) {
        this.app.themeColor('o');
      } else if (this.router.url.includes('campaign')) {
        this.app.themeColor('b');
      } else if (this.router.url.includes('ecommerce')) {
        this.app.themeColor('a');
      } else {
        this.app.themeColor('c');
      }
      const className = document.getElementById('left-sidebar');
      const colorClassName = document.getElementsByClassName('theme-bg');
      if (sessionStorage.getItem('Sidebar') !== '' && sessionStorage.getItem('Sidebar') != null) {
        className.classList.add(sessionStorage.getItem('Sidebar'));
      }
      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < colorClassName.length; index++) {
        const element = colorClassName[index];
        if (sessionStorage.getItem('GradientColor') !== '' && sessionStorage.getItem('GradientColor') != null) {
          element.classList.add('gradient');
        } else {
          element.classList.remove('gradient');
        }
      }
    });
  }

  showDropDown() {
    document.getElementById('drp').classList.toggle('ShowDiv');
  }

  toggleMenu() {
    const body = document.getElementsByTagName('body')[0];

    if (body.classList.contains('toggle_menu_active')) {
      body.classList.remove('toggle_menu_active');
    } else {
      body.classList.add('toggle_menu_active');
    }
  }
  cToggoleMenu() {
    const body = document.getElementsByTagName('body')[0].classList.remove('offcanvas-active');
    document.getElementsByClassName('overlay')[0].classList.toggle('open');
  }
  logout() {
    const router = this.router;
    const sessionHandle = this.sessionHandle;
    const apiService = this.apiService;
    console.log('Logging Out User : ');
    // tslint:disable-next-line:only-arrow-functions
    this.notify.confirmDialog('Logged out successfully ?', function() {
      // tslint:disable-next-line:only-arrow-functions
      apiService.post_private('logout', {}).then(function(data) {
        sessionHandle.destroySession(router);
      });
    });
  }
  // tslint:disable-next-line:variable-name
  getPartnerOpportunity(company_uuid) {
    console.log(company_uuid);
    this.apiService.get_private(`/opportunity/opportunity/${company_uuid}`).then((data) => {
      console.log(data);
      this.partnerOpportunities = data.opportunities;
    });
  }
}
