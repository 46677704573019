import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {environment} from '../../../../../environments/environment';
import {Profile} from '../../../../models/profile.model';
import {SchoolDetailsComponent} from '../../../student/pages/profile/school-details/school-details.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {InstitutionComponent} from '../../../student/pages/profile/institution/institution.component';
import {
  ViewApplicationComponent
} from '../../../student/bursary/view-opportunity/apply/view-application/view-application.component';

@Component({
  selector: 'app-view-student',
  templateUrl: './view-student.component.html',
  styleUrls: ['./view-student.component.scss']
})
export class ViewStudentComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  @Input() student_profile: any;
  apiBaseUrl: any;
  showPercentage: any;
  profilePercentage: any;
  modalRef: BsModalRef;
  profileModel: Profile;
  homeTab = true;
  profileTab = false;
  communicationTab = false;
  qualificationsTab = false;
  schoolDetailsTab = false;
  digitalSignatureTab = false;
  documentTab = false;
  qualificationDetailsEX1Tab = true;
  schoolDetailsEX2Tab = false;
  message: any;
  emailCommunications = false;
  smsCommunications = false;
  communicationType = 'SMS';
  applicationStatus = 'ALL';
  allApplications = false;
  pendingApplications = false;
  approvedApplications = false;
  rejectedApplications = false;
  apiPublic: any = environment.apiPublic;
  randomIndex: any = Math.random();

  constructor(
    public apiService: ApiService,
    private modalService: BsModalService,
  ) {
    this.apiBaseUrl = environment.apiPublic;
    this.showPercentage = true;
    this.profilePercentage = 5;
  }

  ngOnInit(): void {
    console.log(this.student_profile);
    this.communicationKPIClick('SMS');
    this.applicationKPIClick('ALL');
  }

  getStudentProfilePercentage() {
    this.profileModel = new Profile(this.student_profile, this.student_profile.user.uuid, null, null);
    if (this.profileModel.uuid && this.profileModel.uuid !== '') {
      const percentage = (Object.keys(this.profileModel).filter(
          x => this.profileModel[x] !== null).length / Object.keys(this.profileModel).length
      );
      this.profilePercentage = Math.round((percentage * 100));
    }
  }
  // tslint:disable-next-line:variable-name
  viewSchoolDetails(school_details: any) {
    this.modalRef = this.modalService.show(SchoolDetailsComponent, {
      class: 'modal-dialog-centered modal-xl',
      initialState: {
        school_details
      },
      backdrop: 'static',
    });
  }
  viewInstitutionDetails(institution: any) {
    this.modalRef = this.modalService.show(InstitutionComponent, {
      class: 'modal-dialog-centered modal-xl',
      initialState: {
        institution
      },
      backdrop: 'static',
    });
  }
  // tslint:disable-next-line:variable-name
  private onTab(number) {
    this.homeTab = false;
    this.profileTab = false;
    this.documentTab = false;
    this.communicationTab = false;
    this.qualificationsTab = false;
    this.schoolDetailsTab = false;
    this.digitalSignatureTab = false;

    if (number === '1') {
      this.homeTab = true;
    } else if (number === '2') {
      this.documentTab = true;
    } else if (number === '3') {
      this.profileTab = true;
    } else if (number === '4') {
      this.communicationTab = true;
    } else if (number === '5') {
      this.qualificationsTab = true;
    } else if (number === '6') {
      this.schoolDetailsTab = true;
    } else if (number === '7') {
      this.digitalSignatureTab = true;
    }
  }
  // tslint:disable-next-line:variable-name
  educationTabs(number) {
    this.qualificationDetailsEX1Tab = false;
    this.schoolDetailsEX2Tab = false;

    if (number === '1') {
      this.qualificationDetailsEX1Tab = true;
    } else if (number === '2') {
      this.schoolDetailsEX2Tab = true;
    }
  }
  viewApplication(application: any) {
    this.modalRef = this.modalService.show(ViewApplicationComponent, {
      class: 'modal-dialog-centered modal-xl',
      initialState: {
        application
      }
    });
  }

  closeModal(): void {
    if (this.student_profile.modal_number) {
      this.modalService.hide(this.student_profile.modal_number);
      this.student_profile.modal_number = this.student_profile.modal_number - 1;
    } else {
      this.modalService.hide(2);
    }
  }
  viewMessage(template, message) {
    console.log(message);
    this.message = message;
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-xl',
      backdrop: 'static',
    });
  }
  // tslint:disable-next-line:variable-name
  communicationKPIClick(communication_type: string) {
    this.communicationType = communication_type;
    if (communication_type === 'EMAIL') {
      this.emailCommunications = true; // Show Email
      this.smsCommunications = false; // Hide SMS communications
    } else if (communication_type === 'SMS') {
      this.smsCommunications = true; // Show SMS
      this.emailCommunications = false; // Hide email communications
    }
  }

  // tslint:disable-next-line:variable-name
  applicationKPIClick(application_status: string) {
    this.allApplications = false;
    this.pendingApplications = false;
    this.approvedApplications = false;
    this.rejectedApplications = false;
    this.applicationStatus = application_status;

    if (application_status === 'ALL') {
      this.allApplications = true;
    } else if (application_status === 'PENDING') {
      this.pendingApplications = true;
    } else if (application_status === 'APPROVED') {
      this.approvedApplications = true;
    } else {
      this.rejectedApplications = true;
    }
  }
}
