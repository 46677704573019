import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cryptocurrency',
  templateUrl: './cryptocurrency.component.html',
  styleUrls: ['./cryptocurrency.component.scss']
})
export class CryptocurrencyComponent implements OnInit {
  orderTab: boolean = true;
  closedorderTab: boolean;
  constructor() { }

  dashboardMenu() {
    document.getElementById('navbarNavDropdown').classList.toggle("show");
  }

  onTab(number) {
    this.orderTab = false;
    this.closedorderTab = false;

    if (number == '1') {
      this.orderTab = true;
    }
    else if (number == '2') {
      this.closedorderTab = true;
    }
  }
  ngOnInit(): void {
  }

}
