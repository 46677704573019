import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';


@NgModule({
  declarations: [],
    imports: [
        CommonModule,
        FormsModule
    ],
  exports: []
})
export class SharedModule { }
