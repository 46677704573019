export class RequestPaymentModel {

  // tslint:disable-next-line:variable-name
  constructor(budget, institution_results) {
    console.log(institution_results);
    if (institution_results.form_type === 'request_payment') { // New Payment Request
      this.budget_uuid = budget.uuid;
    } else {
      this.application_uuid = institution_results.application_uuid;
    }
  }
  static tuition: number;
  // tslint:disable-next-line:variable-name
  static book_allowance: number;
  // tslint:disable-next-line:variable-name
  static university_accommodation: number;
  // tslint:disable-next-line:variable-name
  static private_accommodation: number;
  // tslint:disable-next-line:variable-name
  static remaining_budget: number;

  public uuid = '';
  // tslint:disable-next-line:variable-name
  public institution_uuid = '';
  // tslint:disable-next-line:variable-name
  public budget_uuid = '';
  public status = 'PENDING';
  public description = '';
  // tslint:disable-next-line:variable-name
  public application_uuid = '';
}
