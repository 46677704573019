import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
@Pipe({
  name: 'unique',
  pure: false
})
export class CustomPipePipe implements PipeTransform {
  transform(value: any): any {
    // tslint:disable-next-line:no-non-null-assertion
    if (value! === undefined && value !== null) {
      return _.uniqBy(value, 'name');
    }
    return value;
  }

  // transform(value: any[], key: string): any[] {
  //   const distinctValues = new Set();
  //   return value.filter(item => {
  //     if (!distinctValues.has(item[key])) {
  //       distinctValues.add(item[key]);
  //       return true;
  //     }
  //     return false;
  //   });
  // }
}
