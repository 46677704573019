import { Component, OnInit } from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {BursaryService} from '../services/bursary/bursary.service';

@Component({
  selector: 'app-approved-applications',
  templateUrl: './approved-applications.component.html',
  styleUrls: ['./approved-applications.component.scss']
})
export class ApprovedApplicationsComponent implements OnInit {
  adminType = localStorage.getItem('admin_type');
  companyUuid = localStorage.getItem('related_uuid');
  opportunityApplications: any;
  modalRef: BsModalRef;
  provincesFilter: any;
  geographicsFilter: any;
  ethnicGroupsFilter: any;
  applicationStatusesFilter: any;
  disabilityCount: any;
  genderFilter: any;
  tableHeader = 'Approved';
  constructor(
      private bursaryService: BursaryService,
      private modalService: BsModalService
  ) {
    this.filter(this.companyUuid, this.tableHeader);
  }

  ngOnInit(): void {
  }

  // tslint:disable-next-line:variable-name
  filter(company_uuid, status) {
    this.tableHeader = status;
    this.bursaryService.getPartnerFirstOpportunity(company_uuid).subscribe((data) => {
      // @ts-ignore
      this.loadApplications(data.uuid, status);
    });
  }

  loadApplications(opportunityUuid, status) {
    this.bursaryService.getPartnerApplicationsPerOpportunity(opportunityUuid, status).subscribe((data) => {
      console.log(data);
      this.opportunityApplications = data;
    });
  }

  openSmartFilter(template) {
    this.bursaryService.getProvincesCount().subscribe((data) => { // Provinces Filter
      this.provincesFilter = data;
    });
    this.bursaryService.getGeographicsCount().subscribe((data) => { // Geograhics Filter
      this.geographicsFilter = data;
    });
    this.bursaryService.getEthnicGroupsCount().subscribe((data) => { // Ethnic Groups Filter
      this.ethnicGroupsFilter = data;
    });
    this.bursaryService.getApplicationStatusesCount().subscribe((data) => { // Application Statuses Filter
      this.applicationStatusesFilter = data;
    });
    this.bursaryService.getDisabilityCount().subscribe((data) => { // Disability Count
      this.disabilityCount = data;
    });
    this.bursaryService.gender().subscribe((data) => { // Disability Count
      this.genderFilter = data;
    });
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-xl',
      backdrop: 'static',
    });
  }
}
