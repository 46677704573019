import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {Profile} from '../../../models/profile.model';
import {Attachment} from '../../../models/attachment.model';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../services/auth.service';
import {UserDashboardService} from '../../../services/user-dashboard/user-dashboard.service';
import {ViewOpportunityComponent} from '../bursary/view-opportunity/view-opportunity.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ApplicationService} from '../../../services/application/application.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class StudentDashboardComponent implements OnInit {

  // ui members
  isFull: boolean;
  isFull1: any;
  isFull3: boolean;
  FeedTab = true;
  ActivityTab: boolean;
  FriendsTab: boolean;
  userType = 'STUDENT';

  // data members
  user: any;
  profileModel: Profile;
  profilePercentage: any;
  showPercentage: any;
  apiPublic: any;
  attachmentFiles: any;
  // tslint:disable-next-line:variable-name
  bursary_companies: any[] = [];
  // tslint:disable-next-line:variable-name
  latest_opportunities: any[] = [];
  modalRef: BsModalRef;
  // tslint:disable-next-line:variable-name
  // latest_opportunities = { opportunities: [] }; // Replace with your actual data
  recordsPerPage = 5;
  currentPage = 1;
  totalPages: number;
  pagedOpportunities: any[] = [];
  pages: number[] = [];
  studentPendingApplications: any[] = [];
  studentAwardedApplications: any[] = [];
  studentRejectedApplications: any[] = [];

  // constructor
  constructor(
    public router: Router,
    public authService: AuthService,
    // tslint:disable-next-line:variable-name
    public user_dashboard_service: UserDashboardService,
    private modalService: BsModalService,
    private applicationService: ApplicationService,
  ) {
    this.profileModel = new Profile(null, null, null, this.user);
    this.profilePercentage = 5;
    this.showPercentage = true;
    this.apiPublic = environment.apiPublic;
  }

  ngOnInit(): void {
    this.loadUser();
    this.getBursaryCompanies();
    this.getLatestOpportunities();
    this.calculatePages();
    this.setPage(1);
  }

  // auth check | fetch & load user
  loadUser(): void {
    this.authService.fetchUser().then((user) => {
      this.user = this.authService.getUser();
      this.profileModel = this.authService.getProfileModel();
      this.attachmentFiles = this.authService.getAttachments();
      this.profilePercentage = this.authService.getProfilePercentage();
    });
    this.getStudentApplications();
  }

  closeMenu() {
    const body = document.getElementsByTagName('body')[0].classList.remove('offcanvas-active');
  }

  dashboardMenu() {
    document.getElementById('navbarNavDropdown').classList.toggle('show');
  }

  fullScreenSection(num) {
    if (num === 1) {
      if (this.isFull) {
        this.isFull = false;
      } else {
        this.isFull = true;
      }
    } else if (num === 2) {
      if (this.isFull1) {
        this.isFull1 = false;
      } else {
        this.isFull1 = true;
      }
    } else if (num === 3) {
      if (this.isFull3) {
        this.isFull3 = false;
      } else {
        this.isFull3 = true;
      }
    }
  }

  onTab(num) {
    this.FeedTab = false;
    this.ActivityTab = false;
    this.FriendsTab = false;

    if (num === '1') {
      this.FeedTab = true;
    } else if (num === '2') {
      this.ActivityTab = true;
    } else if (num === '3') {
      this.FriendsTab = true;
    }
  }

  getBursaryCompanies() {
    this.user_dashboard_service.getBursaryCompanies().subscribe((data) => { // Bursary Companies
      this.bursary_companies = data;
    });
  }
  getLatestOpportunities() {
    console.log(localStorage.getItem('profile_uuid'));
    this.user_dashboard_service.getAllPublishedOpportunities().subscribe((data) => { // Latest Opportunities
      this.latest_opportunities = data;
      console.log(data);
    });
  }
  viewApplicationDetails(opportunity: any) {
    opportunity.modal_number = 1;
    opportunity.profile_completion = this.profilePercentage;
    this.modalRef = this.modalService.show(ViewOpportunityComponent, {
      class: 'modal-dialog-centered modal-xl',
      initialState: {
        opportunity
      },
      backdrop: 'static',
    });
  }
  calculatePages() {
    // if (this.latest_opportunities?.opportunities) {
    //   this.totalPages = Math.ceil(this.latest_opportunities.opportunities.length / this.recordsPerPage);
    //   this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
    // } else {
    //   this.totalPages = 0;
    //   this.pages = [];
    // }
  }

  setPage(page: number) {
    if (page < 1 || page > this.totalPages) {
      return;
    }

    this.currentPage = page;
    const startIndex = (page - 1) * this.recordsPerPage;
    const endIndex = startIndex + this.recordsPerPage;
    // this.pagedOpportunities = this.latest_opportunities.opportunities.slice(startIndex, endIndex);
  }

  prevPage() {
    this.setPage(this.currentPage - 1);
  }

  nextPage() {
    this.setPage(this.currentPage + 1);
  }

  getStudentApplications() {
    // In progress Applications
    this.applicationService.getStudentApplicationsByStatus('pending').subscribe((data) => {
      this.studentPendingApplications = data;
    });
    // Awarded Applications
    this.applicationService.getStudentApplicationsByStatus('awarded').subscribe((data) => {
      this.studentAwardedApplications = data;
    });
    // Rejected Applications
    this.applicationService.getStudentApplicationsByStatus('rejected').subscribe((data) => {
      this.studentRejectedApplications = data;
    });
  }
}
