import * as fs from 'file-saver';

declare let $: any;
import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {ApplicationService} from '../../../services/application/application.service';
import {ViewApplicationComponent} from '../../student/bursary/view-opportunity/apply/view-application/view-application.component';
import {LookupService} from '../../../services/lookup/lookup.service';
import { Workbook } from 'exceljs/dist/exceljs.min.js';
@Component({
  selector: 'app-scrumboard',
  templateUrl: './scrumboard.component.html',
  styleUrls: ['./scrumboard.component.scss']
})
export class ScrumboardComponent implements OnInit {

  status = false;
  modalRef: BsModalRef;
  statusTitle: any = 'Current';
  statusTypeSearch: any = 'All Types';
  showScrumBoard: any = false;
  showFilter: any = false;

  // currentApplications: any[] = [];
  studentNewApplications: any[] = [];
  studentApplications: any[] = [];
  tableData: any = [];
  studentPendingApplications: any[] = [];
  studentAwardedApplications: any[] = [];
  studentRejectedApplications: any[] = [];
  apiSearchString: any = '';
  filterBooleanArray: any = [];
  searchFilter: any = {
    genders: ['*', 'Male', 'Female', 'Other'],
    // tslint:disable-next-line:max-line-length
    provinces: [],
    cities: [],
    institutions: [],
    institutionTypes: ['*', 'TVET College', 'University of Technology', 'University'],
    yearsOfStudy: ['*', '1st', '2nd', '3rd'],
    geographics: ['*', 'RURAL', 'TOWNSHIP', 'URBAN'],
    faculties: [],
    bursaryYears: ['*', '2024', '2023', '2022', '2021', '2020'],
    courses: [],
    statuses: ['*', 'Declined', 'Approved']
  };
  printing: any = true;
  @ViewChild('divToPrint', {static: false}) divToPrint: ElementRef;
  currentDate: any;
  currentTime: any;

  constructor(
    private modalService: BsModalService,
    private applicationService: ApplicationService,
    public lookupService: LookupService
  ) {
    const userType = window.localStorage.getItem('user_type');
  }
  todo = [];

  inprogress = [
    '1) Bursary Application : #98621 SASOL Engineer undergraduate programme. (T. Makepe - tsekomakepe@gmail.com)',
    '1) Bursary Application : #98621 SASOL Engineer undergraduate programme. (T. Makepe - tsekomakepe@gmail.com)',
  ];

  done = [];

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }
  ngOnInit(): void {
    $('.js-example-basic-single').select2();
    this.getStudentApplications();
    this.lookupProvinces();
    this.lookupCities();
    this.lookupInstitutions();
    this.lookupCourses();
    this.lookupModules();
  }
  ListViewEvent() {
    this.status = !this.status;
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  getStudentApplications() {
    this.statusTitle = 'All';
    // New Applications (Applications that came in today)
    this.applicationService.getApplicationsByStatus('new').subscribe((data) => {
      this.studentNewApplications = data;
    });

    // All Applications
    this.applicationService.getAllApplications().subscribe((data) => {
      this.studentApplications = data;
      this.tableData = data;
      // this.currentApplications = data;
      console.log('Printing All Applications', data);
    });

    // In progress Applications
    this.applicationService.getApplicationsByStatus('pending').subscribe((data) => {
      this.studentPendingApplications = data;
      console.log('Printing Pending Applications', data);
    });

    // Awarded Applications
    this.applicationService.getApplicationsByStatus('awarded').subscribe((data) => {
      this.studentAwardedApplications = data;
      console.log('Printing Awarded Applications', data);
    });

    // Rejected Applications
    this.applicationService.getApplicationsByStatus('rejected').subscribe((data) => {
      this.studentRejectedApplications = data;
      console.log('Printing Rejected Applications', data);
    });
  }

  filterByStatus(status: any = 'pending') {
    this.applicationService.getApplicationsByStatus(status).subscribe((data) => {
      this.statusTitle = status;
      // this.currentApplications = data;
      this.studentApplications = data;
      this.tableData = data;
      console.log('Printing Filtered Applications', data);
    });
  }

  filterByType(type: any = 'bursary') {
    this.applicationService.getApplicationsByStatus(type).subscribe((data) => {
      this.statusTypeSearch = type;
      // this.currentApplications = data;
      this.studentApplications = data;
      this.tableData = data;
      console.log('Printing Filtered Applications', data);
    });
  }
  //
  viewApplication(application: any) {
    application.modal_number = 1;
    this.modalRef = this.modalService.show(ViewApplicationComponent, {
      class: 'modal-dialog-centered modal-xl',
      initialState: {
        application
      }
    });
  }
  //
  updateApplicationStatus(application: any, status: any = 'pending') {
    delete application.profile;
    application.status = status;
    this.applicationService.updateApplication(application).then((data) => {
      // this.ngOnInit();
      console.log('Updating Applications', data);
    });
  }

  handleKpiClick(kpiType: string) {
    // Update the data based on the clicked KPI type
    if (kpiType === 'NewApplications') {
      this.getNewApplications();
    } else if (kpiType === 'AllApplications') {
      this.getStudentApplications();
    } else if (kpiType === 'PendingApplications') {
      this.getPendingApplications();
    }

    // Optionally, update the header based on the clicked KPI type
    this.updateHeader(kpiType);
  }
  updateHeader(kpiType: string) {
    // Update header logic goes here
  }
  getNewApplications() {
    this.applicationService.getApplicationsByStatus('new').subscribe(data => {
      this.tableData = data;
    });
  }
  getPendingApplications() {
    this.applicationService.getApplicationsByStatus('pending').subscribe(data => {
      this.tableData = data;
    });
  }

  setFilter(event: any, index: any = false, reset: boolean = false) {
    const searchString = event.target.value;
    if (searchString.indexOf('*') !== -1) {

      // delete this.filterBooleanArray[index];
      console.log(this.filterBooleanArray);
      console.log('Deleting array item : ' + index + ' \n');
      this.filterBooleanArray.splice(index, 1);
      this.filterBooleanArray[index] = {filter: ''};
      console.log(this.filterBooleanArray);

      this.apiSearchString = '';
      this.filterBooleanArray.forEach(element => {
        console.log(element);
        this.setSearchString(element.filter);
      });

      this.getFilteredApplications(searchString);
      return;
    }

    this.filterBooleanArray[index] = {filter: searchString};
    this.filterBooleanArray.forEach(element => {
      this.setSearchString(searchString);
    });

    this.getFilteredApplications(searchString);
  }

  setSearchString(searchString: any) {
    console.log('Setting Search String');
    if (this.apiSearchString.includes(searchString)) {
      return;
    }

    // 1. get existing search string of key
    console.log(this.apiSearchString.substring(this.apiSearchString.indexOf('/') , searchString.indexOf('/')));

    // 3. join key/ with new value
    this.apiSearchString.slice(0, searchString.lastIndexOf('/'));
    this.apiSearchString += searchString;
    console.log(this.apiSearchString);
  }

  //
  getFilteredApplications(str) {
    this.applicationService.getFilteredApplications(str).subscribe((data) => {
      const bursaries: any = data;
      this.tableData = bursaries.applications;
    });
  }

  // lookups

  lookupProvinces() {
    this.searchFilter.provinces = this.lookupService.getProvinces().subscribe((data) => {
      this.searchFilter.provinces = data;
      console.log('Getting All Filtered Provinces : ', data.length);
    });
  }
  lookupCities() {
    this.searchFilter.provinces = this.lookupService.getCities().subscribe((data) => {
      this.searchFilter.cities = data;
      console.log('Getting All Filtered Cities : ', data.length);
    });
  }
  lookupInstitutions() {
    this.searchFilter.provinces = this.lookupService.getInstitutions().subscribe((data) => {
      this.searchFilter.institutions = data;
      console.log('Getting All Filtered Institutions : ', data.length);
    });
  }
  lookupCourses() {
    this.searchFilter.provinces = this.lookupService.getCourse().subscribe((data) => {
      this.searchFilter.courses = data;
      console.log('Getting All Filtered Courses : ', data.length);
    });
  }
  lookupModules() {
    this.searchFilter.provinces = this.lookupService.getModules().subscribe((data) => {
      this.searchFilter.faculties = data;
      console.log('Getting All Filtered Modules : ', data.length);
    });
  }
  getPaginatedData(url) {
    this.applicationService.getPaginatedApplications(url).subscribe((data) => {
      const count: any = data;
      console.log('Getting All PAGINATED Students : ', count?.profiles?.data?.length);
      this.tableData = data;
    });
  }
  print() {
    this.printing = true;
    const printContents = this.divToPrint.nativeElement.innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    setTimeout(() => {
      this.printing = false;
    }, 3000);
  }

  async downloadExcel(extension = '.xlsx') {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('My Sheet');
    console.log(worksheet);
    worksheet.columns = [
      { key: 'id', width: 20, header: 'ID' },
      { key: 'names', width: 20, header: 'NAMES' },
      { key: 'email', width: 20, header: 'EMAIL' },
      { key: 'contact', width: 20, header: 'CONTACT' },
      { key: 'gender', width: 20, header: 'GENDER' },
      { key: 'province', width: 20, header: 'PROVINCE' },
      { key: 'address', width: 20, header: 'ADDRESS' },
      { key: 'status', width: 20, header: 'STATUS' },
    ];

    let x: any;
    this.tableData?.application?.data.forEach( (student) => {
      worksheet.addRow({
        id: student?.id_number ,
        names: student?.first_name,
        email: student?.email,
        contact: student?.contact_number,
        gender: student?.gender,
        province: student?.province,
        address: student?.address,
        status: student?.status,
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, 'Students Full Report ' + this.currentDate + ' - ' + this.currentTime + extension);
  }
}
