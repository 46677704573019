import {Component, Input, OnInit} from '@angular/core';
import {RequestPaymentModel} from '../../../../../../../models/request-payment.model';
import Swal from 'sweetalert2';
import {ApiService} from '../../../../../../../services/api.service';
import {NotificationService} from '../../../../../../../services/notification.service';
import {AuthService} from '../../../../../../../services/auth.service';
import {BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-request-payment',
  templateUrl: './request-payment.component.html',
  styleUrls: ['./request-payment.component.scss']
})
export class RequestPaymentComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  @Input() budget: any;
  // tslint:disable-next-line:variable-name
  @Input() institution_results: any;
  requestModel = RequestPaymentModel;
  message: string[] = [];
  progressInfos: any[] = [];
  selectedFiles?: FileList;
  previews: string[] = [];
  public totalBudget: number;
  public remainingBudget: number;
  public totalRequestedAmountFormatted: number;
  public remainingBudgetFormatted: number;

  constructor(
    public apiService: ApiService,
    private notify: NotificationService,
    public authService: AuthService,
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    this.loadBudget();
  }

  loadBudget(): void {
    // @ts-ignore
    this.requestModel = new RequestPaymentModel(this.budget, this.institution_results);
    if (this.institution_results.form_type === 'request_payment') {
      this.totalBudget = this.budget.budget;
      this.requestModel.remaining_budget = this.budget.remaining_budget;
      this.totalRequestedAmountFormatted = 0;
      this.remainingBudgetFormatted = this.budget.remaining_budget;
    }
  }

  selectFiles(event: any): void {
    this.message = [];
    this.progressInfos = [];
    this.selectedFiles = event.target.files;
    this.previews = [];
    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.previews.push(e.target.result);
        };
        reader.readAsDataURL(this.selectedFiles[i]);
      }
    }
  }

  sendRequest() {
    this.apiService.post_private('bursary_budget_payments/store', this.requestModel).then((response) => {
      if (response?.status === 'success') {
        // this.institution = response.institution_results;
        Swal.fire('Request Sent!', response?.message || 'Nice! Your request has been safely sent!', 'success');
      } else {
        this.notify.confirmDialog(
          'Some Error Occurred. Do You Want to reload your profile and try again?',
          'Send Request',
          'error'
        );
      }
    }, (error) => {
      this.notify.confirmDialog(
        'Some Error Occurred. Do You Want to reload your profile and try again?',
        'Add Send Request',
        'error'
      );
    });
  }

  addBudget() {
    this.apiService.post_private('bursary_budget_payments/add_budget', this.requestModel).then((response) => {
      if (response?.status === 'success') {
        Swal.fire('Budget Added!', response?.message, 'success');
      } else {
        Swal.fire('Failed To Add Budget!', response?.message, 'error');
      }
    }, (error) => {
      this.notify.confirmDialog(
        'Some Error Occurred. Do You Want to reload your profile and try again?',
        'Add Send Request',
        'error'
      );
    });
  }

  closeModal(): void {
    this.modalService.hide(this.institution_results.modal_number);
    this.institution_results.modal_number = this.institution_results.modal_number - 1;
  }
  calculateRemainingBudget() {
    // Calculate the total requested amount.
    const totalRequestedAmount =
      (this.requestModel.tuition || 0) +
      (this.requestModel.book_allowance || 0) +
      (this.requestModel.university_accommodation || 0) +
      (this.requestModel.private_accommodation || 0);

    // Calculate the remaining budget.
    this.requestModel.remaining_budget = this.budget.remaining_budget - totalRequestedAmount;

    this.totalRequestedAmountFormatted = totalRequestedAmount;
    this.remainingBudgetFormatted = this.requestModel.remaining_budget;
  }

}
