export class TicketModel {

    public uuid = '';
    // tslint:disable-next-line:variable-name
    public student_id = localStorage.getItem('user_id');
    // tslint:disable-next-line:variable-name
    public ticket_ref = '';
    // tslint:disable-next-line:variable-name
    public subject = '';
    // tslint:disable-next-line:variable-name
    public description = '';
    public file = '';
    // tslint:disable-next-line:variable-name
    public form_type = 'student';


    // tslint:disable-next-line:variable-name
    constructor(data, uuid = '' ) {
        this.uuid = uuid;
        if (data !== null) {
            this.uuid = data.uuid?.toString();
            this.student_id = data?.student_id;
            this.ticket_ref = data?.ticket_ref;
            this.subject = data?.subject;
            this.description = data?.description;
            this.file = data?.file;
        }
    }
}
