import {Component, OnInit, Input, ViewChild, ElementRef} from '@angular/core';
import { Workbook } from 'exceljs/dist/exceljs.min.js';
import * as fs from 'file-saver';

@Component({
  selector: 'app-applications-table',
  templateUrl: './applications-table.component.html',
  styleUrls: ['./applications-table.component.scss']
})
export class ApplicationsTableComponent implements OnInit {
  @Input() tableHeader: string;
  @Input() adminType: string;
  @Input() opportunityApplications: any;
  printing: any = true;
  @ViewChild('divToPrint', {static: false}) divToPrint: ElementRef;
  currentDate: any;
  currentTime: any;
  constructor() { }

  ngOnInit(): void {
  }

  print() {
    this.printing = true;
    const printContents = this.divToPrint.nativeElement.innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    setTimeout(() => {
      this.printing = false;
    }, 3000);
  }

  async downloadExcel(extension = '.xlsx') {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('My Sheet');
    console.log(worksheet);
    worksheet.columns = [
      { key: 'id', width: 20, header: 'ID' },
      { key: 'names', width: 20, header: 'NAMES' },
      { key: 'email', width: 20, header: 'EMAIL' },
      { key: 'contact', width: 20, header: 'CONTACT' },
      { key: 'gender', width: 20, header: 'GENDER' },
      { key: 'province', width: 20, header: 'PROVINCE' },
      { key: 'address', width: 20, header: 'ADDRESS' },
      { key: 'status', width: 20, header: 'STATUS' },
    ];

    this.opportunityApplications?.application?.data.forEach( (student) => {
      worksheet.addRow({
        id: student?.id_number ,
        names: student?.first_name,
        email: student?.email,
        contact: student?.contact_number,
        gender: student?.gender,
        province: student?.province,
        address: student?.address,
        status: student?.status,
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, 'Students Full Report ' + this.currentDate + ' - ' + this.currentTime + extension);
  }
}
