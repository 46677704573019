import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import Swal from 'sweetalert2';
import validator from 'validator';


@Injectable({
  providedIn: 'root'
})
export class FormValidatorService {
  baseUrl: any = '';

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.baseUrl = environment.baseUrl;
  }

  validateID(idNumber) {
    const ex = /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/;
    // tslint:disable-next-line:triple-equals
    if (ex.test(idNumber) == false) {
      // alert code goes here
      alert('Please supply a valid ID number');
      return false;
    }
    alert(idNumber + ' is a valid ID number');
    Swal.fire('ID Number', 'ID Number is NOT Valid!', 'warning');
    // here you would normally obviously
    // return true;
    // but for the sake of this Codepen:
    return false;
  }

  validateContact(phoneNumber) {
    // const ex = /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/;
    // if (vali) {
    //   console.log("Valid South African phone number");
    // } else {
    //   console.log("Invalid South African phone number");
    // }
  }

  onKeyUp(event: any) {
    const inputValue: string = event.target.value;

    // Check if the length of the input value is greater than or equal to 10
    if (inputValue.length >= 10) {
      // Your logic after 10 letters here
      console.log('After 10 letters: ', inputValue);
    }
  }

}
