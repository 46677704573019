import { Component, OnInit } from '@angular/core';
import {CreateTicketComponent} from '../create-ticket/create-ticket.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {TicketService} from '../../../services/ticket/ticket.service';
import {ApplicationService} from '../../../services/application/application.service';
import { map, switchMap } from 'rxjs/operators';
import {forkJoin} from 'rxjs';


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  contactTab: boolean;
  groupTab: boolean;
  chatTab = true;
  modalRef: BsModalRef;
  tickets: any[] = [];
  pendingTickets: any[] = [];
  closedTickets: any[] = [];
  selectedText: any = {};
  ticketChats: any = {};
  message = '';
  fileToUpload: File | null = null;
  selectedTicketId: number | null = null;

  constructor(
      private modalService: BsModalService,
      private ticketService: TicketService,
      private applicationService: ApplicationService
  ) { }

  ngOnInit(): void {
    this.loadChat();
  }
  loadChat(): void {
    this.ticketService.getStudentTickets().subscribe((data) => { // All Tickets
      forkJoin(this.ticketObservables(data)).subscribe((modifiedTickets) => {
        this.tickets = modifiedTickets;
      }, (error) => {
        console.log(error);
      });
    }, (error) => {
      console.log(error);
    });

    this.ticketService.getStudentPendingTickets().subscribe((data) => { // Pending Tickets
      forkJoin(this.ticketObservables(data)).subscribe((modifiedTickets) => {
        this.pendingTickets = modifiedTickets;
      }, (error) => {
        console.log(error);
      });
    }, (error) => {
      console.log(error);
    });

    this.ticketService.getStudentClosedTickets().subscribe((data) => { // Closed Tickets
      forkJoin(this.ticketObservables(data)).subscribe((modifiedTickets) => {
        this.closedTickets = modifiedTickets;
      }, (error) => {
        console.log(error);
      });
    }, (error) => {
      console.log(error);
    });
  }

  ticketObservables(data): any {
    const ticketObservables = data.tickets.map((ticket) => {
      if (ticket.status === '0') { // Status
        ticket.status = 'Pending';
      } else if (ticket.status === '4') {
        // tslint:disable-next-line:no-unused-expression
        ticket.status = 'Closed';
      } else {
        // tslint:disable-next-line:no-unused-expression
        ticket.status = 'In Progress';
      }

      if (ticket.subject !== 'Other') { // Getting Application Record
        return this.applicationService.getApplicationsByUuid(ticket.subject).pipe(
            map((res) => {
              // @ts-ignore
              const ref = res?.application?.application_ref;
              ticket.subject = ref;
              return ticket;
            })
        );
      } else {
        return ticket;
      }
    });

    return ticketObservables;
  }

  getPendingTicketsCount(): number {
    return this.tickets.filter(ticket => ticket.status !== '4').length;
  }
  getClosedTicketsCount(): number {
    return this.tickets.filter(ticket => ticket.status === '4').length;
  }
  // tslint:disable-next-line:variable-name
  onTab(number) {
    this.chatTab = false;
    this.groupTab = false;
    this.contactTab = false;
    if (number === '1') {
      this.chatTab = true;
    } else if (number === '2') {
      this.groupTab = true;
    } else if (number === '3') {
      this.contactTab = true;
    }
  }
  createTicket() {
    this.modalRef = this.modalService.show(CreateTicketComponent, {
      class: 'modal-dialog-centered modal-xl',
      backdrop: 'static',
    });
  }
  openChat(ticket: any): void {
    this.ticketService.getTicketById(ticket.id).subscribe((data) => {
      console.log(data);
      this.selectedText = { ...ticket };
      // @ts-ignore
      this.ticketChats = data.ticket.ticket_chats;
      this.selectedTicketId = ticket.id;
    }, (error) => {
      console.log(error);
    });
  }
  onFileSelected(event: any) {
    this.fileToUpload = event.target.files.item(0);
  }
  sendTicketMessage() {
    const formData = new FormData();
    formData.append('chat_message', this.message);
    if (this.fileToUpload) {
      formData.append('chat_file', this.fileToUpload, this.fileToUpload.name);
    }
    formData.append('ticket_id', this.selectedTicketId.toString());
    formData.append('user_id', localStorage.getItem('user_id'));
    formData.append('user_type', 'student');

    this.ticketService.sendMessage(formData).subscribe(
        response => {
          // Handle success response if needed
          console.log('Message sent:', response);
          this.message = ''; // Optionally reset the message after sending
        },
        error => {
          // Handle error response if needed
          console.error('Error sending message:', error);
        }
    );
  }
}
