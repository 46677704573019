export class Attachment {

  public uuid = '';
  // tslint:disable-next-line:variable-name
  public profile_uuid = '';
  // tslint:disable-next-line:variable-name
  public file_type = '';
  public format = '';
  public name = '';
  public description = '';
  public path = '';
  public active = '';

  // tslint:disable-next-line:variable-name
  constructor(data, profile_uuid = '' ) {
    this.profile_uuid = profile_uuid;
    if (data !== null) {
      this.uuid = data.uuid?.toString();
      this.file_type = data.file_type;
      this.format = data.format;
      this.name = data.name;
      this.description = data.description;
      this.path = data.path;
      this.active = data.active;
    }
  }
}
