import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';

import {ClientRoutingModule} from './client-routing.module';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {HeaderComponent} from './header/header.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {LeftmenuComponent} from './leftmenu/leftmenu.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {EmailComponent} from './email/email.component';
import {ChatComponent} from './chat/chat.component';
import {ChatComponent as HelpdeskChatComponent} from '../helpdesk/chat/chat.component';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {UiElementComponent} from './ui-element/ui-element.component';
import {UiHelperComponent} from './ui-element/ui-helper/ui-helper.component';
import {UiBootstrapComponent} from './ui-element/ui-bootstrap/ui-bootstrap.component';
import {UiTypographyComponent} from './ui-element/ui-typography/ui-typography.component';
import {UiTabsComponent} from './ui-element/ui-tabs/ui-tabs.component';
import {UiButtonsComponent} from './ui-element/ui-buttons/ui-buttons.component';
import {UiIconsComponent} from './ui-element/ui-icons/ui-icons.component';
import {UiNotificationsComponent} from './ui-element/ui-notifications/ui-notifications.component';
import {UiColorsComponent} from './ui-element/ui-colors/ui-colors.component';
import {UiDialogsComponent} from './ui-element/ui-dialogs/ui-dialogs.component';
import {UiListGroupComponent} from './ui-element/ui-list-group/ui-list-group.component';
import {UiMediaObjectComponent} from './ui-element/ui-media-object/ui-media-object.component';
import {UiModalsComponent} from './ui-element/ui-modals/ui-modals.component';
import {UiNestableComponent} from './ui-element/ui-nestable/ui-nestable.component';
import {UiProgressBarsComponent} from './ui-element/ui-progress-bars/ui-progress-bars.component';
import {UiRangeSlidersComponent} from './ui-element/ui-range-sliders/ui-range-sliders.component';


import {AccordionModule} from 'ngx-bootstrap/accordion';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {AlertModule} from 'ngx-bootstrap/alert';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ToastrModule} from 'ngx-toastr';
import {ModalModule} from 'ngx-bootstrap/modal';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {FormElementComponent} from './form-element/form-element.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatSliderModule} from '@angular/material/slider';
import {TablesComponent} from './tables/tables.component';
import {ChartsComponent} from './charts/charts.component';
import {NgApexchartsModule} from 'ng-apexcharts';
import {LineChartComponent} from './charts/line-chart/line-chart.component';
import {BarChartComponent} from './charts/bar-chart/bar-chart.component';
import {PieChartComponent} from './charts/pie-chart/pie-chart.component';
import {ColumnChartComponent} from './charts/column-chart/column-chart.component';
import {AreaChartComponent} from './charts/area-chart/area-chart.component';
import {RadarChartComponent} from './charts/radar-chart/radar-chart.component';
import {RadialbarChartComponent} from './charts/radialbar-chart/radialbar-chart.component';
import {HeatmapChartComponent} from './charts/heatmap-chart/heatmap-chart.component';
import {CandlestickChartComponent} from './charts/candlestick-chart/candlestick-chart.component';
import {WidgetsComponent} from './widgets/widgets.component';
import {CalenderComponent} from './calender/calender.component';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {SocialComponent} from './social/social.component';
import {BlogComponent} from './blog/blog.component';
import {ScrumboardComponent} from './scrumboard/scrumboard.component';
import {ContactsComponent} from './contacts/contacts.component';
import {FilemanagerComponent} from './filemanager/filemanager.component';
import {TodoListComponent} from './todo-list/todo-list.component';
import {SparklinesComponent} from './charts/sparklines/sparklines.component';
import {SettingsComponent} from './settings/settings.component';
import {MinbarchartComponent} from './charts/minbarchart/minbarchart.component';
import {FullcalenderComponent} from './fullcalender/fullcalender.component';
import {FullCalendarModule} from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import timeGridPlugin from '@fullcalendar/timegrid';
import {WorldmapComponent} from './worldmap/worldmap.component';
import {AgmCoreModule} from '@agm/core';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {CkeditorComponent} from './ckeditor/ckeditor.component';
import {ComposeEmailComponent} from './email/compose-email/compose-email.component';
import {EventsComponent} from './calender/events/events.component';
import {CryptocurrencyComponent} from './cryptocurrency/cryptocurrency.component';
import {CampaignComponent} from './campaign/campaign.component';
import {EcommerceComponent} from './ecommerce/ecommerce.component';
import {MapsComponent} from './maps/maps.component';
import {ComposeEmailDetailsComponent} from './email/compose-email-details/compose-email-details.component';
import {PagesComponent} from './pages/pages.component';
import {PricingComponent} from './pages/pricing/pricing.component';
import {TimelineComponent} from './pages/timeline/timeline.component';
import {ImageGalleryComponent} from './pages/image-gallery/image-gallery.component';
import {InvoicesComponent} from './pages/invoices/invoices.component';
import {InvoiceDetailComponent} from './pages/invoices/invoice-detail/invoice-detail.component';
import {ProfileComponent} from './pages/profile/profile.component';
import {SearchResultsComponent} from './pages/search-results/search-results.component';
import {BlankPageComponent} from './pages/blank-page/blank-page.component';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {StudentEmailComponent} from '../student/email/email.component';
import {StudentComposeEmailComponent} from '../student/email/compose-email/compose-email.component';
import {StudentScrumboardComponent} from '../student/scrumboard/scrumboard.component';
import {StudentDashboardComponent} from './../student/dashboard/dashboard.component';
import {StudentFilemanagerComponent} from './../student/filemanager/filemanager.component';
import {StudentBursaryComponent} from '../student/bursary/todo-list.component';
import {StudentProfileComponent} from '../student/pages/profile/profile.component';
import {StudentComposeEmailDetailsComponent} from '../student/email/compose-email-details/compose-email-details.component';
import {StudentCalenderComponent} from '../student/calender/calender.component';
import {StudentEventsComponent} from '../student/calender/events/events.component';
import {StudentCampaignComponent} from '../student/campaign/campaign.component';
import {OpportunityDetailComponent} from '../student/pages/opportunity/opportunity-detail/opportunity-detail.component';
import { ViewStudentComponent } from './contacts/view-student/view-student.component';
import {NgxSummernoteModule} from 'ngx-summernote';
import {CommentComponent} from '../../shared/components/comment.component';
import {ViewOpportunityComponent} from '../student/bursary/view-opportunity/view-opportunity.component';
import {SharedModule} from '../../shared/shared.module';
import {ApplyComponent} from '../student/bursary/view-opportunity/apply/apply.component';
import {ViewApplicationComponent} from '../student/bursary/view-opportunity/apply/view-application/view-application.component';
import {Select2Module} from 'ng-select2-component';
import {SchoolDetailsComponent} from '../student/pages/profile/school-details/school-details.component';
import {InstitutionComponent} from '../student/pages/profile/institution/institution.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {NgxPaginationModule} from 'ngx-pagination';
import {ApplicationsTableComponent} from '../../applications-table/applications-table.component';
import {DocumentDownloaderComponent} from '../../document-downloader/document-downloader.component';
import {DocumentsTableComponent} from '../../documents-table/documents-table.component';


FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  timeGridPlugin,
  interactionPlugin
]);

@NgModule({
    imports: [
        CommonModule,
        ClientRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        BsDatepickerModule.forRoot(),
        CollapseModule.forRoot(),
        AccordionModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        AlertModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        ProgressbarModule.forRoot(),
        DragDropModule,
        MatSliderModule,
        NgApexchartsModule,
        ToastrModule.forRoot({}),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        AgmCoreModule.forRoot({
            apiKey: 'GOOGLE_API_KEY'
        }),
        FullCalendarModule,
        CKEditorModule,
        NgxSummernoteModule,
        SharedModule,
        Select2Module,
        MatExpansionModule,
        NgxPaginationModule
    ],
    declarations: [
        ClientRoutingModule.components,
        HeaderComponent,
        LeftmenuComponent,
        DashboardComponent,
        StudentDashboardComponent,
        StudentEmailComponent,
        StudentComposeEmailComponent,
        StudentComposeEmailDetailsComponent,
        StudentFilemanagerComponent,
        StudentScrumboardComponent,
        StudentScrumboardComponent,
        StudentBursaryComponent,
        StudentProfileComponent,
        StudentCalenderComponent,
        StudentEventsComponent,
        StudentCampaignComponent,
        OpportunityDetailComponent,
        EmailComponent,
        ChatComponent,
        HelpdeskChatComponent,
        UiElementComponent,
        UiHelperComponent,
        UiBootstrapComponent,
        UiTypographyComponent,
        UiTabsComponent,
        UiButtonsComponent,
        UiIconsComponent,
        UiNotificationsComponent,
        UiColorsComponent,
        UiDialogsComponent,
        UiListGroupComponent,
        UiMediaObjectComponent,
        UiModalsComponent,
        UiNestableComponent,
        UiProgressBarsComponent,
        UiRangeSlidersComponent,
        FormElementComponent,
        TablesComponent,
        ChartsComponent,
        LineChartComponent,
        BarChartComponent,
        PieChartComponent,
        ColumnChartComponent,
        AreaChartComponent,
        RadarChartComponent,
        RadialbarChartComponent,
        HeatmapChartComponent,
        CandlestickChartComponent,
        WidgetsComponent,
        CalenderComponent,
        SocialComponent,
        BlogComponent,
        ScrumboardComponent,
        ContactsComponent,
        FilemanagerComponent,
        TodoListComponent,
        SparklinesComponent,
        SettingsComponent,
        MinbarchartComponent,
        FullcalenderComponent,
        WorldmapComponent,
        CkeditorComponent,
        ComposeEmailComponent,
        EventsComponent,
        CryptocurrencyComponent,
        CampaignComponent,
        EcommerceComponent,
        MapsComponent,
        ComposeEmailDetailsComponent,
        PagesComponent,
        PricingComponent,
        TimelineComponent,
        ImageGalleryComponent,
        InvoicesComponent,
        InvoiceDetailComponent,
        ProfileComponent,
        SearchResultsComponent,
        BlankPageComponent,
        PageNotFoundComponent,
        ViewStudentComponent,
        CommentComponent,
        ViewOpportunityComponent,
        ApplyComponent,
        ViewApplicationComponent,
        SchoolDetailsComponent,
        InstitutionComponent,
        ApplicationsTableComponent,
        DocumentDownloaderComponent,
        DocumentsTableComponent
    ],
    exports: [
        ColumnChartComponent,
        ColumnChartComponent,
        HeaderComponent,
        BarChartComponent,
        CommentComponent,
        ViewOpportunityComponent,
        ApplyComponent,
        ViewApplicationComponent,
        SchoolDetailsComponent,
        InstitutionComponent,
        ApplicationsTableComponent,
        DocumentDownloaderComponent,
        DocumentsTableComponent
    ],
    providers: [BsDatepickerModule, DatePipe]
})
export class ClientModule {
}
