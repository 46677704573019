export class MatricResultsModel {

  public uuid = '';
  // tslint:disable-next-line:variable-name
  public school_details_uuid = '';
  public subject = '';
  public mark = '';


  // tslint:disable-next-line:variable-name
  constructor(data, school_details_uuid = '' ) {
    this.school_details_uuid = school_details_uuid;
    if (data !== null && data !== undefined) {
      this.uuid = data.uuid?.toString();
      this.subject = data.subject;
      this.mark = data.mark;
    }
  }
}
