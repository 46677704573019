import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../../../../../environments/environment';
import Swal from 'sweetalert2';
import {ApiService} from '../../../../../../services/api.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ApplicationService} from '../../../../../../services/application/application.service';
import {RequestPaymentComponent} from './request-payment/request-payment.component';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip
} from 'ng-apexcharts';
import {ChartOptions} from '../../../../../client/charts/column-chart/column-chart.component';
import {InstitutionComponent} from '../../../../pages/profile/institution/institution.component';
import {SchoolDetailsComponent} from '../../../../pages/profile/school-details/school-details.component';
import {NotificationService} from '../../../../../../services/notification.service';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-view-application',
  templateUrl: './view-application.component.html',
  styleUrls: ['./view-application.component.scss']
})
export class ViewApplicationComponent implements OnInit {

  @Input() application: any;
  // tslint:disable-next-line:variable-name
  @Input() view_type: string;
  // tslint:disable-next-line:variable-name
  @Input() current_page: string;
  homeEX3Tab = true;
  qualificationDetailsEX1Tab = true;
  schoolDetailsEX2Tab = false;
  profileEX3Tab: boolean;
  profileEX4Tab: boolean;
  contractEX4Tab: boolean;
  signatureEX4Tab: boolean;
  documentEX3Tab: boolean;
  apiBaseUrl: any;
  userType: any = window.localStorage.getItem('user_type');
  qualificationsTab = false;
  schoolDetailsTab = false;
  modalRef: BsModalRef;
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  showScrumBoard: boolean[] = [];
  showScrumBoardIcons: boolean[] = [];
  apiPublic: any = environment.apiPublic;
  randomIndex: any = Math.random();

  constructor(
    public apiService: ApiService,
    private modalService: BsModalService,
    private applicationService: ApplicationService,
    private notify: NotificationService,
    // private signaturePad: SignaturePad
  ) {

    this.chartOptions = {
      series: [
        {
          name: 'APPROVED',
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
        },
        {
          name: 'DECLINED',
          data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
        },
        {
          name: 'ARCHIVED',
          data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
        }
      ],
      chart: {
        type: 'bar',
        height: 200
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          borderRadius: 10
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: [
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct'
        ]
      },
      yaxis: {
        title: {
          text: ' (Evaluations)'
        }
      },
      fill: {
        type: 'fill',
        colors: ['#17C2D7', '#9367B4', '#A5D8A2'],
      },
      // tooltip: {
      //   y: {
      //     formatter: function (val) {
      //       return "$ " + val + " thousands";
      //     }
      //   }
      // }
    };

    this.application?.budgets.forEach(_ => {
      this.showScrumBoard.push(false);
    });
  }

  ngOnInit(): void {
    this.apiBaseUrl = environment.apiPublic;
    this.showScrumBoard = new Array(this.application?.budgets?.length).fill(false);
  }

  getProfile(uuid) {
    this.apiService.get_public('profile/user_profile/' + uuid).then((data) => {
      this.application.profile = data?.profile;
    });
  }

  withdrawApplication(uuid: string) {
    Swal.fire({
      title: 'Confirm Withdrawal',
      text: 'Are you sure you want to withdraw this application?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, withdraw it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        this.performWithdrawal(uuid);
      }
    });
  }
  reSubmitApplication(uuid: string) {
    Swal.fire({
      title: 'Confirm Resubmission',
      text: 'Are you sure you want to resubmit this application?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, resubmit it!',
      cancelButtonText: 'No, keep it withdrawn'
    }).then((result) => {
      if (result.isConfirmed) {
        this.performResubmission(uuid);
      }
    });
  }
  performWithdrawal(uuid: string) {
    this.apiService.get_private(`application/withdraw/${uuid}`).then((response) => {
      if (response?.status === 'success') {
        Swal.fire('Application Withdrawn!', response?.message || 'Your application has been withdrawn successfully!', 'success');
      } else {
        Swal.fire('Withdrawal Failed!', response?.message || 'Failed to withdraw the application!', 'error');
      }
    }).catch((error) => {
      console.log('Error Withdrawing Application:', error);
      Swal.fire('Error', 'An error occurred while withdrawing the application.', 'error');
    });
  }
  performResubmission(uuid: string) {
    this.apiService.get_private(`application/resubmit/${uuid}`).then((response) => {
      if (response?.status === 'success') {
        Swal.fire('Application Resubmitted!', response?.message || 'Your application has been resubmitted successfully!', 'success');
      } else {
        Swal.fire('Resubmission Failed!', response?.message || 'Failed to resubmit the application!', 'error');
      }
    }).catch((error) => {
      console.log('Error Resubmitting Application:', error);
      Swal.fire('Error', 'An error occurred while resubmitting the application.', 'error');
    });
  }

  updateApplicationStatus(application: any, status: any = 'pending') {
    delete application.profile;
    application.status = status;
    this.applicationService.updateApplication(application).then((data) => {
      this.ngOnInit();
      console.log('Updating Applications', data);
    });
  }

  // tslint:disable-next-line:variable-name
  onTabEX3(number) {
    this.homeEX3Tab = false;
    this.profileEX3Tab = false;
    this.profileEX4Tab = false;
    this.contractEX4Tab = false;
    this.signatureEX4Tab = false;
    this.documentEX3Tab = false;
    this.qualificationsTab = false;
    this.schoolDetailsTab = false;

    if (number === '1') {
      this.homeEX3Tab = true;
    } else if (number === '2') {
      this.documentEX3Tab = true;
    } else if (number === '3') {
      this.profileEX3Tab = true;
    } else if (number === '4') {
      this.profileEX4Tab = true;
    } else if (number === '5') {
      this.contractEX4Tab = true;
    } else if (number === '6') {
      this.qualificationsTab = true;
    } else if (number === '7') {
      this.schoolDetailsTab = true;
    } else if (number === '8') {
      this.signatureEX4Tab = true;
    }
  }

  // tslint:disable-next-line:variable-name
  educationTabs(number) {
    this.qualificationDetailsEX1Tab = false;
    this.schoolDetailsEX2Tab = false;

    if (number === '1') {
      this.qualificationDetailsEX1Tab = true;
    } else if (number === '2') {
      this.schoolDetailsEX2Tab = true;
    }
  }

  // tslint:disable-next-line:variable-name
  requestPayment(budget: any, institution_results: any) {
    institution_results.modal_number = this.application.modal_number + 1;
    institution_results.form_type = 'request_payment';
    institution_results.application_uuid = this.application.uuid;
    this.modalRef = this.modalService.show(RequestPaymentComponent, {
      class: 'modal-dialog-centered modal-xl',
      initialState: {
        budget,
        institution_results
      },
      backdrop: 'static',
    });
  }
  // tslint:disable-next-line:variable-name
  addBudget(budget: any, institution_results: any) {
    institution_results.modal_number = this.application.modal_number + 1;
    institution_results.form_type = 'add_budget';
    institution_results.application_uuid = this.application.uuid;
    this.modalRef = this.modalService.show(RequestPaymentComponent, {
      class: 'modal-dialog-centered modal-xl',
      initialState: {
        budget,
        institution_results
      },
      backdrop: 'static',
    });
  }
  viewInstitutionDetails(institution: any) {
    console.log(institution);
    institution.modal_number = this.application.modal_number + 1;
    institution.view_type = 'view only';
    this.modalRef = this.modalService.show(InstitutionComponent, {
      class: 'modal-dialog-centered modal-xl',
      initialState: {
        institution
      },
      backdrop: 'static',
    });
  }
  // tslint:disable-next-line:variable-name
  viewSchoolDetails(school_details: any) {
    school_details.modal_number = this.application.modal_number + 1;
    school_details.view_type = 'view only';
    this.modalRef = this.modalService.show(SchoolDetailsComponent, {
      class: 'modal-dialog-centered modal-xl',
      initialState: {
        school_details
      },
      backdrop: 'static',
    });
  }
  toggleScrumBoard(index: number) {
    this.showScrumBoard = this.showScrumBoard.map((value, i) => i === index ? !value : false);
    this.showScrumBoardIcons[index] = this.showScrumBoard[index];
  }
  approveOrDeclineAttachment(uuid: string, status: string) {
    this.apiService.get_private(`approve-decline-attachment/${status}/${uuid}`).then((response) => {
      console.log(response);
      if (response?.status === 'success') {
        // this.institution = response.institution_results;
        this.notify.successBasicNotification('Success', response?.message);
        // Swal.fire('Attachment Approved!', response?.message);
      } else {
        this.notify.confirmDialog(
            'Some Error Occurred. Do You Want to reload and try again?',
            'Error',
            'error'
        );
      }
    }, (error) => {
      this.notify.confirmDialog(
          'Some Error Occurred. Do You Want to reload your page and try again?',
          'Approve Attachment',
          'error'
      );
    });
  }

  dataURLToBlob(dataURL) {
    // Code taken from https://github.com/ebidel/filer.js
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
  }

  download(dataURL, filename) {
    if (navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') === -1) {
      window.open(dataURL);
    } else {
      const blob = this.dataURLToBlob(dataURL);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
    }
  }

  openModal(template: TemplateRef<any>) {
    console.log(this.application);
    if (!this.application?.profile) {
      this.getProfile(this.application.profile_uuid);
    } else {
      this.getProfile(this.application?.profile.uuid);
    }
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-xl',
      backdrop: 'static',
    });
  }

  // getPaginatedData(url) {
  //   this.applicationService.getPaginatedApplications(url).subscribe((data) => {
  //     const count: any = data;
  //     console.log('Getting All PAGINATED Students : ', count?.profiles?.data?.length);
  //     this.tableData = data;
  //   });
  // }
}
