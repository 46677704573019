import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpEvent, HttpHeaders, HttpRequest} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {NotificationService} from '../notification.service';

@Injectable({
    providedIn: 'root'
})
export class UserManagementService {

    private baseUrl;

    constructor(
        private http: HttpClient,
        private notify: NotificationService,
    ) {
        this.baseUrl = environment.baseUrl;
    }

    get(baseUrl: string = null, url: string): Observable<any> {
        if (baseUrl !== null) {
            return this.http.get(`${baseUrl + url}`);
        }
        return this.http.get(`${this.baseUrl + url}`);
    }

    post(url: string, body: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const header = {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
            };
            const param = this.JSON_to_URLEncoded(body);
            return this.http.post(this.baseUrl + url, param, header).subscribe((data) => {
                resolve(data);
            }, error => {
                this.notify.errorBasicNotification(error.error.message, error.statusText);
                reject(error);
            });
        });
    }

    JSON_to_URLEncoded(element: any, key?: any, list?: any) {
        // tslint:disable-next-line:variable-name
        const new_list = list || [];
        if (typeof element === 'object') {
            // tslint:disable-next-line:forin
            for (const idx in element) {
                this.JSON_to_URLEncoded(
                    element[idx],
                    key ? key + '[' + idx + ']' : idx,
                    new_list
                );
            }
        } else {
            new_list.push(key + '=' + encodeURIComponent(element));
        }
        return new_list.join('&');
    }
}
