import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import {Location} from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private location: Location) {}
  //
  goBack(): void {
    this.location.back();
  }
  //
  public successBasicNotification(message, statusText) {
    Swal.fire(statusText, message, 'success');
  }
  //
  public warningBasicNotification(message, statusText) {
    Swal.fire(statusText, message, 'warning');
  }
  //
  public errorBasicNotification(message, statusText) {
    Swal.fire(statusText, message, 'error');
  }
  // tslint:disable-next-line:max-line-length
  public confirmDialog(text, confirmationFunction, title= 'Are you sure?', icon= 'warning', showCancelButton = true, confirmButtonText = 'Yes', cancelButtonText = 'No') {
    const options = {title, text, icon, showCancelButton, confirmButtonText, cancelButtonText}
    // @ts-ignore
    Swal.fire(options).then((event) => {
      if (event.value === true) {
        confirmationFunction();
      } else {
        Swal.close();
      }
    });
  }
  // tslint:disable-next-line:max-line-length
  public awarenessActionDialog(text, confirmationFunction, title= 'Are you sure?', icon= 'warning', showCancelButton = true, confirmButtonText = 'Yes') {
    const options = {title, text, icon, showCancelButton, confirmButtonText}
    // @ts-ignore
    Swal.fire(options).then((event) => {
      if (event.value === true) {
        confirmationFunction();
      } else {
        Swal.close();
      }
    });
  }
}
