// This file can be replaced during build by using the `fileReplacements` array.
export const environment = {
  production: false,

  // Live
  apiPublic: 'https://api.bothoconsult.co.za/',
  baseUrl: 'https://api.bothoconsult.co.za/api/',
  imageUrl: 'https://api.bothoconsult.co.za/storage/images/',
  helpdeskBaseUrl: 'https://helpdesk.bothoconsult.co.za/api/',
  helpdeskPublic: 'https://helpdesk.bothoconsult.co.za/',

  // Dash Local Host
  // baseUrl: 'https://ubuntu-api.premiumdigital.co.za/api/',
  // imageUrl: 'https://ubuntu-api.premiumdigital.co.za/storage/images/',
  // apiPublic: 'https://ubuntu-api.premiumdigital.co.za/',

  // Philani Local Host
  // apiPublic: 'http://127.0.0.1:8000/',
  // baseUrl: 'http://127.0.0.1:8000/api/',
  // imageUrl: 'http://127.0.0.1:8000/storage/images/',
  // helpdeskBaseUrl: 'http://127.0.0.1:8001/api/',
  // helpdeskPublic: 'http://127.0.0.1:8001/',

  firebase: {
    apiKey: 'xxx',
    authDomain: 'xxx',
    projectId: 'xxx',
    storageBucket: 'xxx',
    messagingSenderId: 'xxx',
    appId: 'xxx'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
