import { Component, OnInit } from '@angular/core';
import {NotificationService} from '../../../services/notification.service';
import {Router} from '@angular/router';
import {SessionHandlerService} from '../../../services/session/session-handler.service';
import {ApiService} from '../../../services/api.service';
import {AuthService} from '../../../services/auth.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isFullScreen: boolean;
  contactTab: boolean;
  groupTab: boolean;
  chatTab = true;
  user: any;
  message: any;
  modalRef: BsModalRef;

  constructor(
      private notify: NotificationService,
      private sessionHandle: SessionHandlerService,
      private router: Router,
      private apiService: ApiService,
      public authService: AuthService,
      private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    this.loadUser();
  }
  mToggoleMenu() {
    document.getElementsByTagName('body')[0].classList.toggle('offcanvas-active');
    document.getElementsByClassName('overlay')[0].classList.toggle('open');

  }
  noteToggle() {
    document.getElementsByClassName('sticky-note')[0].classList.toggle('open');
    document.getElementsByClassName('overlay')[0].classList.toggle('open');
  }
  openRightMenu() {
    document.getElementById('rightbar').classList.toggle('open');
    document.getElementsByClassName('overlay')[0].classList.toggle('open');

  }
  openfullScreen() {

    let elem = document.documentElement;
    let methodToBeInvoked = elem.requestFullscreen ||
      elem.requestFullscreen || elem['mozRequestFullscreen'] || elem['msRequestFullscreen'];
    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem)
    }
    this.isFullScreen = true;
  }

  closeFullScreen() {
    const docWithBrowsersExitFunctions = document as Document & {
      mozCancelFullScreen(): Promise<void>;
      webkitExitFullscreen(): Promise<void>;
      msExitFullscreen(): Promise<void>;
    };
    if (docWithBrowsersExitFunctions.exitFullscreen) {
      docWithBrowsersExitFunctions.exitFullscreen();
    } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) { /* Firefox */
      docWithBrowsersExitFunctions.mozCancelFullScreen();
    } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      docWithBrowsersExitFunctions.webkitExitFullscreen();
    } else if (docWithBrowsersExitFunctions.msExitFullscreen) { /* IE/Edge */
      docWithBrowsersExitFunctions.msExitFullscreen();
    }
    this.isFullScreen = false;
  }

  onTab(number) {
    this.chatTab = false;
    this.groupTab = false;
    this.contactTab = false;
    if (number === '1') {
      this.chatTab = true;
    } else if (number === '2') {
      this.groupTab = true;
    } else if (number === '3') {
      this.contactTab = true;
    }
  }
  logout() {
    const router = this.router;
    const sessionHandle = this.sessionHandle;
    const apiService = this.apiService;
    console.log('Logging Out User : ');
    // tslint:disable-next-line:only-arrow-functions
    this.notify.confirmDialog('Logged out successfully ?', function() {
      // tslint:disable-next-line:only-arrow-functions
      apiService.post_private('logout', {}).then(function(data) {
        if (data) {
          sessionHandle.destroySession(router);
        }
      });
      sessionHandle.destroySession(router);
    });
  }
  loadUser(): void {
    this.authService.fetchUser().then((user) => {
      this.user = this.authService.getUser();
      window.localStorage.setItem('user_data', JSON.stringify(this.user));
      // console.log(JSON.parse(window.localStorage.getItem('user_data')));
    });
  }
  viewMessage(template, message) {
    this.message = message;
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-xl',
      backdrop: 'static',
    });
  }
}
