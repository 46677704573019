import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import {BursaryService} from '../../../../../services/bursary/bursary.service';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {environment} from '../../../../../../environments/environment';
import {ViewApplicationComponent} from '../../../bursary/view-opportunity/apply/view-application/view-application.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ApplicationService} from '../../../../../services/application/application.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-invoice-detail',
  templateUrl: './opportunity-detail.component.html',
  styleUrls: ['./opportunity-detail.component.scss']
})
export class OpportunityDetailComponent implements OnInit {

  public swal: any = Swal;

  constructor(
    private bursaryService: BursaryService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private applicationService: ApplicationService) { }

  @ViewChild('divToPrint', {static: false}) divToPrint: ElementRef;

  uuid: any;
  bursaries: any;
  opportunity: any;
  documentRequirements: any;
  apiUrl: any = environment.apiPublic;
  homeEX4Tab = true;
  profileEX4Tab: boolean;
  awardedApplicationsEX4Tab: boolean;
  ContactEX4Tab: boolean;
  modalRef: BsModalRef;
  imageUrl: any = 'https://cdn-icons-png.flaticon.com/512/4599/4599520.png';

  //
  window: any = window;

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.uuid = params.get('uuid');
      this.getBursaries(this.uuid);
    });
  }

  // auth check | fetch user
  getBursaries(uuid) {
    this.bursaryService.getBursaries().subscribe((data) => {
      // @ts-ignore
      const bursaries: any = data;
      this.bursaries = bursaries.opportunities?.data;
      // tslint:disable-next-line:forin
      for (const bursary of this.bursaries) {
        if (bursary.uuid === uuid) {
          this.opportunity = bursary;
          console.log(bursary);
          // tslint:disable-next-line:max-line-length
          if (this.opportunity.document_checklist_json.length > 5) {
            this.documentRequirements = this.opportunity.document_checklist_json.toString ?  JSON.parse(this.opportunity.document_checklist_json.toString) : null;
          } else {
            this.documentRequirements = JSON.parse(null);
          }

          console.log(this.documentRequirements);
        }
      }
    });
  }

  // tslint:disable-next-line:variable-name
  onTabEX4(number) {
    this.homeEX4Tab = false;
    this.profileEX4Tab = false;
    this.awardedApplicationsEX4Tab = false;
    this.ContactEX4Tab = false;

    if (number === '1') {
      this.homeEX4Tab = true;
    } else if (number === '2') {
      this.profileEX4Tab = true;
    } else if (number === '3') {
      this.ContactEX4Tab = true;
    } else if (number === '4') {
      this.awardedApplicationsEX4Tab = true;
    }
  }

  //
  viewApplication(application: any) {
    application.modal_number = 1;
    application.view_type = 'view only';
    this.modalRef = this.modalService.show(ViewApplicationComponent, {
      class: 'modal-dialog-centered modal-xl',
      initialState: {
        application
      }
    });
  }
  updateApplicationStatus(application: any, status: any = 'pending') {
    delete application.profile;
    application.status = status;
    this.applicationService.updateApplication(application).then((data) => {
      this.ngOnInit();
      // tslint:disable-next-line:max-line-length
      Swal.fire('Application ' + status.toLowerCase(), data?.message || 'Your application has been ' + status.toLowerCase() + ' successfully!', 'success');
      console.log('Updating Applications', data);
    });
  }
  handleImageError(image) {
    image.parentNode.parentNode.style.display = 'none';
  }
  getPaginatedData(url) {
    this.applicationService.getPaginatedApplications(url).subscribe((data) => {
      const count: any = data;
      console.log('Getting All PAGINATED Opportunities : ', count?.profiles?.data?.length);
      this.bursaries = data;
    });
  }

  print() {
    const printContents = this.divToPrint.nativeElement.innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  imgError(image) {
    image.parentNode.parentNode.style.display = 'none';
  }
}
