export class InstitutionDocumentsResultModel {

  public uuid = '';
  // tslint:disable-next-line:variable-name
  public institution_uuid = '';
  // tslint:disable-next-line:variable-name
  public document_name = '';
  public path = '';
  public year = '';


  // tslint:disable-next-line:variable-name
  constructor(data, institution_uuid = '' ) {
    this.institution_uuid = institution_uuid;
    if (data !== null && data !== undefined) {
      this.uuid = data.uuid?.toString();
      this.document_name = data.document_name;
      this.path = data.path;
      this.year = data.year;
    }
  }
}
