export class PartnerModel {

    public uuid = '';
    // tslint:disable-next-line:variable-name
    public user_uuid = '';
    // tslint:disable-next-line:variable-name
    public company_name = '';
    // tslint:disable-next-line:variable-name
    public company_reg = '';
    public address = '';
    public website = '';
    // tslint:disable-next-line:variable-name
    public contact_number = '';
    // tslint:disable-next-line:variable-name
    public office_number = '';
    public email = '';
    public industry = '';
    public logo = '';
    constructor(data, uuid = '' ) {
        if (data !== null) {
            this.uuid = data?.uuid?.toString();
            this.user_uuid = data?.user_uuid?.toString();
            this.company_name = data?.company_name;
            this.company_reg = data?.company_reg;
            this.address = data?.address;
            this.website = data?.website;
            this.contact_number = data?.contact_number;
            this.office_number = data?.office_number;
            this.email = data?.email;
        }
    }
}
