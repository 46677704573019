import {AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import Swal from 'sweetalert2';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ApiService} from '../../services/api.service';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {LookupService} from '../../services/lookup/lookup.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'AdminCommentComponent',
    templateUrl: './comment.component.html',
})
export class CommentComponent implements  OnInit, AfterViewInit {

  @Input() relatedUuid: string;
  @Input() action: string;

  public comment: any = {related_uuid: '', user_uuid: '', comment: '', action: ''};
  public modalRef: BsModalRef;
  public commentEX1Tab: any = true;
  public commentEX2Tab: any = false;
  public comments: any;

  constructor(public apiService: ApiService, private modalService: BsModalService, private http: HttpClient, private lookupService: LookupService) {}

  ngOnInit(): void {
    this.getComments();
  }

  ngAfterViewInit() {}

  // open component modal
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'myCustomModalClass'});
    this.comment.related_uuid = this.relatedUuid;
  }


  // Comments Tabs
  commentTabs(index: any) {
    if (index === 1) {
      this.commentEX2Tab = false;
      this.commentEX1Tab = true;
    } else if (index === 2) {
      this.commentEX1Tab = false;
      this.commentEX2Tab = true;
    }
  }

  getComments() {
    const uuid = this.relatedUuid;
    this.lookupService.getCommentsByKeyValue('related_uuid', uuid).subscribe((data) => {
      this.comments = data;
      console.log('Getting All Comments: ', data.length);
    });
  }

  save() {
    const formData: FormData = new FormData();
    formData.append('related_uuid', this.comment.related_uuid);
    formData.append('user_uuid', localStorage.getItem('user_uuid'));
    formData.append('comment', this.comment.comment);
    formData.append('action', this?.action ? this?.action : '');

    const req = new HttpRequest('POST', `${environment.baseUrl}` + 'comment/store', formData, {
      reportProgress: true,
      responseType: 'json'
    });

    this.http.request(req).subscribe((data) => {
      console.log(data);
      this.getComments();
      this.commentTabs(2);
    });
  }
}
