import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpEvent, HttpRequest} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  private baseUrl;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }
  //
  uploadRelated(file: File, url, uuid, formDataInput = new FormData()): Observable<HttpEvent<any>> {
    const formData: FormData = formDataInput;
    if (!formDataInput.has('file')) {
      formData.append('file', file);
      formData.append('uuid', uuid);
    }

    const req = new HttpRequest('POST', `${this.baseUrl}` + url, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }
  //
  getFiles(): Observable<any> {
    return this.http.get(`${this.baseUrl}files`);
  }

  uploadSignature(file: Blob, url, uuid, formDataInput = new FormData()): Observable<HttpEvent<any>> {
    const formData: FormData = formDataInput;
    if (!formDataInput.has('file')) {
      formData.append('file', file);
      formData.append('bursary_agreement_signature', file);
      formData.append('uuid', uuid);
    }
    const req = new HttpRequest('POST', `${this.baseUrl}` + url, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }
}
