import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class SessionHandlerService {
  constructor(public router: Router, private apiService: ApiService) { }
  public startSession(res: any, router, route = '/student/dashboard') {
    console.log('Session Handler Getting User : ');
    localStorage.setItem('token', res.token);
    // tslint:disable-next-line:only-arrow-functions
    this.apiService.get_private('user').then(function(data) {
      console.log(data);
      localStorage.setItem('user_id', data?.id);
      localStorage.setItem('user_uuid', data?.uuid);
      localStorage.setItem('user_type', data?.type);
      localStorage.setItem('user_email', data?.email);
      localStorage.setItem('user_name', data?.name);
      localStorage.setItem('profile_uuid', data?.profile?.uuid ? data?.profile?.uuid : null);
      router.navigate([route], { queryParams: data });
    }, (error) => {
      console.log(error);
      this.destroySession(router, '/login');
    });
  }

  public destroySession(router, route = '/login') {
    localStorage.clear();
    router.navigate([route]);
  }
}
