import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {BursaryService} from '../../../services/bursary/bursary.service';

@Component({
  selector: 'app-todo-list',
  templateUrl: './todo-list.component.html',
  styleUrls: ['./todo-list.component.scss']
})
export class TodoListComponent implements OnInit {

  modalRef: BsModalRef;
  bursaries: any[] = [];
  companies: any[] = [];
  userType: any;

  constructor(
    private modalService: BsModalService,
    private bursaryService: BursaryService
  ) {
    this.userType = window.localStorage.getItem('user_type');
  }

  ngOnInit(): void {
    this.getBursaries();
    this.getCompanies();
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  getBursaries() {
    this.bursaryService.getBursaries().subscribe((data) => {
      this.bursaries = data;
    });
  }
  getCompanies() {
    this.bursaryService.getCompanies().subscribe((data) => {
      this.companies = data;
    });
  }
}
