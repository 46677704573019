import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {NotificationService} from '../notification.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  baseUrl: any = '';

  constructor(
    private router: Router,
    private http: HttpClient,
    private notify: NotificationService,
  ) {
    this.baseUrl = environment.baseUrl;
  }

  public getStudentApplications(): Observable<any[]> {
    console.log('Getting Students Applications');
    // tslint:disable-next-line:max-line-length
    return this.http.get<any[]>(`${this.baseUrl}application/application/${localStorage.getItem('profile_uuid')}`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  public getStudentApplicationsByStatus(status): Observable<any[]> {
    console.log('Getting Students Applications By Status');
    // tslint:disable-next-line:max-line-length
    return this.http.get<any[]>(`${this.baseUrl}application/students_applications_by_status/${status}/${localStorage.getItem('profile_uuid')}`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  // ALL
  public getAllApplications(): Observable<any[]> {
    console.log('Getting All Applications');
    // tslint:disable-next-line:max-line-length
    return this.http.get<any[]>(`${this.baseUrl}application/applications_by_status/all`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  public getApplicationsByStatus(status): Observable<any[]> {
    console.log('Getting All Applications By Status');
    // tslint:disable-next-line:max-line-length
    return this.http.get<any[]>(`${this.baseUrl}application/applications_by_status/${status}`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  // tslint:disable-next-line:variable-name
  public getFilteredApplications(string_value: string = ''): Observable<any[]> {
    console.log('Getting Filtered Applications');
    // tslint:disable-next-line:max-line-length
    return this.http.get<any[]>(`${this.baseUrl}application/${string_value}`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  public getApplicationsByType(type): Observable<any[]> {
    console.log('Getting All Applications By Status');
    // tslint:disable-next-line:max-line-length
    return this.http.get<any[]>(`${this.baseUrl}application/applications_by_type/${type}`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  public getApplicationsByUuid(uuid): Observable<any[]> {
    console.log('Getting All Applications By Status');
    // tslint:disable-next-line:max-line-length
    return this.http.get<any[]>(`${this.baseUrl}application/application_by_uuid/${uuid}`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  public updateApplication(application): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const header = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      };
      const param = this.JSON_to_URLEncoded(application);
      return this.http.post(`${this.baseUrl}application/update/${application.uuid}`, param, header).subscribe((data) => {
        resolve(data);
      }, error => {
        this.notify.errorBasicNotification(error.error.message, error.statusText);
        reject(error);
      });
    });
  }

  private handleError(error: any) {
    console.error('An error occurred:', error);
    // Handle the error and possibly throw a custom exception
    throw error;
  }

  JSON_to_URLEncoded(element: any, key?: any, list?: any) {
    // tslint:disable-next-line:variable-name
    const new_list = list || [];
    if (typeof element === 'object') {
      // tslint:disable-next-line:forin
      for (const idx in element) {
        this.JSON_to_URLEncoded(
          element[idx],
          key ? key + '[' + idx + ']' : idx,
          new_list
        );
      }
    } else {
      new_list.push(key + '=' + encodeURIComponent(element));
    }
    return new_list.join('&');
  }

  public getPaginatedApplications(url): Observable<any[]> {
    console.log('Getting All Paginated Applications');
    // tslint:disable-next-line:max-line-length
    return this.http.get<any[]>(url)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }
}
