import {ApplicationInitStatus, Component, OnInit} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {BursaryService} from '../../../services/bursary/bursary.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {ViewApplicationComponent} from '../../student/bursary/view-opportunity/apply/view-application/view-application.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ApplicationService} from '../../../services/application/application.service';

@Component({
  selector: 'app-ecommerce',
  templateUrl: './ecommerce.component.html',
  styleUrls: ['./ecommerce.component.scss']
})
export class EcommerceComponent implements OnInit {

  isFull: boolean;
  isFull1: any;
  private sub: any;
  uuid: any;
  company: any;
  opportunities: any;
  userType = 'ADMIN';
  apiUrl: any = environment.apiPublic;
  partners: any;
  user: any;
  modalRef: BsModalRef;

  constructor(private modalService: BsModalService, private authService: AuthService, private bursaryService: BursaryService, private applicationService: ApplicationService, public router: Router,     private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.loadUser();
    this.getCompanies();
    this.sub = this.route.params.subscribe(params => {
      if (params.uuid) {
        this.uuid = params.uuid; // (+) converts string 'id' to a number
        console.log(this.uuid);
        this.loadCompany(params.uuid);
        this.getBursaries(params.uuid);
      } else {
        this.getBursaries();
      }
    });
  }

  // Get Opportunity Posts
  getBursaries(uuid = false) {
    if (uuid !== false) {
      this.bursaryService.getPartnerOpportunities(uuid).subscribe((data) => {
        const bursaries: any = data;
        this.opportunities = bursaries.opportunities;
      });
    } else {
      this.bursaryService.getBursaries().subscribe((data) => {
        const bursaries: any = data;
        this.opportunities = bursaries.opportunities;
      });
    }
  }

  dashboardMenu() {
    document.getElementById('navbarNavDropdown').classList.toggle("show");
  }

  fullScreenSection(number) {
    if (number == 1) {
      if (this.isFull) {
        this.isFull = false;
      }
      else {
        this.isFull = true;
      }
    }
    else if (number == 2) {
      if (this.isFull1) {
        this.isFull1 = false;
      }
      else {
        this.isFull1 = true;
      }
    }
  }

  // auth check | fetch & load user
  loadUser(): void {
    this.authService.fetchUser().then((user) => {
      this.user = this.authService.getUser();
    });
  }

  // companies
  loadCompany(uuid): void {
    this.authService.fetchCompany(uuid).then((data) => {
      this.company = data.partner.length > 0 ? data.partner[0] : null;
    });
  }

  //
  getCompanies() {
    this.bursaryService.getCompanies().subscribe((data) => {
      this.partners = data;
      console.log(data);
    });
  }

  updateApplicationStatus(application: any, status: any = 'pending') {
    delete application.profile;
    application.status = status;
    this.applicationService.updateApplication(application).then((data) => {
      this.ngOnInit();
      console.log('Updating Applications', data);
    });
  }

  viewApplication(application: any) {
    this.modalRef = this.modalService.show(ViewApplicationComponent, {
      class: 'modal-dialog-centered modal-xl',
      initialState: {
        application
      }
    });
  }
}
