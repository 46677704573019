import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignupComponent } from './pages/signup/signup.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StudentDashboardComponent } from './../student/dashboard/dashboard.component';
import { StudentEmailComponent } from './../student/email/email.component';
import { StudentComposeEmailComponent } from './../student/email/compose-email/compose-email.component';
import { StudentBursaryComponent } from './../student/bursary/todo-list.component';
import { EmailComponent } from './email/email.component';
import { ChatComponent } from './chat/chat.component';
import { ChatComponent as HelpdeskChatComponent } from './../helpdesk/chat/chat.component';
import { UiHelperComponent } from './ui-element/ui-helper/ui-helper.component';
import { UiBootstrapComponent } from './ui-element/ui-bootstrap/ui-bootstrap.component';
import { UiTypographyComponent } from './ui-element/ui-typography/ui-typography.component';
import { UiTabsComponent } from './ui-element/ui-tabs/ui-tabs.component';
import { UiButtonsComponent } from './ui-element/ui-buttons/ui-buttons.component';
import { UiIconsComponent } from './ui-element/ui-icons/ui-icons.component';
import { UiNotificationsComponent } from './ui-element/ui-notifications/ui-notifications.component';
import { UiColorsComponent } from './ui-element/ui-colors/ui-colors.component';
import { UiDialogsComponent } from './ui-element/ui-dialogs/ui-dialogs.component';
import { UiListGroupComponent } from './ui-element/ui-list-group/ui-list-group.component';
import { UiMediaObjectComponent } from './ui-element/ui-media-object/ui-media-object.component';
import { UiModalsComponent } from './ui-element/ui-modals/ui-modals.component';
import { UiNestableComponent } from './ui-element/ui-nestable/ui-nestable.component';
import { UiProgressBarsComponent } from './ui-element/ui-progress-bars/ui-progress-bars.component';
import { UiRangeSlidersComponent } from './ui-element/ui-range-sliders/ui-range-sliders.component';
import { FormElementComponent } from './form-element/form-element.component';
import { TablesComponent } from './tables/tables.component';
import { ChartsComponent } from './charts/charts.component';
import { WidgetsComponent } from './widgets/widgets.component';
import { CalenderComponent } from './calender/calender.component';
import { TodoListComponent } from './todo-list/todo-list.component';
import { FilemanagerComponent } from './filemanager/filemanager.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ScrumboardComponent } from './scrumboard/scrumboard.component';
import { BlogComponent } from './blog/blog.component';
import { SocialComponent } from './social/social.component';
import { SettingsComponent } from './settings/settings.component';
import { ComposeEmailComponent } from './email/compose-email/compose-email.component';
import { EventsComponent } from './calender/events/events.component';
import { CampaignComponent } from './campaign/campaign.component';
import {FullReportComponent} from './full-report/full-report.component';
import { CryptocurrencyComponent } from './cryptocurrency/cryptocurrency.component';
import { EcommerceComponent } from './ecommerce/ecommerce.component';
import { MapsComponent } from './maps/maps.component';
import { ComposeEmailDetailsComponent } from './email/compose-email-details/compose-email-details.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { TimelineComponent } from './pages/timeline/timeline.component';
import { ImageGalleryComponent } from './pages/image-gallery/image-gallery.component';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { InvoiceDetailComponent } from './pages/invoices/invoice-detail/invoice-detail.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { SearchResultsComponent } from './pages/search-results/search-results.component';
import { BlankPageComponent } from './pages/blank-page/blank-page.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import {StudentFilemanagerComponent} from '../student/filemanager/filemanager.component';
import {StudentScrumboardComponent} from '../student/scrumboard/scrumboard.component';
import {StudentProfileComponent} from '../student/pages/profile/profile.component';
import {StudentComposeEmailDetailsComponent} from '../student/email/compose-email-details/compose-email-details.component';
import {StudentCalenderComponent} from '../student/calender/calender.component';
import {StudentEventsComponent} from '../student/calender/events/events.component';
import {StudentCampaignComponent} from '../student/campaign/campaign.component';
import {OpportunityDetailComponent} from '../student/pages/opportunity/opportunity-detail/opportunity-detail.component';
import {UserManagementComponent} from '../../user-management/user-management.component';
import {RecommendedApplicationsComponent} from '../../recommended-applications/recommended-applications.component';
import {ApprovedApplicationsComponent} from '../../approved-applications/approved-applications.component';
import {Document400Component} from '../../document400/document400.component';
import {RecommendedDoc400Component} from '../../recommended-doc400/recommended-doc400.component';
import {ApprovedDoc400Component} from '../../approved-doc400/approved-doc400.component';
import {CancelledDoc400Component} from '../../cancelled-doc400/cancelled-doc400.component';
import {DiscontinuedDoc400Component} from '../../discontinued-doc400/discontinued-doc400.component';
import {PrintPdfComponent} from '../../print-pdf/print-pdf.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: { title: 'Ubuntu IBMS | Dashboard' }
  },
  {
    path: 'user-management',
    component: UserManagementComponent,
    data: { title: 'Ubuntu IBMS | User Management' }
  },
  {
    path: 'cryptocurrency',
    component: CryptocurrencyComponent,
    data: { title: 'Ubuntu IBMS | Cryptocurrency' }
  },
  {
    path: 'campaign',
    component: CampaignComponent,
    data: { title: 'Ubuntu IBMS | Campaign' }
  },
  {
    path: 'full-report',
    component: FullReportComponent,
    data: { title: 'Ubuntu IBMS | Full Report' }
  },
  {
    path: 'ecommerce',
    component: EcommerceComponent,
    data: { title: 'Ubuntu IBMS | eCommerce ' }
  },
  {
    path: 'app-inbox',
    component: EmailComponent,
    data: { title: 'Ubuntu IBMS | Inbox' }
  },
  {
    path: 'app-chat',
    component: ChatComponent,
    data: { title: 'Ubuntu IBMS | Chat' }
  },
  {
    path: 'helpdesk',
    component: HelpdeskChatComponent,
    data: { title: 'Ubuntu IBMS | Chat' }
  },
  {
    path: 'ui-element/helper',
    component: UiHelperComponent,
    data: { title: 'Ubuntu IBMS | Helper Class' }
  },
  {
    path: 'ui-element/bootstrap',
    component: UiBootstrapComponent,
    data: { title: 'Ubuntu IBMS | Bootstrap UI' }
  },
  {
    path: 'ui-element/typography',
    component: UiTypographyComponent,
    data: { title: 'Ubuntu IBMS | UI Typography' }
  },
  {
    path: 'ui-element/tabs',
    component: UiTabsComponent,
    data: { title: 'Ubuntu IBMS | UI Tabs' }
  },
  {
    path: 'ui-element/buttons',
    component: UiButtonsComponent,
    data: { title: 'Ubuntu IBMS | UI Buttons' }
  },
  {
    path: 'ui-element/icons',
    component: UiIconsComponent,
    data: { title: 'Ubuntu IBMS | UI Icons' }
  },
  {
    path: 'ui-element/notifications',
    component: UiNotificationsComponent,
    data: { title: 'Ubuntu IBMS | UI Notifications' }
  },
  {
    path: 'ui-element/colors',
    component: UiColorsComponent,
    data: { title: 'Ubuntu IBMS | UI Colors' }
  },
  {
    path: 'ui-element/dialogs',
    component: UiDialogsComponent,
    data: { title: 'Ubuntu IBMS | UI Dialogs' }
  },
  {
    path: 'ui-element/listgroup',
    component: UiListGroupComponent,
    data: { title: 'Ubuntu IBMS | UI List Group' }
  },
  {
    path: 'ui-element/mediaobject',
    component: UiMediaObjectComponent,
    data: { title: 'Ubuntu IBMS | UI Media Object' }
  },
  {
    path: 'ui-element/modals',
    component: UiModalsComponent,
    data: { title: 'Ubuntu IBMS | UI Modals' }
  },
  {
    path: 'ui-element/nestabel',
    component: UiNestableComponent,
    data: { title: 'Ubuntu IBMS | UI Nestabel' }
  },
  {
    path: 'ui-element/progressbars',
    component: UiProgressBarsComponent,
    data: { title: 'Ubuntu IBMS | UI Progressbars ' }
  },
  {
    path: 'ui-element/rangesliders',
    component: UiRangeSlidersComponent,
    data: { title: 'Ubuntu IBMS | UI Range Sliders' }
  },
  {
    path: 'formelement',
    component: FormElementComponent,
    data: { title: 'Ubuntu IBMS | Form-element' }
  },
  {
    path: 'tableelement',
    component: TablesComponent,
    data: { title: 'Ubuntu IBMS | Table-element' }
  },
  {
    path: 'chartelement',
    component: ChartsComponent,
    data: { title: 'Ubuntu IBMS | Chart-element' }
  },
  {
    path: 'widgets',
    component: WidgetsComponent,
    data: { title: 'Ubuntu IBMS | Widgets' }
  },
  {
    path: 'calender',
    component: CalenderComponent,
    data: { title: 'Ubuntu IBMS | Calender' }
  },
  {
    path: 'todolist',
    component: TodoListComponent,
    data: { title: 'Ubuntu IBMS | TodoList' }
  },
  {
    path: 'filemanager',
    component: FilemanagerComponent,
    data: { title: 'Ubuntu IBMS | Filemanager' }
  },
  {
    path: 'contacts',
    component: ContactsComponent,
    data: { title: 'Ubuntu IBMS | Contacts' }
  },
  {
    path: 'scrumboard',
    component: ScrumboardComponent,
    data: { title: 'Ubuntu IBMS | Scrumboard' }
  },
  {
    path: 'blog',
    component: BlogComponent,
    data: { title: 'Ubuntu IBMS | Blog' }
  },
  {
    path: 'social',
    component: SocialComponent,
    data: { title: 'Ubuntu IBMS | Social' }
  },
  {
    path: 'settings',
    component: SettingsComponent,
    data: { title: 'Ubuntu IBMS | Settings' }
  },
  {
    path: 'composeemail',
    component: ComposeEmailComponent,
    data: { title: 'Ubuntu IBMS | ComposeEmail' }
  },
  {
    path: 'composeemail/composeemail-details',
    component: ComposeEmailDetailsComponent,
    data: { title: 'Ubuntu IBMS | ComposeEmailDetails' }
  },

  {
    path: 'calender/events',
    component: EventsComponent,
    data: { title: 'Ubuntu IBMS | Events' }
  },
  {
    path: 'map',
    component: MapsComponent,
    data: { title: 'Ubuntu IBMS | Maps' }
  },
  {
    path: 'pages/pricing',
    component: PricingComponent,
    data: { title: 'Ubuntu IBMS | Pricing' }
  },
  {
    path: 'pages/timeline',
    component: TimelineComponent,
    data: { title: 'Ubuntu IBMS | Timeline' }
  },
  {
    path: 'pages/image-gallery',
    component: ImageGalleryComponent,
    data: { title: 'Ubuntu IBMS | Image-Gallery' }
  },
  {
    path: 'pages/invoices',
    component: InvoicesComponent,
    data: { title: 'Ubuntu IBMS | Invoices' }
  },
  {
    path: 'pages/invoices/invoice-detail',
    component: InvoiceDetailComponent,
    data: { title: 'Ubuntu IBMS | InvoiceDetail' }
  },
  {
    path: 'pages/profile',
    component: ProfileComponent,
    data: { title: 'Ubuntu IBMS | Profile' }
  },
  {
    path: 'pages/search-result',
    component: SearchResultsComponent,
    data: { title: 'Ubuntu IBMS | SearchResults' }
  },
  {
    path: 'pages/blank-page',
    component: BlankPageComponent,
    data: { title: 'Ubuntu IBMS | BlankPage' }
  },
  {
    path: 'pages/page-404',
    component: PageNotFoundComponent,
    data: { title: 'Ubuntu IBMS | PAGE-404' }
  },
  {
    path: 'pages/signup',
    component: SignupComponent,
    data: { title: 'Ubuntu IBMS | Signup' }
  },
  {
    path: 'student/dashboard',
    component: StudentDashboardComponent,
    data: { title: 'Ubuntu IBMS | Student Dashboard' }
  },
  {
    path: 'student/filemanager',
    component: StudentFilemanagerComponent,
    data: { title: 'Ubuntu IBMS | Student File Manager' }
  },
  {
    path: 'student/calendar',
    component: StudentCalenderComponent,
    data: { title: 'Ubuntu IBMS | Student Calendar' }
  },
  {
    path: 'student/events',
    component: StudentEventsComponent,
    data: { title: 'Ubuntu IBMS | Student Events' }
  },
  {
    path: 'student/email',
    component: StudentEmailComponent,
    data: { title: 'Ubuntu IBMS | Student Email' }
  },
  {
    path: 'student/email/compose',
    component: StudentComposeEmailComponent,
    data: { title: 'Ubuntu IBMS | Student Compose Email' }
  },
  {
    path: 'student/composeemail',
    component: StudentComposeEmailComponent,
    data: { title: 'Ubuntu IBMS | Student Compose Email' }
  },
  {
    path: 'student/composeemail/composeemail-details',
    component: StudentComposeEmailDetailsComponent,
    data: { title: 'Ubuntu IBMS | STudent Compose Email Details' }
  },
  {
    path: 'student/scrumboard',
    component: StudentScrumboardComponent,
    data: { title: 'Ubuntu IBMS | Student Compose Email' }
  },
  {
    path: 'student/profile',
    component: StudentProfileComponent,
    data: { title: 'Ubuntu IBMS | Student Profile' }
  },
  {
    path: 'student/bursaries',
    component: StudentBursaryComponent,
    data: { title: 'Ubuntu IBMS | Student Bursaries' }
  },
  {
    path: 'bursaries/all',
    component: StudentBursaryComponent,
    data: { title: 'Ubuntu IBMS | Student Bursaries' }
  },
  {
    path: 'company/bursaries',
    component: TodoListComponent,
    data: { title: 'Ubuntu IBMS | Student Bursaries' }
  },
  {
    path: 'student/campaign',
    component: StudentCampaignComponent,
    data: { title: 'Ubuntu IBMS | Student Campaign' }
  },
  {
    path: 'admin/profile',
    component: ProfileComponent,
    data: { title: 'Ubuntu IBMS | Student Profile' }
  },
  {
    path: 'dashboard/dashboard',
    component: DashboardComponent,
    data: { title: 'Ubuntu IBMS | Company Dashboard' }
  },
  {
    path: 'dashboard/dashboard/:uuid',
    component: DashboardComponent,
    data: { title: 'Ubuntu IBMS | Company Dashboard' }
  },
  {
    path: 'opportunity-detail/:uuid',
    component: OpportunityDetailComponent,
    data: { title: 'Ubuntu IBMS | Opportunity Detail' }
  },
  {
    path: 'admin/scrumboard',
    component: ScrumboardComponent,
    data: { title: 'Ubuntu IBMS | Admin Compose Email' }
  },
  {
    path: 'admin/reports',
    component: ChartsComponent,
    data: { title: 'Ubuntu IBMS | Admin Reports' }
  },
  {
    path: 'client/report/:uuid',
    component: EcommerceComponent,
    data: { title: 'Ubuntu IBMS | eCommerce ' }
  },
  {
    path: 'recommended-applications',
    component: RecommendedApplicationsComponent,
    data: { title: 'Ubuntu IBMS | Recommended Applications ' }
  },
  {
    path: 'approved-applications',
    component: ApprovedApplicationsComponent,
    data: { title: 'Ubuntu IBMS | Approved Applications ' }
  },
  {
    path: 'doc-400',
    component: Document400Component,
    data: { title: 'Ubuntu IBMS | Document 400 Report' }
  },
  {
    path: 'recommended-doc-400',
    component: RecommendedDoc400Component,
    data: { title: 'Ubuntu IBMS | Recommended (Doc 400)' }
  },
  {
    path: 'approved-doc-400',
    component: ApprovedDoc400Component,
    data: { title: 'Ubuntu IBMS | Approved (Doc 400)' }
  },
  {
    path: 'cancelled-doc-400',
    component: CancelledDoc400Component,
    data: { title: 'Ubuntu IBMS | Cancelled (Doc 400)' }
  },
  {
    path: 'discontinued-doc-400',
    component: DiscontinuedDoc400Component,
    data: { title: 'Ubuntu IBMS | Discontinued (Doc 400)' }
  },
  {
    path: 'pdf',
    component: PrintPdfComponent,
    data: { title: 'Ubuntu IBMS | PDF' }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClientRoutingModule {
  static components = [
    SignupComponent,
    DashboardComponent

  ];

}
