import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compose-email-details',
  templateUrl: './compose-email-details.component.html',
  styleUrls: ['./compose-email-details.component.scss']
})
export class ComposeEmailDetailsComponent implements OnInit {

  userType: any;

  constructor() {
    this.userType = window.localStorage.getItem('user_type');
  }

  ngOnInit(): void {
  }

}
