import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LookupService {
  baseUrl: any = '';

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.baseUrl = environment.baseUrl;
  }

  // Partners
  public getPartners(): Observable<any[]> {
    console.log('Getting Partners');
    return this.http.get<any[]>(`${this.baseUrl}partner/all`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  // Provinces
  public getProvinces(): Observable<any[]> {
    console.log('Getting Provinces');
    return this.http.get<any[]>(`${this.baseUrl}province/all`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  // Cities
  public getCities(): Observable<any[]> {
    console.log('Getting Cities');
    return this.http.get<any[]>(`${this.baseUrl}city/all`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  // Institutions
  public getInstitutions(): Observable<any[]> {
    console.log('Getting Institution');
    return this.http.get<any[]>(`${this.baseUrl}institution/all`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  // Modules
  public getModules(): Observable<any[]> {
    console.log('Getting Modules');
    return this.http.get<any[]>(`${this.baseUrl}module/all`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  // Course
  public getCourse(): Observable<any[]> {
    console.log('Getting Courses');
    return this.http.get<any[]>(`${this.baseUrl}course/all`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  // Qualifications
  public getQualifications(): Observable<any[]> {
    console.log('Getting Faculty');
    return this.http.get<any[]>(`${this.baseUrl}qualification/all`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  // Comments
  public getCommentsByKeyValue(key, val): Observable<any[]> {
    console.log('Getting Comments for Key : Value {' + key + '' + val + '}');
    return this.http.get<any[]>(`${this.baseUrl}comment/get/${key}/${val}`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  private handleError(error: any) {
    console.error('An error occurred:', error);
    // Handle the error and possibly throw a custom exception
    throw error;
  }
}
