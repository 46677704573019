import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Profile} from '../../../models/profile.model';
import {AuthService} from '../../../services/auth.service';
import {environment} from '../../../../environments/environment';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import Swal from 'sweetalert2';
import {FileUploadService} from '../../../services/upload/file-upload.service';
import {ApiService} from '../../../services/api.service';
import {NotificationService} from '../../../services/notification.service';
import {Opportunity} from '../../../models/opportunity.model';
import {BursaryService} from '../../../services/bursary/bursary.service';
import {DatePipe} from '@angular/common';
import {StudentService} from '../../../services/student/student.service';
import {ApplicationService} from '../../../services/application/application.service';
import {LookupService} from '../../../services/lookup/lookup.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  // ui members
  isFull: boolean;
  isFull1: any;
  isFull3: boolean;
  FeedTab = false;
  ActivityTab: boolean;
  HomeTab = true;
  userType = 'ADMIN';
  adminType = localStorage.getItem('admin_type');
  opportunityTabHeader = 'Publish Opportunity';
  apiUrl: any = environment.apiPublic;

  // data members
  user: any;
  opportunityModel: Opportunity;
  profilePercentage: any;
  showPercentage: any;
  apiBaseUrl: any;
  attachmentFiles: any;
  uuid: number;
  private sub: any;
  company: any;
  partners: any;

  // opporunity
  opportunity: {};
  opportunities: any [] = [];
  publishedOpportunities: any [] = [];
  archivedOpportunities: any [] = [];

  // file upload members
  selectedFiles?: FileList;
  progressInfos: any[] = [];
  message: string[] = [];
  previews: string[] = [];

  selectedRequiredDocuments: string[] = [];
  apiSearchString: any = '';
  setSearchString: any;
  requirementsTab = true;
  allApplicationsTab: boolean;
  incompleteApplicationsTab: boolean;
  completeApplicationsTab: boolean;
  withdrawalApplicationsTab: boolean;
  recommendedApplicationsTab: boolean;
  declinedApplicationsTab: boolean;
  approvedApplicationsTab: boolean;
  renewalApplicationsTab: boolean;
  suspendedApplicationsTab: boolean;
  reInstatedApplicationsTab: boolean;
  showFilter: any = false;
  applicationsTabPane: boolean;
  searchFilter: any = {
    genders: ['*', 'Male', 'Female', 'Other', 'Choose to Not Say', 'I do not Identify Myself as Any of the Above'],
    // tslint:disable-next-line:max-line-length
    provinces: [],
    cities: [],
    institutions: [],
    institutionTypes: ['*', 'TVET College', 'University of Technology', 'University'],
    yearsOfStudy: ['*', '1st', '2nd', '3rd'],
    geographics: ['*', 'RURAL', 'TOWNSHIP', 'URBAN'],
    faculties: [],
    bursaryYears: ['*', '2024', '2023', '2022', '2021', '2020'],
    courses: [],
    statuses: ['*', 'New', 'Approved', 'Declined', 'Recommended']
  };

  filtered = false;
  companyUuid = localStorage.getItem('related_uuid');
  opportunityAllApplications: any;
  opportunityIncompleteApplications: any;
  opportunityRecommendedApplications: any;
  opportunityApprovedApplications: any;
  partner = 'Fasset';

  constructor(
    public router: Router,
    private notify: NotificationService,
    public authService: AuthService,
    private route: ActivatedRoute,
    private uploadService: FileUploadService,
    private apiService: ApiService,
    private bursaryService: BursaryService,
    public datePipe: DatePipe,
    public studentService: StudentService,
    private applicationService: ApplicationService,
    public lookupService: LookupService,
  ) {
    this.opportunityModel = new Opportunity(null, null);
    this.profilePercentage = 5;
    this.showPercentage = true;
    this.opportunity = {type: 'Bursary'};
    this.apiBaseUrl = environment.apiPublic;
    this.lookupProvinces();
    if (this.companyUuid !== 'N/A') {
      this.bursaryService.getPartnerFirstOpportunity(this.companyUuid).subscribe((data) => {
        // @ts-ignore
        this.loadApplications(data.uuid);
      });
    } else {
      this.loadApplications('12');
    }
  }

  ngOnInit(): void {
    this.filtered = false;
    this.loadUser();
    this.getCompanies();

    this.sub = this.route.params.subscribe(params => {
      if (params.uuid) {
        this.uuid = params.uuid; // (+) converts string 'id' to a number
        console.log(this.uuid);
        this.loadCompany(params.uuid);
        this.getBursaries(params.uuid);
      } else {
        this.getBursaries();
      }
    });
  }

  // auth check | fetch & load user
  loadUser(): void {
    this.authService.fetchUser().then((user) => {
      this.user = this.authService.getUser();
    });
  }

  // companies
  loadCompany(uuid): void {
    this.authService.fetchCompany(uuid).then((data) => {
      this.company = data.partner.length > 0 ? data.partner[0] : null;
    });
  }

  //
  getCompanies() {
    this.bursaryService.getCompanies().subscribe((data) => {
      this.partners = data;
    });
  }

  // closeMenu() {
  //   const body = document.getElementsByTagName('body')[0].classList.remove('offcanvas-active');
  // }

  dashboardMenu() {
    document.getElementById('navbarNavDropdown').classList.toggle('show');
  }

  // tslint:disable-next-line:variable-name
  fullScreenSection(number) {
    if (number === 1) {
      if (this.isFull) {
        this.isFull = false;
      } else {
        this.isFull = true;
      }
    } else if (number === 2) {
      if (this.isFull1) {
        this.isFull1 = false;
      } else {
        this.isFull1 = true;
      }
    } else if (number === 3) {
      if (this.isFull3) {
        this.isFull3 = false;
      } else {
        this.isFull3 = true;
      }
    }

  }

  // tslint:disable-next-line:variable-name
  onTab(number) {
    this.FeedTab = false;
    this.ActivityTab = false;
    this.HomeTab = false;
    if (number === '1') {
      this.FeedTab = true;
      this.opportunityTabHeader = 'Publish Opportunity';
      this.opportunityModel = new Opportunity(null, null);
    } else if (number === '2') {
      this.ActivityTab = true;
    } else if (number === '3') {
      this.HomeTab = true;
    }
  }

  // Selected Partner
  selectRequiredPartner(event: any): void {
    this.opportunityModel.partner_uuid = event.target.value;
  }

  // Selected DOcument
  selectRequiredDocuments(event: any): void {
    const numberOfDocuments = this.selectedRequiredDocuments.length;
    this.selectedRequiredDocuments.push(event.target.value);
    // console.log(event.target.text + '\n');
    // console.log(event.target);
  }


  // File Upload
  selectFiles(event: any): void {
    this.message = [];
    this.progressInfos = [];
    this.selectedFiles = event.target.files;
    this.previews = [];
    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.previews.push(e.target.result);
        };
        reader.readAsDataURL(this.selectedFiles[i]);
      }
    }
  }

  upload(idx: number, file: File): void {
    this.progressInfos[idx] = {value: 0, fileName: file.name};
    const formDataInput = new FormData();
    formDataInput.append('image_banner', file);
    if (file) {
      // tslint:disable-next-line:max-line-length
      this.uploadService.uploadRelated(file, 'opportunity/update/' + this.opportunityModel.uuid, this.opportunityModel.uuid, formDataInput).subscribe(
        (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfos[idx].value = Math.round(
              (100 * event.loaded) / event.total
            );
          } else if (event instanceof HttpResponse) {
            Swal.fire('File Upload!', 'Successful Upload!', 'success');
            // this.message.push(msg);
            // this.imageInfos = this.uploadService.getFiles();
          }
        }, (err: any) => {
          this.progressInfos[idx].value = 0;
          const msg = 'Could not upload the file: ' + file.name;
          this.message.push(msg);
        }
      );
    }
  }

  saveOpportunity() {
    delete this.opportunityModel.uuid;
    this.opportunityModel.document_checklist_json = this.selectedRequiredDocuments.toString();
    this.opportunityModel.status = 'PUBLISHED';
    this.opportunityModel.publish_date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.apiService.post_private('opportunity/store', this.opportunityModel).then((response) => {
      console.log('######################## \n Opportunity Success Response : \n ####################### \n');
      console.log(response);
      Swal.fire(
        'Opportunity (' + this.opportunityModel.type + ') Created!',
        response?.message || 'Opportunity Successfully saved!', 'success'
      );

      this.opportunityModel.uuid = response.opportunity.uuid;
      if (this.selectedFiles[0]) {
        this.uploadRelated(this.selectedFiles[0], this.opportunityModel.uuid);
      }

      this.router.navigate(['/bursaries/all']);
    }, (error) => {
      console.log('Opportunity Error Response : \n');
      console.log(error);
      this.notify.confirmDialog(
        'Some Error Occured. Do You Want to reload and try again?', this.ngOnInit(),
        'Create Student Profile',
        'error'
      );
    });
  }

  uploadRelated(file, uuid) {
    this.uploadService.uploadRelated(file, 'opportunity/update/' + uuid, uuid).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          // this.progressInfos[idx].value = Math.round(
          //   (100 * event.loaded) / event.total
          // );
        } else if (event instanceof HttpResponse) {
          Swal.fire('File Upload!', 'Successful Upload!', 'success');
          // this.message.push(msg);
          // this.imageInfos = this.uploadService.getFiles();
        }
      }, (err: any) => {
        // this.progressInfos[idx].value = 0;
        const msg = 'Could not upload the file: ' + file.name;
        this.message.push(msg);
      }
    );
  }

  uploadFiles(): void {
    this.message = [];
    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.upload(i, this.selectedFiles[i]);
      }
    }
  }

  // Get Opportunity Posts
  getBursaries(uuid: string = null) {
    if (uuid !== null) {
      // Get All Opportunities
      this.bursaryService.getPartnerOpportunities(uuid).subscribe((data) => {
        const bursaries: any = data;
        this.opportunities = bursaries?.opportunities;
      });
      // Get Published Opportunities
      this.bursaryService.getPartnerOpportunitiesByStatus(uuid, 'published').subscribe((data) => {
        const bursaries: any = data;
        this.publishedOpportunities = bursaries?.opportunities;
      });
      // Get Archived Opportunities
      this.bursaryService.getPartnerOpportunitiesByStatus(uuid, 'archived').subscribe((data) => {
        const bursaries: any = data;
        this.archivedOpportunities = bursaries?.opportunities;
      });
    } else {
      this.bursaryService.getBursaries().subscribe((data) => {
        const bursaries: any = data;
        this.opportunities = bursaries?.opportunities;
      });
    }
  }

  // delete
  deleteOpportunity(uuid) {
    // @ts-ignore
    Swal.fire({
      title: 'Do you want to delete this listing?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        denyButton: 'order-3',
      }
    }).then((result) => {
      if (result.isConfirmed) {
        this.bursaryService.deleteBursary(uuid).then((data) => {
          Swal.fire('Deleted Listing', '', 'success');
          this.ngOnInit();
        });
      } else if (result.isDenied) {
        Swal.fire('Listing are not deleted', '', 'info');
      }
    });
  }

//  Filters

  setFilter(value: string, reset: boolean = false, index: number) {
    const selectedValue = value;
    const selectedIndex = index;

    // Your logic to maintain an array of selected values and indexes goes here
    if (!this.setSearchString) {
      this.setSearchString = [];
    }

    // Find the index of the existing item with the same index
    const existingIndex = this.setSearchString.findIndex(item => item.index === selectedIndex);

    // If an item with the same index exists, update its value
    if (existingIndex !== -1) {
      this.setSearchString[existingIndex].value = selectedValue;
    } else {
      // If no item with the same index exists, add the selected value and index into the array
      this.setSearchString.push({value: selectedValue, index: selectedIndex});
    }

    let searchUrl = '';
    this.setSearchString.forEach(item => {
      if (!item.value.includes('*')) { // Check if the value does not contain '*'
        searchUrl += item.value;
      }
    });

    console.log('Search URL:', searchUrl);

    if (searchUrl !== '') {
      this.filtered = true;
      this.getFilteredOpportunities(searchUrl);
    } else {
      this.ngOnInit();
    }
  }


  getFilteredOpportunities(str) {
    this.bursaryService.getFilteredOpportunities(str).subscribe((data) => {
      const bursaries: any = data;
      this.opportunities = bursaries.opportunities;
      console.log(this.opportunities);
    });
  }

  editOpportunity(opportunity: any) {
    this.ActivityTab = true;
    this.FeedTab = false;
    this.opportunityTabHeader = 'Update Opportunity';
    this.opportunityModel = new Opportunity(opportunity, null);
  }

  saveOrUpdateOpportunity() {
    if (this.opportunityModel.uuid) {
      this.updateOpportunity();
    } else {
      this.saveOpportunity();
    }
  }

  updateOpportunity() {
    this.opportunityModel.document_checklist_json = this.selectedRequiredDocuments.toString();
    this.opportunityModel.publish_date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.apiService.post_private(`opportunity/update/${this.opportunityModel?.uuid}`, this.opportunityModel).then((response) => {
      console.log('######################## \n Opportunity Success Response : \n ####################### \n');
      console.log(response);
      Swal.fire(
        'Opportunity Updated!',
        response?.message, 'success'
      );

      if (this.selectedFiles[0]) {
        this.uploadRelated(this.selectedFiles[0], this.opportunityModel.uuid);
      }
    }, (error) => {
      console.log('Opportunity Error Response : \n');
      console.log(error);
      this.notify.confirmDialog(
        'Some Error Occured. Do You Want to reload and try again?', this.ngOnInit(),
        'Create Student Profile',
        'error'
      );
    });
  }

  getPaginatedData(url) {
    this.bursaryService.getFilteredOpportunities(url).subscribe((data) => {
      const count: any = data;
      console.log('Getting All PAGINATED Students : ', count?.profiles?.data?.length);
      this.opportunities = data;
    });
  }

  // tslint:disable-next-line:variable-name
  onTabEX4(number) {
    this.requirementsTab = false;
    this.allApplicationsTab = false;
    this.incompleteApplicationsTab = false;
    this.completeApplicationsTab = false;
    this.withdrawalApplicationsTab = false;
    this.recommendedApplicationsTab = false;
    this.declinedApplicationsTab = false;
    this.approvedApplicationsTab = false;
    this.renewalApplicationsTab = false;
    this.suspendedApplicationsTab = false;
    this.reInstatedApplicationsTab = false;

    if (number === '1') {
      this.requirementsTab = true;
    } else {
      this.applicationsTabPane = true;
      if (number === '2') {
        this.allApplicationsTab = true;
      } else if (number === '3') {
        this.incompleteApplicationsTab = true;
      } else if (number === '4') {
        this.completeApplicationsTab = true;
      } else if (number === '5') {
        this.withdrawalApplicationsTab = true;
      } else if (number === '6') {
        this.recommendedApplicationsTab = true;
      } else if (number === '7') {
        this.declinedApplicationsTab = true;
      } else if (number === '8') {
        this.approvedApplicationsTab = true;
      } else if (number === '9') {
        this.renewalApplicationsTab = true;
      } else if (number === '10') {
        this.suspendedApplicationsTab = true;
      } else if (number === '11') {
        this.reInstatedApplicationsTab = true;
      }
    }
  }

  updateApplicationStatus(application: any, status: any = 'pending') {
    delete application.profile;
    application.status = status;
    this.applicationService.updateApplication(application).then((data) => {
      this.ngOnInit();
      // tslint:disable-next-line:max-line-length
      Swal.fire('Application ' + status.toLowerCase(), data?.message || 'Your application has been ' + status.toLowerCase() + ' successfully!', 'success');
      console.log('Updating Applications', data);
    });
  }

  lookupProvinces() {
    this.searchFilter.provinces = this.lookupService.getProvinces().subscribe((data) => {
      this.searchFilter.provinces = data;
      console.log('Getting All Filtered Provinces : ', data.length);
    });
  }

  loadApplications(opportunityUuid) {
    this.bursaryService.getProfilesCount('All', this.partner).subscribe((data) => {
      console.log(data);
      this.opportunityAllApplications = data;
    });
    this.bursaryService.getProfilesCount('Incomplete', this.partner).subscribe((data) => {
      console.log(data);
      this.opportunityIncompleteApplications = data;
    });
    this.bursaryService.getProfilesCount('Recommended', this.partner).subscribe((data) => {
      this.opportunityRecommendedApplications = data;
    });
    this.bursaryService.getProfilesCount('Approved', this.partner).subscribe((data) => {
      console.log(data);
      this.opportunityApprovedApplications = data;
    });
  }
}
