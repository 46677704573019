import {Component, Input, OnInit, TemplateRef, Renderer2} from '@angular/core';
import {TicketModel} from '../../../models/ticket.model';
import Swal from 'sweetalert2';
import {ApiService} from '../../../services/api.service';
import {NotificationService} from '../../../services/notification.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {AuthService} from '../../../services/auth.service';
import {TicketService} from '../../../services/ticket/ticket.service';

@Component({
  selector: 'app-create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: ['./create-ticket.component.scss']
})
export class CreateTicketComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  TicketModel: TicketModel;
  modalRef: BsModalRef;

  // members
  homeEX4Tab: any = true;
  profileEX4Tab: any;
  ContactEX4Tab: any;
  // tslint:disable-next-line:variable-name
  user_type: any;
  applications: any;

  constructor(
    public apiService: ApiService,
    private notify: NotificationService,
    public modalService: BsModalService,
    public authService: AuthService,
    private renderer: Renderer2,
    private ticketService: TicketService
  ) {
    this.user_type = localStorage.getItem('user_type');
  }

  ngOnInit(): void {
    this.loadTicketComponent();
  }
  loadTicketComponent(): void {
    this.TicketModel = new TicketModel(null, null);
    // Get Student Applications for the dropdown
    this.ticketService.getStudentApplications().subscribe((data) => {
      this.applications = data;
    });
  }
  closeModal(): void {
    this.renderer.addClass(document.body, 'enable-scroll');
    this.modalService.hide(1);
  }

  previewImage(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input?.files?.length) {
      const fileUpload = input.files[0];
      const fileReader = new FileReader();

      fileReader.onload = (e) => {
        const imgElement = document.getElementById('imgCont') as HTMLImageElement;
        if (imgElement) {
          imgElement.src = e.target?.result as string;
        }
      };

      fileReader.readAsDataURL(fileUpload);
    }
  }

  createTicket() {
    this.apiService.helpdesk_post_private('create_ticket', this.TicketModel).then((response) => {
      console.log(response);
      if (response?.status === 'success') {
        this.loadTicketComponent();
        Swal.fire('Ticket Created!', response?.message, 'success');
      } else {
        this.notify.confirmDialog(
            'Some Error Occurred. Do You Want to reload and try again?',
            'Create Ticket',
            'error'
        );
      }
    }, (error) => {
      this.notify.confirmDialog(
          'Some Error Occurred. Do You Want to reload and try again?',
          'Create Ticket',
          'error'
      );
    });
  }
}
