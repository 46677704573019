export class AddressModel {

    public municipality = '';
    // tslint:disable-next-line:variable-name
    public suburb_town = '';
    // tslint:disable-next-line:variable-name
    public area_neighbourhood = '';
    // tslint:disable-next-line:variable-name
    public house_flat_number = '';
    // tslint:disable-next-line:variable-name
    public physical_address = '';
    // tslint:disable-next-line:variable-name
    public postal_code = '';
    // tslint:disable-next-line:variable-name
    public postal_municipality = '';
    // tslint:disable-next-line:variable-name
    public postal_suburb_town = '';
    // tslint:disable-next-line:variable-name
    public postal_area_neighbourhood = '';
    // tslint:disable-next-line:variable-name
    public postal_house_flat_number = '';

    // tslint:disable-next-line:variable-name
    constructor(data) {
        console.log(data);
        if (data != null) {
            this.municipality = data?.municipality;
            this.suburb_town = data?.suburb_town;
            this.area_neighbourhood = data?.area_neighbourhood;
            this.house_flat_number = data?.house_flat_number;
            this.physical_address = data?.physical_address;
            this.postal_code = data?.postal_code;
            this.postal_municipality = data?.postal_municipality;
            this.postal_suburb_town = data?.postal_suburb_town;
            this.postal_area_neighbourhood = data?.postal_area_neighbourhood;
            this.postal_house_flat_number = data?.postal_house_flat_number;
        }
    }
}
