import {LookupService} from '../../../services/lookup/lookup.service';

declare let $: any;
import { Component, OnInit, TemplateRef, Renderer2 } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {OpportunityService} from '../../../services/opportunity/opportunity.service';
import Swal from 'sweetalert2';
import {ViewOpportunityComponent} from './view-opportunity/view-opportunity.component';
import {ApplicationService} from '../../../services/application/application.service';
import {AuthService} from '../../../services/auth.service';
import {environment} from '../../../../environments/environment';
import {BursaryService} from '../../../services/bursary/bursary.service';


@Component({
  selector: 'app-todo-list',
  templateUrl: './todo-list.component.html',
  styleUrls: ['./todo-list.component.scss']
})
export class StudentBursaryComponent implements OnInit {

  modalRef: BsModalRef;
  listedOpportunities: any[] = [];
  allOpportunities: any[] = [];
  bursaryOpportunities: any[] = [];
  studentApplications: any[] = [];
  jobOpportunities: any[] = [];
  internshipOpportunities: any[] = [];
  userType: any;
  adminType: any;
  companyUuid: any;
  showFilter: any = false;
  apiBaseUrl: any;
  profilePercentage: any;

  // filter
  apiSearchString: any = '/status/PUBLISHED';
  filterBooleanArray: any = [];
  searchFilter: any = {
    genders: ['*', 'Male', 'Female', 'Other', 'Choose to Not Say', 'I do not Identify Myself as Any of the Above'],
    // tslint:disable-next-line:max-line-length
    provinces: [],
    cities: [],
    institutions: [],
    institutionTypes: ['*', 'TVET College', 'University of Technology', 'University'],
    yearsOfStudy: ['*', '1st', '2nd', '3rd'],
    geographics: ['*', 'RURAL', 'TOWNSHIP', 'URBAN'],
    faculties: [],
    bursaryYears: ['*', '2024', '2023', '2022', '2021', '2020'],
    courses: [],
    statuses: ['*', 'New', 'Approved', 'Declined', 'Recommended'],
    bursaryTypes: ['*', 'Bursary', 'Scholarship', 'Internship', 'Job']
  };
  constructor(
    private modalService: BsModalService,
    private opportunityService: OpportunityService,
    private applicationService: ApplicationService,
    private authService: AuthService,
    private renderer: Renderer2,
    private lookupService: LookupService,
    private bursaryService: BursaryService
  ) {
    this.userType = localStorage.getItem('user_type');
    this.adminType = localStorage.getItem('admin_type');
    this.companyUuid = localStorage.getItem('related_uuid');
    this.apiBaseUrl = environment.apiPublic;
    this.profilePercentage = 5;
  }

  ngOnInit(): void {
    this.loadUser();
    $('.js-example-basic-single').select2();
    this.getOpportunities(false);
    this.lookupProvinces();
    this.lookupCities();
    this.lookupInstitutions();
    this.lookupCourses();
    this.lookupModules();
  }
  loadUser(): void {
    this.authService.fetchUser().then((user) => {
      this.profilePercentage = this.authService.getProfilePercentage();
    });
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  getOpportunities(filter = false) {
    if (filter) {
      this.getFilteredOpportunities();
    } else if (this.userType === 'STUDENT') { // Student
      // Get All Opportunities
      this.opportunityService.getAllPublishedOpportunities().subscribe((data) => { // All Opportunities
        this.allOpportunities = data;
      });
    } else if (this.adminType === 'CLIENT') { // Client
      this.bursaryService.getPartnerOpportunities(this.companyUuid).subscribe((data) => {
        this.listedOpportunities = data;
      });
    } else { // Super Admin
      this.opportunityService.getAllOpportunities().subscribe((data) => { // Bursary Opportunities
        this.listedOpportunities = data;
      });
    }

    this.opportunityService.getOpportunitiesByStatusAndType('published', 'Bursary').subscribe((data) => { // Bursary Opportunities
      this.bursaryOpportunities = data;
    });

    this.opportunityService.getOpportunitiesByStatusAndType('published', 'Job').subscribe((data) => { // Job Opportunities
      this.jobOpportunities = data;
    });

    this.opportunityService.getOpportunitiesByStatusAndType('published', 'Internship').subscribe((data) => { // Job Opportunities
      this.internshipOpportunities = data;
    });
    // Get Students Applications
    this.applicationService.getStudentApplications().subscribe((data) => {
      this.studentApplications = data;
    });
  }
  viewApplicationDetails(opportunity: any) {
    opportunity.modal_number = 1;
    opportunity.profile_completion = this.profilePercentage;
    this.renderer.removeClass(document.body, 'enable-scroll');
    this.modalRef = this.modalService.show(ViewOpportunityComponent, {
      class: 'modal-dialog-centered modal-xl',
      initialState: {
        opportunity
      },
      backdrop: 'static',
    });
  }

  getRandomNumber(min, max) {
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

    return randomNumber;
  }

  // filter

  setFilter(event: any, index: any = false, reset: boolean = false) {
    const searchString = event.target.value;
    if (searchString.indexOf('*') !== -1) {

      // delete this.filterBooleanArray[index];
      console.log(this.filterBooleanArray);
      console.log('Deleting array item : ' + index + ' \n');
      this.filterBooleanArray.splice(index, 1);
      this.filterBooleanArray[index] = {filter: ''};
      console.log(this.filterBooleanArray);

      this.apiSearchString = '';
      this.filterBooleanArray.forEach(element => {
        console.log(element);
        this.setSearchString(element.filter);
      });

      this.getFilteredOpportunities();
      return;
    }

    this.filterBooleanArray[index] = {filter: searchString};
    this.filterBooleanArray.forEach(element => {
      this.setSearchString(searchString);
    });

    this.getFilteredOpportunities();
  }

  setSearchString(searchString: any) {
    console.log('Setting Search String');
    if (this.apiSearchString.includes(searchString)) {
      return;
    }

    // 1. get existing search string of key
    console.log(this.apiSearchString.substring(this.apiSearchString.indexOf('/') , searchString.indexOf('/')));

    // 3. join key/ with new value
    this.apiSearchString.slice(0, searchString.lastIndexOf('/'));
    this.apiSearchString += searchString;
    console.log(this.apiSearchString);
  }

  //
  getFilteredOpportunities() {
    const str = this.apiSearchString;
    // tslint:disable-next-line:no-unused-expression
    (str.charAt(str.length - 1) === '/') ? str.substr(0, str.length - 1) : str;
    this.bursaryService.getFilteredOpportunities(str).subscribe((data) => {
      this.allOpportunities = data;
      console.log('Getting All Filtered Students : ', data);
    });
  }

  // lookups

  lookupProvinces() {
    this.searchFilter.provinces = this.lookupService.getProvinces().subscribe((data) => {
      this.searchFilter.provinces = data;
      console.log('Getting All Filtered Provinces : ', data.length);
    });
  }
  lookupCities() {
    this.searchFilter.provinces = this.lookupService.getCities().subscribe((data) => {
      this.searchFilter.cities = data;
      console.log('Getting All Filtered Cities : ', data.length);
    });
  }
  lookupInstitutions() {
    this.searchFilter.provinces = this.lookupService.getInstitutions().subscribe((data) => {
      this.searchFilter.institutions = data;
      console.log('Getting All Filtered Institutions : ', data.length);
    });
  }
  lookupCourses() {
    this.searchFilter.provinces = this.lookupService.getCourse().subscribe((data) => {
      this.searchFilter.courses = data;
      console.log('Getting All Filtered Courses : ', data.length);
    });
  }
  lookupModules() {
    this.searchFilter.provinces = this.lookupService.getModules().subscribe((data) => {
      this.searchFilter.faculties = data;
      console.log('Getting All Filtered Modules : ', data.length);
    });
  }
  getPaginatedData(url) {
    this.applicationService.getPaginatedApplications(url).subscribe((data) => {
      const count: any = data;
      console.log('Getting All PAGINATED Students : ', count?.profiles?.data?.length);
      this.allOpportunities = data;
    });
  }
}
