import { Component, Input } from '@angular/core';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-document-downloader',
  templateUrl: './document-downloader.component.html',
  styleUrls: ['./document-downloader.component.scss']
})
export class DocumentDownloaderComponent {
  @Input() application: any; // Replace 'any' with the actual type of your application object

  downloadDocuments() {
    const attachments = this.application?.profile?.attachments;
    const zip = new JSZip();

    attachments.forEach((attachment, index) => {
      fetch(attachment.path)
          .then(response => response.blob())
          .then(blob => zip.file(`document_${index + 1}.${attachment.file_type}`, blob))
          .catch(error => console.error('Error downloading document:', error));
    });

    zip.generateAsync({ type: 'blob' }).then(blob => {
      saveAs(blob, this.application?.profile?.first_name + '_' + this.application?.profile?.surname + '_' + 'documents.zip');
    });
  }
}
