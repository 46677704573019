import { Component, OnInit, TemplateRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {ApplicationService} from '../../../services/application/application.service';
import {ViewApplicationComponent} from '../bursary/view-opportunity/apply/view-application/view-application.component';
@Component({
  selector: 'app-scrumboard',
  templateUrl: './scrumboard.component.html',
  styleUrls: ['./scrumboard.component.scss']
})
export class StudentScrumboardComponent implements OnInit {
  status = false;
  modalRef: BsModalRef;

  studentApplications: any[] = [];
  studentPendingApplications: any[] = [];
  studentAwardedApplications: any[] = [];
  studentRejectedApplications: any[] = [];

  constructor(
    private modalService: BsModalService,
    private applicationService: ApplicationService,
  ) {
    const userType = window.localStorage.getItem('user_type');
  }
  todo = [];

  inprogress = [
    '1) Bursary Application : #98621 SASOL Engineer undergraduate programme. (T. Makepe - tsekomakepe@gmail.com)',
    '1) Bursary Application : #98621 SASOL Engineer undergraduate programme. (T. Makepe - tsekomakepe@gmail.com)',
  ];

  done = [];

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }
  ngOnInit(): void {
    this.getStudentApplications();
  }
  ListViewEvent() {
    this.status = !this.status;
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  getStudentApplications() {
    // All Applications
    this.applicationService.getStudentApplications().subscribe((data) => {
      this.studentApplications = data;
      console.log(data);
    });
    // In progress Applications
    this.applicationService.getStudentApplicationsByStatus('pending').subscribe((data) => {
      this.studentPendingApplications = data;
      console.log('Printing Pending Applications', data);
    });
    // Awarded Applications
    this.applicationService.getStudentApplicationsByStatus('awarded').subscribe((data) => {
      this.studentAwardedApplications = data;
      console.log('Printing Awarded Applications', data);
    });
    // Rejected Applications
    this.applicationService.getStudentApplicationsByStatus('rejected').subscribe((data) => {
      this.studentRejectedApplications = data;
      console.log('Printing Rejected Applications', this.studentApplications);
    });
  }

  viewApplication(application: any) {
    application.modal_number = 1;
    this.modalRef = this.modalService.show(ViewApplicationComponent, {
      class: 'modal-dialog-centered modal-xl',
      initialState: {
        application
      }
    });
  }
}
