import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StudentService {
  baseUrl: any = '';

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.baseUrl = environment.baseUrl;
  }

  public getAllStudents(): Observable<any[]> {
    console.log('Getting All Students');
    // tslint:disable-next-line:max-line-length
    return this.http.get<any[]>(`${this.baseUrl}profile/all`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  public getFilteredStudents(filterString = '/profile/all'): Observable<any[]> {
    console.log('Getting All Students');
    // tslint:disable-next-line:max-line-length
    return this.http.get<any[]>(`${this.baseUrl}student${filterString}`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  public getPaginatedStudents(url): Observable<any[]> {
    console.log('Getting All Students');
    // tslint:disable-next-line:max-line-length
    return this.http.get<any[]>(url)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  private handleError(error: any) {
    console.error('An error occurred:', error);
    // Handle the error and possibly throw a custom exception
    throw error;
  }
}
