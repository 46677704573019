import {ApiService} from '../../../services/api.service';

declare let $: any;
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {StudentService} from '../../../services/student/student.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ViewStudentComponent} from '../contacts/view-student/view-student.component';
import {LookupService} from '../../../services/lookup/lookup.service';
import { DatePipe } from '@angular/common';
// import { Workbook } from 'exceljs';
import { Workbook } from 'exceljs/dist/exceljs.min.js';
import * as fs from 'file-saver';

@Component({
  selector: 'app-campaign',
  templateUrl: './full-report.component.html',
  styleUrls: ['./full-report.component.scss']
})
export class FullReportComponent implements OnInit {
  isFull: boolean;
  isFull1: any;
  allStudents: any = [];
  modalRef: BsModalRef;
  filterBooleanArray: any = [];
  printing: any = true;
  currentDate: any;
  currentTime: any;
  select2a: any;
  test: any = '?';

  @ViewChild('divToPrint', {static: false}) divToPrint: ElementRef;

  searchFilter: any = {
    genders: ['*', 'Male', 'Female', 'Other'],
    // tslint:disable-next-line:max-line-length
    provinces: [],
    cities: [],
    institutions: [],
    institutionTypes: ['*', 'TVET College', 'University of Technology', 'University'],
    yearsOfStudy: ['*', '1st', '2nd', '3rd'],
    geographics: ['*', 'RURAL', 'TOWNSHIP', 'URBAN'],
    faculties: ['*'],
    bursaryYears: ['*', '2024', '2023', '2022', '2021', '2020'],
    courses: ['*'],
    statuses: ['*', 'Declined', 'Approved']
  };

  showFilter: any = false;
  apiSearchString: any = '/status/approved';
  kpi: any = {allStudents: 0, approvedStudents: 0, declinedStudents: 0};

  constructor(
      private studentService: StudentService,
      private modalService: BsModalService,
      public lookupService: LookupService,
      public datepipe: DatePipe,
      private apiService: ApiService
  ) {
    // tslint:disable-next-line:new-parens
    this.currentDate = this.datepipe.transform((new Date), 'yyyy/MM/dd');
    // tslint:disable-next-line:new-parens
    this.currentTime = this.datepipe.transform((new Date), 'h:mm:ss');
  }

  ngOnInit(): void {
    this.select2a = $('.js-example-basic-single').select2();
    this.test = this.select2a.text();
    this.getFilteredStudents();
    this.lookupProvinces();
    this.lookupCities();
    this.lookupInstitutions();
    this.lookupCourses();
    this.lookupModules();
  }

  dashboardMenu() {
    document.getElementById('navbarNavDropdown').classList.toggle('show');
  }

  // tslint:disable-next-line:variable-name
  fullScreenSection(number) {
    if (number === 1) {
      if (this.isFull) {
        this.isFull = false;
      } else {
        this.isFull = true;
      }
    } else if (number === 2) {
      if (this.isFull1) {
        this.isFull1 = false;
      } else {
        this.isFull1 = true;
      }
    }
  }

  getStudents() {
    this.studentService.getAllStudents().subscribe((data) => {
      this.allStudents = data;
      console.log('Getting All Students', data);
    });
  }

  // tslint:disable-next-line:variable-name
  viewProfile(student_profile: any) {
    student_profile.modal_number = 1;
    this.modalRef = this.modalService.show(ViewStudentComponent, {
      class: 'modal-dialog-centered modal-xl',
      initialState: {
        student_profile
      },
      backdrop: 'static',
    });
  }

  // tslint:disable-next-line:variable-name
  viewSearchProfile(event: any) {
    const uuid = event.target.value;
    console.log(uuid);
    this.studentService.getFilteredStudents('uuid/' + uuid ).subscribe((data) => {
      this.allStudents = data;
      this.kpi.allStudents = data;
      console.log('Getting All Filtered Students : ', data?.length);
    });

    // tslint:disable-next-line:variable-name
    const student_profile = event.target.value;
    student_profile.modal_number = 1;
    this.modalRef = this.modalService.show(ViewStudentComponent, {
      class: 'modal-dialog-centered modal-xl',
      initialState: {
        student_profile
      },
      backdrop: 'static',
    });
  }
  // lookups

  lookupProvinces() {
    this.searchFilter.provinces = this.lookupService.getProvinces().subscribe((data) => {
      this.searchFilter.provinces = data;
      console.log('Getting All Filtered Provinces : ', data.length);
    });
  }
  lookupCities() {
    this.searchFilter.provinces = this.lookupService.getCities().subscribe((data) => {
      this.searchFilter.cities = data;
      console.log('Getting All Filtered Cities : ', data.length);
    });
  }
  lookupInstitutions() {
    this.searchFilter.provinces = this.lookupService.getInstitutions().subscribe((data) => {
      this.searchFilter.institutions = data;
      console.log('Getting All Filtered Institutions : ', data.length);
    });
  }
  lookupCourses() {
    this.searchFilter.provinces = this.lookupService.getCourse().subscribe((data) => {
      this.searchFilter.courses = data;
      console.log('Getting All Filtered Courses : ', data.length);
    });
  }
  lookupModules() {
    this.searchFilter.provinces = this.lookupService.getModules().subscribe((data) => {
      this.searchFilter.faculties = data;
      console.log('Getting All Filtered Modules : ', data.length);
    });
  }
  setFilter(event: any, index: any = false, reset: boolean = false) {
    const searchString = event.target.value;
    if (searchString.indexOf('*') !== -1) {

      // delete this.filterBooleanArray[index];
      console.log(this.filterBooleanArray);
      console.log('Deleting array item : ' + index + ' \n');
      this.filterBooleanArray.splice(index, 1);
      this.filterBooleanArray[index] = {filter: ''};
      console.log(this.filterBooleanArray);

      this.apiSearchString = '';
      this.filterBooleanArray.forEach(element => {
        console.log(element);
        this.setSearchString(element.filter);
      });

      this.getFilteredStudents();
      return;
    }

    this.filterBooleanArray[index] = {filter: searchString};
    this.filterBooleanArray.forEach(element => {
      this.setSearchString(searchString);
    });

    this.getFilteredStudents();
  }

  setSearchString(searchString: any) {
    console.log('Setting Search String');
    if (this.apiSearchString.includes(searchString)) {
      return;
    }

    // 1. get existing search string of key
    console.log(this.apiSearchString.substring(this.apiSearchString.indexOf('/') , searchString.indexOf('/')));

    // 3. join key/ with new value
    this.apiSearchString.slice(0, searchString.lastIndexOf('/'));
    this.apiSearchString += searchString;
    console.log(this.apiSearchString);
  }

  //
  getFilteredStudents() {
    const str = this.apiSearchString;
    // tslint:disable-next-line:no-unused-expression
    // (str.charAt(str.length - 1) === '/') ? str.substr(0, str.length - 1) : str;
    this.studentService.getFilteredStudents(str).subscribe((data) => {
      this.allStudents = data;
      this.kpi.allStudents = data;
      console.log('Getting All Filtered Students : ', data?.length);
      console.log(data);
    });
  }

  getPaginatedData(url) {
    this.studentService.getPaginatedStudents(url).subscribe((data) => {
      const count: any = data;
      console.log('Getting All PAGINATED Students : ', count?.profiles?.data?.length);
      this.allStudents = data;
    });
  }

  print() {
    this.printing = true;
    const printContents = this.divToPrint.nativeElement.innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    setTimeout(() => {
      this.printing = false;
    }, 3000);
  }

  // Excel Workbook
   async downloadExcel(extension = '.xlsx') {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('My Sheet');
    console.log(worksheet);
    worksheet.columns = [
      { key: 'id', width: 20, header: 'ID' },
      { key: 'names', width: 20, header: 'NAMES' },
      { key: 'email', width: 20, header: 'EMAIL' },
      { key: 'contact', width: 20, header: 'CONTACT' },
      { key: 'gender', width: 20, header: 'GENDER' },
      { key: 'province', width: 20, header: 'PROVINCE' },
      { key: 'address', width: 20, header: 'ADDRESS' },
      { key: 'status', width: 20, header: 'STATUS' },
    ];

    let x: any;
    this.allStudents?.profiles?.data.forEach( (student) => {
       worksheet.addRow({
         id: student?.id_number ,
         names: student?.first_name,
         email: student?.email,
         contact: student?.contact_number,
         gender: student?.gender,
         province: student?.province,
         address: student?.address,
         status: student?.status,
       });
     });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, 'Students Full Report ' + this.currentDate + ' - ' + this.currentTime + extension);
  }

    openSmartFilter(template) {
        this.modalRef = this.modalService.show(template, {
            class: 'modal-dialog-centered modal-xl',
            backdrop: 'static',
        });
    }

  // tslint:disable-next-line:variable-name
    smartFiltering(filter_string: string) {
      this.apiService.get_public(`${filter_string}`).then((data) => {
        console.log(data);
      });
    }
}
