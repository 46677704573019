export class Opportunity {

  public uuid = '';
  // tslint:disable-next-line:variable-name
  public partner_uuid = '';
  public type = '';
  // tslint:disable-next-line:variable-name
  public title = '';
  // tslint:disable-next-line:variable-name
  public advertisement_title = '';
  public description = '';
  public industry = '';
  // tslint:disable-next-line:variable-name
  public image_banner = '';
  // tslint:disable-next-line:variable-name
  public criteria_json = '';
  // tslint:disable-next-line:variable-name
  public document_checklist_json = '';
  // tslint:disable-next-line:variable-name
  public publish_date = '';
  // tslint:disable-next-line:variable-name
  public closing_date = '';
  // tslint:disable-next-line:variable-name
  public highest_qualification_required = '';
  // tslint:disable-next-line:variable-name
  public years_experience_required = '';
  // tslint:disable-next-line:variable-name
  public status = '';
  // tslint:disable-next-line:variable-name
  public other_requirements = '';
  // tslint:disable-next-line:variable-name
  public gender_preference = '';
  // tslint:disable-next-line:variable-name
  public ethnicity_preference = '';
  // tslint:disable-next-line:variable-name
  public enquiries_contact_number = '';
  // tslint:disable-next-line:variable-name
  public enquiries_contact_person = '';
  // tslint:disable-next-line:variable-name
  public enquiries_department = '';
  // tslint:disable-next-line:variable-name
  public created_at = '';
  // tslint:disable-next-line:variable-name
  public updated_at = '';
  // tslint:disable-next-line:variable-name
  public application = '';
  public partner = '';


  // tslint:disable-next-line:variable-name
  constructor(data, user_uuid = '' ) {
    if (data !== null) {
      this.uuid = data?.uuid?.toString();
      this.partner_uuid = data?.partner_uuid;
      this.type = data?.type;
      this.title = data?.title;
      this.advertisement_title = data?.advertisement_title;
      this.description = data?.description;
      this.industry = data?.industry;
      this.image_banner = data?.image_banner;
      this.criteria_json = data?.criteria_json;
      this.document_checklist_json = data?.document_checklist_json;
      this.publish_date = data?.publish_date;
      this.closing_date = data?.closing_date;
      this.highest_qualification_required = data?.highest_qualification_required;
      this.years_experience_required = data?.years_experience_required;
      this.status = data?.status;
      this.other_requirements = data?.other_requirements;
      this.gender_preference = data?.gender_preference;
      this.ethnicity_preference = data?.ethnicity_preference;
      this.enquiries_contact_number = data?.enquiries_contact_number;
      this.enquiries_contact_person = data?.enquiries_contact_person;
      this.enquiries_department = data?.enquiries_department;
      this.created_at = data?.created_at;
      this.updated_at = data?.updated_at;
      this.application = data?.application;
      this.partner = data?.partner;
    }
  }
}
