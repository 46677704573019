import { Component, OnInit } from '@angular/core';
import {ApiService} from '../services/api.service';

@Component({
  selector: 'app-discontinued-doc400',
  templateUrl: './discontinued-doc400.component.html',
  styleUrls: ['./discontinued-doc400.component.scss']
})
export class DiscontinuedDoc400Component implements OnInit {
  students: any;
  adminType = localStorage.getItem('admin_type');
  tableHeader = 'Discontinued';
  constructor(
      public apiService: ApiService,
  ) {}

  ngOnInit(): void {
    this.loadStudents();
  }

  loadStudents() {
    this.apiService.get_public(`student/status/Discontinued/partner/Fasset`).then((response) => {
      console.log(response);
      this.students = response;
    }, (error) => {
      console.log(error);
    });
  }
}
