import {AfterViewInit, Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../../../services/notification.service';
import {Profile} from '../../../../models/profile.model';
import Swal from 'sweetalert2';
import {FileUploadService} from '../../../../services/upload/file-upload.service';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Attachment} from '../../../../models/attachment.model';
import {AuthService} from '../../../../services/auth.service';
import {InstitutionModel} from '../../../../models/institution.model';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {InstitutionComponent} from './institution/institution.component';
import {SchoolDetailsComponent} from './school-details/school-details.component';
import {ProfileComponent} from '../../../client/pages/profile/profile.component';
import SignaturePad from 'signature_pad';
import {LookupService} from '../../../../services/lookup/lookup.service';
import {AddressModel} from '../../../../models/address.model';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class StudentProfileComponent implements OnInit, AfterViewInit {
  @ViewChild('sPad', {static: true}) signaturePadElement;

  // global members
  isFull1: any;
  isFull: boolean;
  buttonText: any;

  // data members
  user: any;
  profileModel: Profile;
  addressModel: AddressModel;
  institutionModel: InstitutionModel;
  attachmentModel: Attachment;
  profilePercentage: any;
  showPercentage: any;
  showUploadProfile: any;
  showInstitutionSection: any;
  showBasicInformationSection: any;
  showLoginInformationSection: any;
  apiBaseUrl: any;
  attachmentFiles: any;
  institutionResults: any;
  schoolDetails: any;

  // file upload members
  selectedFiles?: FileList;
  selectedDisabilityEvidenceFiles?: any[];
  progressInfos: any[] = [];
  message: string[] = [];
  previews: string[] = [];
  modalRef: BsModalRef;
  showDisabilityEvidence = false;
  signaturePad: any;
  showSignaturePad: any = true;
  apiPublic: any = environment.apiPublic;
  randomIndex: any = Math.random();
  searchFilter: any = {
    genders: ['*', 'Male', 'Female', 'Other'],
    // tslint:disable-next-line:max-line-length
    provinces: [],
  };
  profileTab1: any = true;
  profileTab2: any = false;
  profileTab3: any = false;
  profileTab4: any = false;
  profileTab5: any = false;
  addressDetailsTab: any = false;

  constructor(
    public apiService: ApiService,
    public router: Router,
    private notify: NotificationService,
    private uploadService: FileUploadService,
    private modalService: BsModalService,
    public authService: AuthService,
    private renderer: Renderer2,
    private lookupService: LookupService
  ) {
    this.profileModel = new Profile(null, this.user?.uuid, null, this.user);
    this.profilePercentage = 5;
    this.showPercentage = true;
    this.showUploadProfile = false;
    this.showInstitutionSection = false;
    this.showBasicInformationSection = true;
    this.showLoginInformationSection = true;
    this.apiBaseUrl = environment.apiPublic;
  }

  ngOnInit(): void {
    this.loadUser();
    this.lookupProvinces();
  }

  lookupProvinces() {
    this.searchFilter.provinces = this.lookupService.getProvinces().subscribe((data) => {
      this.searchFilter.provinces = data;
      console.log('Getting All Filtered Provinces : ', data.length);
    });
  }

  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement);
  }

  // auth check | fetch user
  loadUser(): void {
    this.authService.fetchUser().then((user) => {
      this.user = this.authService.getUser();
      this.profileModel = this.authService.getProfileModel();
      this.institutionModel = new InstitutionModel(null, this.profileModel.uuid);
      this.institutionResults = this.authService.getInstitutionResults();
      this.schoolDetails = this.authService.getSchoolDetails();
      this.attachmentModel = new Attachment(null, this.profileModel.uuid);
      this.attachmentFiles = this.authService.getAttachments();
      this.profilePercentage = this.authService.getProfilePercentage();
      this.addressModel = new AddressModel(this.profileModel.address);
    });
  }

  // tslint:disable-next-line:variable-name
  fullScreenSection(number) {
    if (number === 1) {
      if (this.isFull) {
        this.isFull = false;
      } else {
        this.isFull = true;
      }
    } else if (number === 2) {
      if (this.isFull1) {
        this.isFull1 = false;
      } else {
        this.isFull1 = true;
      }
    }
  }

  saveProfile() {
    this.apiService.post_private('profile/store', this.profileModel).then((response) => {
      Swal.fire('Profile Created!', response?.message || 'Nice! Your profile has been safely saved!', 'success');
      this.loadUser();
    }, (error) => {
      console.log(error);
      this.notify.confirmDialog(
        'Some Error Occured. Do You Want to reload your profile and try again?', this.loadUser(),
        // 'Some Error Occured. Do You Want to reload your profile and try again?',
        'Create Student Profile',
        'error'
      );
    });
  }

  updateProfile(type) {
    if (this.profileModel.email !== this.user.email && type === 'email') {
      Swal.fire({
        title: 'Email Update Confirmation',
        text: 'If you update your email, you will be required to verify the new email and log in again. Are you sure you want to proceed?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, update email & logout',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.isConfirmed) {
          this.performUpdate(type);
          this.router.navigate(['/login']);
        } else {
          Swal.fire('Email Update Canceled', 'Your email has not been updated.', 'info');
        }
      });
    } else {
      this.performUpdate(type);
    }
  }

  performUpdate(type) {
    // tslint:disable-next-line:variable-name
    let profile_uuid = '';
    if (this.profileModel.uuid) {
      profile_uuid = this.profileModel.uuid;
    } else {
      profile_uuid = 'email';
    }
    // @ts-ignore
    this.profileModel.type = type;
    this.apiService.post_private('profile/update/' + profile_uuid, this.profileModel).then((response) => {
      if (response.status === 'success') {
        Swal.fire('Profile Updated!', response?.message || 'Nice! Your profile has been safely updated!', 'success');
        this.loadUser();
      } else {
        Swal.fire('Profile Failed To Update!', response?.message, 'error');
      }
    }, (error) => {
      console.log(error);
      this.notify.confirmDialog('Some Error Occurred. Do You Want to reload your profile and try again?', this.loadUser(),
        'Create Student Profile', 'error',
      );
    });
  }

  togglePercentage() {
    if (this.showPercentage === true) {
      this.showPercentage = false;
    } else {
      this.showPercentage = true;
    }
  }

  toggleShowUploadProfile() {
    if (this.showUploadProfile === true) {
      this.showUploadProfile = false;
    } else {
      this.showUploadProfile = true;
    }
  }

  // file upload
  selectFiles(event: any, preview: string): void {
    this.message = [];
    this.progressInfos = [];
    this.selectedFiles = event.target.files;
    this.previews = [];
    if (preview === 'preview') {
      if (this.selectedFiles && this.selectedFiles[0]) {
        const numberOfFiles = this.selectedFiles.length;
        for (let i = 0; i < numberOfFiles; i++) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.previews.push(e.target.result);
          };
          reader.readAsDataURL(this.selectedFiles[i]);
        }
      }
    }
  }

  upload(idx: number, file: File): void {
    this.progressInfos[idx] = {value: 0, fileName: file.name};
    if (file) {
      this.uploadService.uploadRelated(file, 'profile/upload/image', this.profileModel.uuid).subscribe(
        (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfos[idx].value = Math.round(
              (100 * event.loaded) / event.total
            );
          } else if (event instanceof HttpResponse) {
            Swal.fire('File Upload!', 'Successful Upload!', 'success');
            window.location.reload();
            // this.message.push(msg);
            // this.imageInfos = this.uploadService.getFiles();
          }
        }, (err: any) => {
          this.progressInfos[idx].value = 0;
          const msg = 'Could not upload the file: ' + file.name;
          this.message.push(msg);
        }
      );
    }
  }

  uploadFiles(): void {
    this.message = [];
    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.upload(i, this.selectedFiles[i]);
      }
    }
  }

  addInstitution(institution: any) {
    institution.modal_number = 1;
    institution.view_type = 'edit';
    this.modalRef = this.modalService.show(InstitutionComponent, {
      class: 'modal-dialog-centered modal-xl',
      initialState: {
        institution
      },
      backdrop: 'static',
    });
  }

  // tslint:disable-next-line:variable-name
  addSchool(school_details: any) {
    school_details.modal_number = 1;
    school_details.view_type = 'edit';
    this.modalRef = this.modalService.show(SchoolDetailsComponent, {
      class: 'modal-dialog-centered modal-xl',
      initialState: {
        school_details
      },
      backdrop: 'static',
    });
  }

  // tslint:disable-next-line:variable-name
  addSignature(signature: any) {
    signature.modal_number = 1;
    signature.view_type = 'edit';
    this.modalRef = this.modalService.show(SchoolDetailsComponent, {
      class: 'modal-dialog-centered modal-xl',
      initialState: {
        signature
      },
      backdrop: 'static',
    });
  }

  viewInstitutionDetails(institution: any) {
    institution.modal_number = 1;
    this.renderer.removeClass(document.body, 'enable-scroll');
    this.modalRef = this.modalService.show(InstitutionComponent, {
      class: 'modal-dialog-centered modal-xl',
      initialState: {
        institution
      },
      backdrop: 'static',
    });
  }
  // tslint:disable-next-line:variable-name
  viewSchoolDetails(event: Event, school_details: any): void {
    event.preventDefault();
    school_details.modal_number = 1;
    this.renderer.removeClass(document.body, 'enable-scroll');
    this.modalRef = this.modalService.show(SchoolDetailsComponent, {
      class: 'modal-dialog-centered modal-xl',
      initialState: {
        school_details
      },
      backdrop: 'static',
    });
  }
  toggleDisabilityEvidence() {
    if (this.profileModel.disability === 'None') {
      this.showDisabilityEvidence = false;
    } else {
      this.showDisabilityEvidence = true;
    }
  }
  deleteSchoolDetails(uuid) {
    this.notify.confirmDialog('Do you want to delete this record?', () => {
      this.destroySchoolDetails(uuid);
    });
  }
  deleteInstitutionDetails(uuid) {
    this.notify.confirmDialog('Do you want to delete this record?', () => {
      this.destroyInstitutionDetails(uuid);
    });
  }

  destroySchoolDetails(uuid) {
    this.apiService.get_private('school_details/delete/' + uuid).then(data => {
      this.schoolDetails = undefined;
      Swal.fire('Record Deleted Successfully!', 'Deleting A Record', 'success');
      this.loadUser();
    });
  }
  destroyInstitutionDetails(uuid) {
    this.apiService.get_private('institution_results/delete/' + uuid).then(data => {
      this.institutionResults = data.institution_results;
      Swal.fire('Record Deleted Successfully!', 'Deleting A Record', 'success');
      this.loadUser();
    });
  }

  // Digital Signature
  changeColor() {
    const r = Math.round(Math.random() * 255);
    const g = Math.round(Math.random() * 255);
    const b = Math.round(Math.random() * 255);
    const color = 'rgb(' + r + ',' + g + ',' + b + ')';
    this.signaturePad.penColor = color;
  }

  clear() {
    this.signaturePad.clear();
  }

  undo() {
    const data = this.signaturePad.toData();
    if (data) {
      data.pop(); // remove the last dot or line
      this.signaturePad.fromData(data);
    }
  }

  download(dataURL, filename) {
    if (navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') === -1) {
      window.open(dataURL);
    } else {
      const blob = this.dataURLToBlob(dataURL);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
    }
  }

  saveDigitalSignature() {
    Swal.fire({
      title: 'Digital Signature ',
      text: 'If you update your email, you will be required to verify the new email and log in again. Are you sure you want to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, update email & logout',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/login']);
      } else {
        Swal.fire('Digital Signature Cancelled', 'Record not been updated.', 'info');
      }
    });
  }

  uploadSignature() {
    const dataURL = this.signaturePad.toDataURL();
    this.download(dataURL, 'signature.png');
    const file = this.dataURLToBlob(dataURL);

    if (file) {
        this.uploadService.uploadSignature(file, 'profile/upload/signature', this.profileModel.uuid).subscribe(
          (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
            } else if (event instanceof HttpResponse) {
              Swal.fire('Signature Upload!', 'Signature Upload!', 'success');
              this.ngOnInit();
              this.ngAfterViewInit();
            }
          }, (err: any) => {
            // this.progressInfos[idx].value = 0;
            const msg = 'Could not upload the file';
            this.message.push(msg);
          }
        );
    }
  }

  dataURLToBlob(dataURL) {
    // Code taken from https://github.com/ebidel/filer.js
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
  }

  toggleSignature() {
    this.showSignaturePad = true;
    this.ngAfterViewInit();
  }

  savePNG() {
    if (this.signaturePad.isEmpty()) {
      alert('Please provide a signature first.');
    } else {
      const dataURL = this.signaturePad.toDataURL();
      this.download(dataURL, 'signature.png');
    }
  }

  saveJPG() {
    if (this.signaturePad.isEmpty()) {
      alert('Please provide a signature first.');
    } else {
      const dataURL = this.signaturePad.toDataURL('image/jpeg');
      this.download(dataURL, 'signature.jpg');
    }
  }

  saveSVG() {
    if (this.signaturePad.isEmpty()) {
      alert('Please provide a signature first.');
    } else {
      const dataURL = this.signaturePad.toDataURL('image/svg+xml');
      this.download(dataURL, 'signature.svg');
    }
  }

  setProvince(event: any) {
    this.profileModel.province = event.target.value;
  }

  // tslint:disable-next-line:variable-name
  onTabChange(number) {
    this.profileTab1 = false;
    this.profileTab2 = false;
    this.profileTab3 = false;
    this.profileTab4 = false;
    this.profileTab5 = false;
    this.addressDetailsTab = false;

    if (number === '1') {
      this.profileTab1 = true;
    } else if (number === '2') {
      this.profileTab2 = true;
    } else if (number === '3') {
      this.profileTab3 = true;
    } else if (number === '4') {
      this.profileTab4 = true;
    } else if (number === '5') {
      this.profileTab5 = true;
    } else if (number === '6') {
      this.addressDetailsTab = true;
    }
  }

  saveAddress() {
    console.log(this.profileModel);
  }
}
