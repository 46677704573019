import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {NotificationService} from './notification.service';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl: any = '';
  helpdeskBaseUrl: any = '';
  mediaURL: any = '';

  constructor(
    private http: HttpClient,
    private notify: NotificationService,
    private router: Router
  ) {
    this.baseUrl = environment.baseUrl;
    this.helpdeskBaseUrl = environment.helpdeskBaseUrl;
    this.mediaURL = environment.imageUrl;
  }

  uploadFile(files: File[]) {
    const formData = new FormData();
    Array.from(files).forEach(f => formData.append('image', f));
    return this.http.post(this.baseUrl + 'v1/' + 'uploadImage', formData);
  }

  JSON_to_URLEncoded(element: any, key?: any, list?: any) {
    // tslint:disable-next-line:variable-name
    const new_list = list || [];
    if (typeof element === 'object') {
      // tslint:disable-next-line:forin
      for (const idx in element) {
        this.JSON_to_URLEncoded(
          element[idx],
          key ? key + '[' + idx + ']' : idx,
          new_list
        );
      }
    } else {
      new_list.push(key + '=' + encodeURIComponent(element));
    }
    return new_list.join('&');
  }

  public login(url: string, body: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const header = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      };
      const param = this.JSON_to_URLEncoded(body);
      console.log(param);
      return this.http.post(this.baseUrl + url, param, header).subscribe((data) => {
        // @ts-ignore
        if (body?.type === 'STUDENT' && data?.user?.type === 'ADMIN') {
          this.notify.errorBasicNotification('Login Error', 'Navigate to Admin Login Screen');
          // @ts-ignore
        } else if (body?.type === 'ADMIN' && data?.user?.type === 'STUDENT') {
          this.notify.errorBasicNotification('Login Error', 'Navigate to Student Login Screen');
          // @ts-ignore
        // } else if (data?.status === 'warning') {
        //   Swal.fire({
        //     title: '<span style="font-size: 60px;">&#x1F6A7;</span>',
        //     // @ts-ignore
        //     html: `${data?.message}`,
        //     icon: null,
        //     showCloseButton: true,
        //     showCancelButton: false,
        //     showConfirmButton: false,
        //     customClass: {
        //       title: 'swal-title'
        //     }
        //   });
        } else {
          this.notify.successBasicNotification('Login Successful', 'Success!');
        }
        resolve(data);
      }, error => {
        console.log(error);
        this.notify.errorBasicNotification(error.error.message, error.statusText);
        reject(error);
      });
    });
  }

  public register(url: string, body: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const header = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      };
      const param = this.JSON_to_URLEncoded(body);
      console.log(param);
      return this.http.post(this.baseUrl + url, param, header).subscribe((data) => {
        resolve(data);
      }, error => {
        this.notify.errorBasicNotification(error.error.message, error.statusText);
        reject(error);
      });
    });
  }

  public forgotPassword(url: string, body: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const header = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      };
      const param = this.JSON_to_URLEncoded(body);
      return this.http.post(this.baseUrl + url, param, header).subscribe((data) => {
        resolve(data);
      }, error => {
        this.notify.errorBasicNotification(error.error.message, error.statusText);
        reject(error);
      });
    });
  }
  public helpdeskRegister(url: string, body: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const header = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      };
      const param = this.JSON_to_URLEncoded(body);
      console.log(param);
      return this.http.post(this.helpdeskBaseUrl + url, param, header).subscribe((data) => {
        resolve(data);
      }, error => {
        this.notify.errorBasicNotification(error.error.message, error.statusText);
        reject(error);
      });
    });
  }

  public post_public(url: string, body: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const header = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      };
      const param = this.JSON_to_URLEncoded(body);
      console.log(param);
      this.http.post(this.baseUrl + url, param, header).subscribe((data) => {
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public post_private(url: string, body: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const header = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set('Authorization', `Bearer ${localStorage.getItem('token')}`)
      };
      const param = this.JSON_to_URLEncoded(body);
      console.log(param);
      this.http.post(this.baseUrl + url, param, header).subscribe((data) => {
        resolve(data);
      }, error => {
        if (error.error && error.error.message && error.error.message.includes('SQLSTATE[HY000]')) {
          console.log('Error : ' + error.error.message);
          this.notify.confirmDialog('Do you want to refresh the page?', window.location.reload());
        } else {
          if (error.error && error.statusText) {
            this.notify.errorBasicNotification(error.error.message, error.statusText);
          } else {
            this.notify.errorBasicNotification('An error occurred.', 'Error');
          }
        }
        reject(error);
      });
    });
  }

  public post_temp(url, body, temp): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const header = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set('Authorization', `Bearer ${temp}`)
      };
      const param = this.JSON_to_URLEncoded(body);
      console.log(param);
      this.http.post(this.baseUrl + url, param, header).subscribe((data) => {
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public get_public(url: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const header = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      };
      this.http.get(this.baseUrl + url, header).subscribe((data) => {
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public get_private(url: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const header = {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set('Authorization', `Bearer ${localStorage.getItem('token')}`)
      };
      this.http.get(this.baseUrl + url, header).subscribe((data) => {
        resolve(data);
      }, error => {
        if (error.error.message === 'Unauthenticated.') {
          return this.router.navigate(['/login']);
        }
        // this.notify.errorBasicNotification(error.error.message, error.statusText);
        // console.log(error.error.message);
        // reject(error);
      });
    });
  }

  public externalGet(url: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const header = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      };
      this.http.get(url, header).subscribe((data) => {
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  httpGet(url: any, key: any) {
    const header = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', `Bearer ${key}`)
    };

    return this.http.get(url, header);
  }

  externalPost(url: any, body: any, key: any) {
    const header = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', `Bearer ${key}`)
    };
    const order = this.JSON_to_URLEncoded(body);
    console.log(order);
    return this.http.post(url, order, header);
  }

  public helpdesk_post_private(url: string, body: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const header = {
        headers: new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', `Bearer ${localStorage.getItem('token')}`)
      };
      const param = this.JSON_to_URLEncoded(body);
      console.log(param);
      this.http.post(this.helpdeskBaseUrl + url, param, header).subscribe((data) => {
        resolve(data);
      }, error => {
        console.log(error);
        if (error.error && error.error.message && error.error.message.includes('SQLSTATE[HY000]')) {
          console.log('Error : ' + error.error.message);
          this.notify.confirmDialog('Do you want to refresh the page?', window.location.reload());
        } else {
          if (error.error && error.statusText) {
            this.notify.errorBasicNotification(error.error.message, error.statusText);
          } else {
            this.notify.errorBasicNotification('An error occurred.', 'Error');
          }
        }
        reject(error);
      });
    });
  }
}
