export class SchoolDetailsModel {

  public uuid = '';
  // tslint:disable-next-line:variable-name
  public profile_uuid = '';
  public province = '';
  // tslint:disable-next-line:variable-name
  public school_name = '';
  // tslint:disable-next-line:variable-name
  public year_from = '';
  // tslint:disable-next-line:variable-name
  public year_to = '';


  // tslint:disable-next-line:variable-name
  constructor(data, profile_uuid = '' ) {
    this.profile_uuid = profile_uuid;
    if (data !== null) {
      this.uuid = data.uuid?.toString();
      this.province = data.province;
      this.school_name = data.school_name;
      this.year_from = data.year_from;
      this.year_to = data.year_to;
    }
  }
}
