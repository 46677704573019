import { BrowserModule } from '@angular/platform-browser';
import {forwardRef, NgModule} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { OverlayModule } from '@angular/cdk/overlay';
import { OpportunityListComponent } from './modules/student/opportunity-list/opportunity-list.component';
import { CreateTicketComponent } from './modules/helpdesk/create-ticket/create-ticket.component';
// tslint:disable-next-line:max-line-length
import { RequestPaymentComponent } from './modules/student/bursary/view-opportunity/apply/view-application/request-payment/request-payment.component';
import { ClientModule } from './modules/client/client.module';
import { FullReportComponent } from './modules/client/full-report/full-report.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import {CustomPipePipe} from './services/custom-pipe/custom-pipe.pipe';
import { UserManagementComponent } from './user-management/user-management.component';
import {GooglePlacesComponent} from './modules/google/google-places/google-places.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {SharedModule} from './shared/shared.module';
import { RecommendedApplicationsComponent } from './recommended-applications/recommended-applications.component';
import { ApprovedApplicationsComponent } from './approved-applications/approved-applications.component';
import { Document400Component } from './document400/document400.component';
import { RecommendedDoc400Component } from './recommended-doc400/recommended-doc400.component';
import { ApprovedDoc400Component } from './approved-doc400/approved-doc400.component';
import { CancelledDoc400Component } from './cancelled-doc400/cancelled-doc400.component';
import { DiscontinuedDoc400Component } from './discontinued-doc400/discontinued-doc400.component';
import { PrintPdfComponent } from './print-pdf/print-pdf.component';

@NgModule({
  declarations: [
    AppComponent,
    OpportunityListComponent,
    CreateTicketComponent,
    GooglePlacesComponent,
    RequestPaymentComponent,
    FullReportComponent,
    CustomPipePipe,
    UserManagementComponent,
    RecommendedApplicationsComponent,
    ApprovedApplicationsComponent,
    Document400Component,
    RecommendedDoc400Component,
    ApprovedDoc400Component,
    CancelledDoc400Component,
    DiscontinuedDoc400Component,
    PrintPdfComponent,
    // CommentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    NgHttpLoaderModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
    }),
    MatAutocompleteModule,
    OverlayModule,
    ClientModule,
    NgxSummernoteModule,
    SharedModule
  ],
  providers: [BsDatepickerModule, CustomPipePipe, BsModalService],
    exports: [
        CustomPipePipe,
        GooglePlacesComponent,
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
