import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  baseUrl: any = '';
  helpdeskBaseUrl: any = '';

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.baseUrl = environment.baseUrl;
    this.helpdeskBaseUrl = environment.helpdeskBaseUrl;
  }

  public getStudentApplications(): Observable<any[]> {
    console.log('Getting All Applications');
    // tslint:disable-next-line:max-line-length
    return this.http.get<any[]>(`${this.baseUrl}application/application/${localStorage.getItem('profile_uuid')}`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }
  public getStudentTickets(): Observable<any[]> {
    console.log('Getting Student Tickets');
    // tslint:disable-next-line:max-line-length
    return this.http.get<any[]>(`${this.helpdeskBaseUrl}student/tickets/${localStorage.getItem('user_id')}`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }
  public getStudentPendingTickets(): Observable<any[]> {
    console.log('Getting Student Pending Tickets');
    // tslint:disable-next-line:max-line-length
    return this.http.get<any[]>(`${this.helpdeskBaseUrl}student/tickets/${localStorage.getItem('user_id')}/pending`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }
  public getStudentClosedTickets(): Observable<any[]> {
    console.log('Getting Student Closed Tickets');
    // tslint:disable-next-line:max-line-length
    return this.http.get<any[]>(`${this.helpdeskBaseUrl}student/tickets/${localStorage.getItem('user_id')}/closed`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  // tslint:disable-next-line:variable-name
  public getTicketById(ticket_id): Observable<any[]> {
    console.log('Getting Ticket');
    return this.http.get<any[]>(`${this.helpdeskBaseUrl}student/get-ticket-by-id/${ticket_id}`)
        .pipe(
          // catchError(this.handleError) // Handle errors if necessary
        );
  }
  sendMessage(formData: FormData): Observable<any> {
    // Assuming your API endpoint is '/api/chat'
    return this.http.post<any>(`${this.helpdeskBaseUrl}chat`, formData)
        .pipe(
            // catchError(this.handleError) // Handle errors if necessary
        );
  }

  private handleError(error: any) {
    console.error('An error occurred:', error);
    // Handle the error and possibly throw a custom exception
    throw error;
  }
}
