declare let $: any;
import {HttpClient, HttpRequest} from '@angular/common/http';
import {Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input, TemplateRef} from '@angular/core';
import {StudentService} from '../../../services/student/student.service';
import {environment} from '../../../../environments/environment';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ViewStudentComponent} from './view-student/view-student.component';
import Swal from 'sweetalert2';
import {ApiService} from '../../../services/api.service';
import {NotificationService} from '../../../services/notification.service';
import {LookupService} from '../../../services/lookup/lookup.service';
import { CommentComponent } from '../../../shared/components/comment.component';
// @ts-ignore
import { } from '@types/googlemaps';


@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})

export class ContactsComponent implements OnInit {

  AllContactsTab: boolean;
  GoogleContactsTab = true;
  InvitationsContactsTab: boolean;

  // tslint:disable-next-line:variable-name
  all_students: any[] = [];
  showFilter: any = false;
  apiBaseUrl: any;
  modalRef: BsModalRef;
  selectedStudents: any = [];

  // tslint:disable-next-line:max-line-length
  communicationMessage: any = {profile_uuid: '', related_uuid: '', user_uuid: localStorage.getItem('user_uuid'), subject: '', message: '', status: '', receiver: '', type: 1};
  allChecked = false;
  studentCheckList: any = [];
  attachmentFile: any = false;

  // filter
  apiSearchString: any = '/status/approved';
  filterBooleanArray: any = [];
  searchFilter: any = {
    genders: ['*', 'Male', 'Female', 'Other'],
    // tslint:disable-next-line:max-line-length
    provinces: [],
    cities: [],
    institutions: [],
    institutionTypes: ['*', 'TVET College', 'University of Technology', 'University'],
    yearsOfStudy: ['*', '1st', '2nd', '3rd'],
    geographics: ['*', 'RURAL', 'TOWNSHIP', 'URBAN'],
    faculties: [],
    bursaryYears: ['*', '2024', '2023', '2022', '2021', '2020'],
    courses: [],
    statuses: ['*', 'Declined', 'Approved']
  };

  constructor(
    // tslint:disable-next-line:variable-name
    public student_service: StudentService,
    private modalService: BsModalService,
    public apiService: ApiService,
    private notify: NotificationService,
    private http: HttpClient,
    private lookupService: LookupService
  ) {
    this.apiBaseUrl = environment.apiPublic;
  }

  openBulkCommunicationModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-xl',
      backdrop: 'static',
    });
  }

  // tslint:disable-next-line:variable-name
  onTab(number) {
    this.AllContactsTab = false;
    this.GoogleContactsTab = false;
    this.InvitationsContactsTab = false;

    if (number === '1') {
      this.AllContactsTab = true;
    } else if (number === '2') {
      this.GoogleContactsTab = true;
    } else if (number === '3') {
      this.InvitationsContactsTab = true;
    }
  }
  ngOnInit(): void {
    $('.js-example-basic-single').select2();
    this.getFilteredStudents();
    this.lookupProvinces();
    this.lookupCities();
    this.lookupInstitutions();
    this.lookupCourses();
    this.lookupModules();
  }

  selectStudent(uuid, ) {
    // Check if the element exists in the array
    const index =  this.selectedStudents.indexOf(uuid);
    if (index !== -1) {
      this.selectedStudents.splice(index, 1);
    } else if (uuid == null) {
      // this.selectedStudents.push(uuid);
    } else {
      this.selectedStudents.push(uuid);
    }
  }

  // tslint:disable-next-line:variable-name
  viewProfile(student_profile: any) {
    student_profile.modal_number = 1;
    this.modalRef = this.modalService.show(ViewStudentComponent, {
      class: 'modal-dialog-centered modal-xl',
      initialState: {
        student_profile
      },
      backdrop: 'static',
    });
  }

  sendToOne(item) {
    this.communicationMessage.profile_uuid = item;
    this.communicationMessage.related_uuid = item;
    this.apiService.post_private('send-communication', this.communicationMessage).then((response) => {
      if (response.status === 'success') {
        console.log('Nice! Your recepients will receive the communication shortly!');
      } else {
        console.log('Failed To Update!');
      }
    }, (error) => {
      console.log(error);
    });
  }

  sendToMany() {
    Swal.fire({
      title: 'Bulk Communication Confirmation',
      // tslint:disable-next-line:max-line-length
      text: 'If you select to proceed, this communication will be sent to every student selected by ' + this.communicationMessage?.type + '. Are you sure you want to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Send Communication',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed && this.selectedStudents.length > 0 || this.attachmentFile !== false) {

        // if (this.selectedStudents.length > 0) {
        //   this.selectedStudents.forEach(item => {
        //     this.communicationMessage.status = 'SENT';
        //     this.communicationMessage.receiver = '';
        //     // console.log('Saving ... \n');
        //     // console.log(this.communicationMessage);
        //     this.sendToOne(item);
        //   });
        //
        // } else {
        //   if (this.attachmentFile) {
        //     const formData: FormData = new FormData();
        //     formData.append('status', 'SENT');
        //     formData.append('subject', this.communicationMessage.subject);
        //     formData.append('message', this.communicationMessage.message);
        //     formData.append('file', this.attachmentFile);
        //     formData.append('bursary_agreement_signature', this.attachmentFile);
        //
        //     const req = new HttpRequest('POST', `${environment.baseUrl}` + 'send-communication', formData, {
        //       reportProgress: true,
        //       responseType: 'json'
        //     });
        //   }
        // }

        const formData: FormData = new FormData();
        formData.append('status', 'SENT');
        formData.append('subject', this.communicationMessage.subject);
        formData.append('message', this.communicationMessage.message);
        formData.append('type', this.communicationMessage.type);
        formData.append('user_uuid', this.communicationMessage.user_uuid);
        formData.append('file', this.attachmentFile);
        formData.append('bursary_agreement_signature', this.attachmentFile);

        const req = new HttpRequest('POST', `${environment.baseUrl}` + 'send-communication', formData, {
          reportProgress: true,
          responseType: 'json'
        });

        this.http.request(req).subscribe((data) => {
          console.log(data);
        });

        this.resetForm();
      } else {
        Swal.fire('Email Update Canceled', 'Your email has not been updated.', 'info');
      }
    });
  }

  updateAllChecked() {
    this.allChecked = this.studentCheckList != null && this.studentCheckList.every(t => t.selected);
  }
  someSelected(): boolean {
    if (this.studentCheckList == null) {
      return false;
    }

    return this.studentCheckList.filter(t => t.selected).length > 0 && !this.allChecked;
  }

  setAll() {
    if (this.allChecked === true) {
      this.allChecked = false;
    } else {
      this.allChecked = true;
    }
  }

  unselectAll() {
    this.allChecked = true;
    this.allChecked = false;
    this.selectedStudents = [];
  }

  selectCommsType(event: Event) {
    const input = event.target as HTMLInputElement;
    this.communicationMessage.type = input.value;
    console.log(input.value);
  }

  resetForm() {
    Swal.fire(this.communicationMessage.type + 'Sent Successfully', 'Your recipients will receive the communication shortly.', 'success');
    this.communicationMessage = {profile_uuid: '', related_uuid: '', user_uuid: localStorage.getItem('user_uuid'), subject: '',
      message: '', status: '', receiver: '', type: 1};
    setTimeout(function() {this.modalRef.hide(); } , 1000);
  }

  //
  // file upload
  selectFiles(event: any, preview: string = ''): void {
    if (event.target.files) {
      this.attachmentFile = event.target.files[0];
      console.log(this.attachmentFile);
    }
  }

  // filter

  setFilter(event: any, index: any = false, reset: boolean = false) {
    const searchString = event.target.value;
    if (searchString.indexOf('*') !== -1) {

      // delete this.filterBooleanArray[index];
      console.log(this.filterBooleanArray);
      console.log('Deleting array item : ' + index + ' \n');
      this.filterBooleanArray.splice(index, 1);
      this.filterBooleanArray[index] = {filter: ''};
      console.log(this.filterBooleanArray);

      this.apiSearchString = '';
      this.filterBooleanArray.forEach(element => {
        console.log(element);
        this.setSearchString(element.filter);
      });

      this.getFilteredStudents();
      return;
    }

    this.filterBooleanArray[index] = {filter: searchString};
    this.filterBooleanArray.forEach(element => {
      this.setSearchString(searchString);
    });

    this.getFilteredStudents();
  }

  setSearchString(searchString: any) {
    console.log('Setting Search String');
    if (this.apiSearchString.includes(searchString)) {
      return;
    }

    // 1. get existing search string of key
    console.log(this.apiSearchString.substring(this.apiSearchString.indexOf('/') , searchString.indexOf('/')));

    // 3. join key/ with new value
    this.apiSearchString.slice(0, searchString.lastIndexOf('/'));
    this.apiSearchString += searchString;
    console.log(this.apiSearchString);
  }

  //
  getFilteredStudents() {
    const str = this.apiSearchString;
    // (str.charAt(str.length - 1) === '/') ? str.substr(0, str.length - 1) : str;
    this.student_service.getFilteredStudents(str).subscribe((data) => {
      this.all_students = data;
      console.log('Getting All Filtered Students : ', data.length);
    });
  }

  // lookups

  lookupProvinces() {
    this.searchFilter.provinces = this.lookupService.getProvinces().subscribe((data) => {
      this.searchFilter.provinces = data;
      console.log('Getting All Filtered Provinces : ', data.length);
    });
  }
  lookupCities() {
    this.searchFilter.provinces = this.lookupService.getCities().subscribe((data) => {
      this.searchFilter.cities = data;
      console.log('Getting All Filtered Cities : ', data.length);
    });
  }
  lookupInstitutions() {
    this.searchFilter.provinces = this.lookupService.getInstitutions().subscribe((data) => {
      this.searchFilter.institutions = data;
      console.log('Getting All Filtered Institutions : ', data.length);
    });
  }
  lookupCourses() {
    this.searchFilter.provinces = this.lookupService.getCourse().subscribe((data) => {
      this.searchFilter.courses = data;
      console.log('Getting All Filtered Courses : ', data.length);
    });
  }
  lookupModules() {
    this.searchFilter.provinces = this.lookupService.getModules().subscribe((data) => {
      this.searchFilter.faculties = data;
      console.log('Getting All Filtered Modules : ', data.length);
    });
  }
  getPaginatedData(url) {
    this.student_service.getPaginatedStudents(url).subscribe((data) => {
      const count: any = data;
      console.log('Getting All PAGINATED Students : ', count?.profiles?.data?.length);
      this.all_students = data;
    });
  }
}
