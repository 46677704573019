import {Component, Input, OnInit, Renderer2, TemplateRef, ViewChild} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {ApplyComponent} from './apply/apply.component';
import {ViewApplicationComponent} from './apply/view-application/view-application.component';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-view-opportunity',
  templateUrl: './view-opportunity.component.html',
  styleUrls: ['./view-opportunity.component.scss']
})
export class ViewOpportunityComponent implements OnInit {
  @Input() opportunity: any;
  // tslint:disable-next-line:variable-name
  @Input() profile_completion: string;
  @Input() userType: string;
  private modalRef: BsModalRef;
  apiUrl: any = environment.apiPublic;
  // userType = window.localStorage.getItem('user_type');

  constructor(
    private modalService: BsModalService,
    private renderer: Renderer2
  ) {
    const userType = window.localStorage.getItem('user_type');
  }

  ngOnInit(): void {
    // console.log(JSON.stringify(this.opportunity));
  }
  closeModal(): void {
    this.renderer.addClass(document.body, 'enable-scroll');
    this.modalService.hide(this.opportunity.modal_number);
  }
  openApplyModal(opportunity: any) {
    this.modalRef = this.modalService.show(ApplyComponent, {
      class: 'modal-dialog-top modal-xl',
      initialState: {
        opportunity
      },
      backdrop: 'static',
    });
  }

  viewApplication(application: any) {
    application.modal_number = this.opportunity.modal_number + 1;
    application.view_type = 'view only';
    this.modalRef = this.modalService.show(ViewApplicationComponent, {
      class: 'modal-dialog-top modal-xl',
      initialState: {
        application
      },
      backdrop: 'static',
    });
  }

  getRandomNumber(min, max) {
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

    return randomNumber;
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-xl',
      backdrop: 'static',
    });
  }
}
