import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InstitutionService {
  private baseUrl;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

  getUniversities(): Observable<string[]> {
    const universitiesUrl = `${this.baseUrl}institutions/University`;
    return this.http.get<string[]>(universitiesUrl);
  }

  getColleges(): Observable<string[]> {
    const collegesUrl = `${this.baseUrl}institutions/College`;
    return this.http.get<string[]>(collegesUrl);
  }
}
