import { Injectable } from '@angular/core';
import {Profile} from '../models/profile.model';
import {ApiService} from './api.service';
import {NotificationService} from './notification.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // member vars
  public user: any;
  public profileModel: Profile;
  public institutionResults: any;
  public schoolDetails: any;
  public attachmentFiles: any;
  public profilePercentage: any;

  // constructor
  constructor(public apiService: ApiService, public notify: NotificationService, public router: Router) {
    this.profilePercentage = 5;
    this.fetchUser().then((user) =>
      this.checkProfileStatus(user, 'Companies will not recognize you if you don`t complete your profile & upload your documents.')
    );
  }
  // main method : mutator method
  fetchUser() {
    return this.apiService.get_private('user').then( data => {
        this.user = data;
        if (data.profile) {
          this.profileModel = new Profile(data.profile, data.uuid, null, this.user);
          if (this.profileModel.uuid && this.profileModel.uuid !== '') {
            const percentage = (Object.keys(this.profileModel).filter(
                x => this.profileModel[x] !== null).length / Object.keys(this.profileModel).length
            );
            this.profilePercentage = Math.round((percentage * 100));
          }
        } else if (localStorage.getItem('user_uuid') !== null) {
          this.profileModel = new Profile(null, localStorage.getItem('user_uuid'), null, this.user);
        } else {
          this.router.navigate(['/login']);
        }

        if (data?.profile?.institution_results) {
          this.institutionResults = data?.profile?.institution_results;
        }
        if (data?.profile?.school_details) {
          this.schoolDetails = data?.profile?.school_details;
        }
        if (data?.profile?.attachments) {
          this.attachmentFiles = data?.profile?.attachments;
        }

        return this.user;
      // tslint:disable-next-line:only-arrow-functions
    });
  }

  fetchCompany(uuid) {
    return this.apiService.get_private('partner/get_partner_by_uuid/' + uuid).then( data => {
      return data;
    });
  }

  // profile check
  checkProfileStatus(user, message) {
    if (user.profile === null && user.type === 'STUDENT') {
      this.notify.confirmDialog(
          message,
          () => {
            this.router.navigate(['/student/profile']);
          },
          (this.user?.name ?? 'Hi') + ', do you want to go complete your profile now?',
          'warning'
      );
    } else if (user.profile?.attachments === null && user.type === 'STUDENT') {
      this.notify.confirmDialog(
          message,
          () => {
            this.router.navigate(['/student/filemanager']);
          },
          (this.user?.name ?? 'Hi') + ', do you want to go upload your documents now?',
          'warning'
      );
    }
  }

  // accessor methods
  public getUser() {
    return this.user;
  }
  public getProfileModel() {
    return this.profileModel;
  }
  public getInstitutionResults() {
    return this.institutionResults;
  }
  public getSchoolDetails() {
    return this.schoolDetails;
  }
  public getAttachments() {
    return this.attachmentFiles;
  }
  public getProfilePercentage() {
    return this.profilePercentage;
  }
  // mutator methods
  private goToPage(route = '/student/profile') {
    const router = this.router;
    return function redirect() {
      router.navigate([route]);
    };
  }

}
