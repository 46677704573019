export class UserModel {

    public uuid = '';
    // tslint:disable-next-line:variable-name
    public related_uuid = '';
    public name = '';
    public surname = '';
    public type = '';
    // tslint:disable-next-line:variable-name
    public admin_type = '';
    public email = '';
    // tslint:disable-next-line:variable-name
    public contact_number = '';
    public password = '';
    // tslint:disable-next-line:variable-name
    public password_confirmation = '';

    constructor(data, uuid = '' ) {
        if (data !== null) {
            this.uuid = data?.uuid?.toString();
            this.name = data?.name;
            this.surname = data?.surname;
            this.type = data?.type;
            this.admin_type = data?.admin_type;
            this.email = data?.email;
            this.contact_number = data?.contact_number;
        }
    }
}
