import {Component, Input, OnInit, Renderer2, TemplateRef} from '@angular/core';
import {InstitutionModel} from '../../../../../models/institution.model';
import Swal from 'sweetalert2';
import {ApiService} from '../../../../../services/api.service';
import {NotificationService} from '../../../../../services/notification.service';
import {AuthService} from '../../../../../services/auth.service';
import {Profile} from '../../../../../models/profile.model';
import {InstitutionDocumentsResultModel} from '../../../../../models/institution-documents-result.model';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {FileUploadService} from '../../../../../services/upload/file-upload.service';
import {environment} from '../../../../../../environments/environment';
import {DomSanitizer} from '@angular/platform-browser';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { FormControl } from '@angular/forms';
import { startWith, debounceTime, switchMap } from 'rxjs/operators';
import { InstitutionService } from '../../../../../services/insitution/institution.service';
import { QualificationNameService } from '../../../../../services/qualification-name/qualification-name.service';

@Component({
  selector: 'app-institution',
  templateUrl: './institution.component.html',
  styleUrls: ['./institution.component.scss']
})
export class InstitutionComponent implements OnInit {
  @Input() institution: any;
  // tslint:disable-next-line:variable-name
  @Input() add_button = false;
  // tslint:disable-next-line:variable-name
  @Input() view_type = 'edit';
  institutionModel: InstitutionModel;
  user: any;
  profileModel: Profile;
  institutionResults: any;
  InstitutionDocumentsResultModel: InstitutionDocumentsResultModel;
  attachmentFiles: any;
  profilePercentage: any;
  progressInfos: any[] = [];
  message: string[] = [];
  previews: string[] = [];
  selectedFiles?: any[];
  apiBaseUrl = environment.apiPublic;
  viewAttachment = 'https://dummyimage.com/318x200/000/fff';
  modalRef: BsModalRef;
  selectedPdf: any;
  captionPdf = 'Choose a PDF';

  // members
  homeEX4Tab: any = true;
  profileEX4Tab: any;
  // tslint:disable-next-line:variable-name
  user_type: any;
  institutions: string[] = [];
  qualificationNames: string[] = [];
  // tslint:disable-next-line:variable-name
  active_tab = 'institution';
  constructor(
    public apiService: ApiService,
    private notify: NotificationService,
    public authService: AuthService,
    public uploadService: FileUploadService,
    public sanitizer: DomSanitizer,
    public modalService: BsModalService,
    private renderer: Renderer2,
    private institutionService: InstitutionService,
    private qualificationNameService: QualificationNameService
  ) {
    this.profilePercentage = 5;
    this.InstitutionDocumentsResultModel = new InstitutionDocumentsResultModel(null, null);
    this.user_type = localStorage.getItem('user_type');
  }

  ngOnInit(): void {
    this.loadUser();
    console.log(this.institution);
  }

  loadUser(): void {
    this.authService.fetchUser().then((user) => {
      this.user = this.authService.getUser();
      this.profileModel = this.authService.getProfileModel();
      this.institutionModel = new InstitutionModel(this.institution, this.profileModel.uuid);
      this.institutionResults = this.authService.getInstitutionResults();
      this.InstitutionDocumentsResultModel = new InstitutionDocumentsResultModel(null, null);
    });
    if (this.user_type !== 'STUDENT') {
      this.institutionModel = new InstitutionModel(this.institution);
    }
  }
  saveInstitution() {
    this.apiService.post_private('institution_results/store', this.institutionModel).then((response) => {
      if (response?.status === 'success') {
        this.institution = response.institution_results;
        Swal.fire('Institution Saved!', response?.message || 'Nice! Your institution has been safely saved!', 'success');
      } else {
        this.notify.confirmDialog(
          'Some Error Occurred. Do You Want to reload your profile and try again?',
          'Add Institution',
          'error'
        );
      }
    }, (error) => {
      this.notify.confirmDialog(
        'Some Error Occurred. Do You Want to reload your profile and try again?',
        'Add Institution',
        'error'
      );
    });
  }
  updateInstitution() {
    this.apiService.post_private('institution_results/update', this.institutionModel).then((response) => {
      if (response?.status === 'success') {
        Swal.fire('Institution Updated!', response?.message || 'Nice! Your institution has been safely updated!', 'success');
      } else {
        this.notify.confirmDialog(
          'Some Error Occurred. Do You Want to reload your profile and try again?',
          'Update Institution',
          'error'
        );
      }
    }, (error) => {
      this.notify.confirmDialog(
        'Some Error Occurred. Do You Want to reload your profile and try again?',
        'Update Institution',
        'error'
      );
    });
  }
  upload(idx: number, file: File): void {
    this.progressInfos[idx] = { value: 0, fileName: file.name };
    if (file) {

      const formData = new FormData();
      const notify = this.notify;
      formData.append('file', file);
      formData.append('document_name', this.InstitutionDocumentsResultModel.document_name);
      formData.append('institution_uuid', this.institution.uuid);
      // tslint:disable-next-line:max-line-length
      this.uploadService.uploadRelated(file, 'institution_document_results/store', this.InstitutionDocumentsResultModel.institution_uuid, formData).subscribe(
        (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfos[idx].value = Math.round((100 * event.loaded) / event.total);
          } else if (event instanceof HttpResponse) {
            this.institution = event.body.institution_results;
            console.log(event.body.institution_results);
            Swal.fire('Upload Successful Upload!', 'File Upload', 'success');
            this.resetUploader();
          }
        }, (err: any) => {
          this.progressInfos[idx].value = 0;
          Swal.fire('Could not upload the file: ' + file.name, 'Upload Error', 'error');
        }
      );
    }
  }
  resetUploader() {
    this.message = [];
    this.previews = [];
    this.progressInfos = [];
    this.selectedFiles = [];
    this.loadUser();
  }
  openViewFileModal(template6: TemplateRef<any>, url) {
    this.viewAttachment = url;
    this.modalRef = this.modalService.show(
      template6,
      Object.assign({}, { class: 'gray modal-xl' })
    );
  }
  deleteDocument(uuid) {
    this.notify.confirmDialog('Do you want to delete this file?', () => {
      this.destroyFile(uuid);
    });
  }
  destroyFile(uuid) {
    this.apiService.get_private('institution_document_results/delete/' + uuid).then( data => {
      this.institution = data.institution_results;
      Swal.fire('File Deleted Successfully!', 'File Delete', 'success');
      this.loadUser();
    });
  }
  selectFiles(event: any): void {
    this.message = [];
    this.previews = [];
    this.progressInfos = [];
    this.selectedFiles = event.target.files;
    if (this.selectedFiles && this.selectedFiles[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.previews.push(e.target.result);
        this.selectedPdf = reader.result.toString();
      };
      const pdfToUpload = this.selectedFiles[0];
      reader.readAsDataURL(pdfToUpload);
      this.captionPdf = event.target.files[0].name;
    }
  }
  uploadFiles(): void {
    this.message = [];
    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.upload(i, this.selectedFiles[i]);
      }
    }
  }
  openUploadFileModal(template6: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template6,
      {
        class: 'gray modal-md modal-dialog-centered',
        backdrop: 'static'
      }
    );
  }
  closeModal() {
    this.renderer.addClass(document.body, 'enable-scroll');
    this.modalService.hide(this.institution.modal_number);
  }
  // tslint:disable-next-line:variable-name
  onTabEX4(number) {
    this.homeEX4Tab = false;
    this.profileEX4Tab = false;

    if (number === '1') {
      this.homeEX4Tab = true;
      this.active_tab = 'institution';
    } else if (number === '2') {
      this.profileEX4Tab = true;
      this.active_tab = 'documents';
    }
  }
  onInstitutionTypeChange() {
    if (this.institutionModel.institution_type === 'University') {
      this.institutionService.getUniversities().subscribe(
          (universities: string[]) => {
            // @ts-ignore
            this.institutions = universities.institutions;
          },
          (error) => {
            console.error('Error fetching universities:', error);
          }
      );
    } else if (this.institutionModel.institution_type === 'College') {
      this.institutionService.getColleges().subscribe(
          (colleges: string[]) => {
            // @ts-ignore
            this.institutions = colleges?.institutions;
          },
          (error) => {
            console.error('Error fetching colleges:', error);
          }
      );
    } else {
      this.institutions = [];
    }
  }

  onQualificationTypeChange() {
    this.qualificationNameService.getQualificationNames(this.institutionModel.qualification_type).subscribe(
        (qualificationNames: string[]) => {
          // @ts-ignore
          this.qualificationNames = qualificationNames.qualification_names;
        },
        (error) => {
          console.error('Error fetching universities:', error);
        }
    );
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-xl',
      backdrop: 'static',
    });
  }
}
