import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {NotificationService} from '../notification.service';

@Injectable({
  providedIn: 'root'
})
export class BursaryService {
  baseUrl: any = '';

  constructor(
    private router: Router,
    private http: HttpClient,
    private notify: NotificationService,
  ) {
    this.baseUrl = environment.baseUrl;
  }

  public getBursaries(): Observable<any[]> {
    console.log('Getting Bursaries');
    return this.http.get<any[]>(`${this.baseUrl}opportunity/all`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  public getBursary(uuid): Observable<any[]> {
    console.log('Getting Bursary by Uuid');
    return this.http.get<any[]>(`${this.baseUrl}opportunity/` + uuid)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  public async deleteBursary(uuid): Promise<Observable<any[]>> {
    console.log('Deleting Bursary');
    return await new Promise<any>((resolve, reject) => {
      const header = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      };
      const param = this.JSON_to_URLEncoded({uuid});
      return this.http.post(`${this.baseUrl}opportunity/delete/${uuid}`, param, header).subscribe((data) => {
        resolve(data);
      }, error => {
        this.notify.errorBasicNotification(error.error.message, error.statusText);
        reject(error);
      });
    });
  }

  public getPartnerOpportunities(uuid): Observable<any[]> {
    console.log('Getting Partners Opportunities');
    return this.http.get<any[]>(`${this.baseUrl}opportunity/opportunity/` + uuid)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  public getPartnerOpportunitiesByStatus(uuid: string, status: string): Observable<any[]> {
    console.log('Getting Partners Opportunities');
    return this.http.get<any[]>(`${this.baseUrl}opportunity/partner_opportunity_by_status/` + uuid + `/` + status)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  public getFilteredOpportunities(filterString = '/opportunity/all'): Observable<any[]> {
    console.log('Getting All Opportunities');
    // tslint:disable-next-line:max-line-length
    return this.http.get<any[]>(`${this.baseUrl}opportunity${filterString}`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  JSON_to_URLEncoded(element: any, key?: any, list?: any) {
    // tslint:disable-next-line:variable-name
    const new_list = list || [];
    if (typeof element === 'object') {
      // tslint:disable-next-line:forin
      for (const idx in element) {
        this.JSON_to_URLEncoded(
          element[idx],
          key ? key + '[' + idx + ']' : idx,
          new_list
        );
      }
    } else {
      new_list.push(key + '=' + encodeURIComponent(element));
    }
    return new_list.join('&');
  }

  private handleError(error: any) {
    console.error('An error occurred:', error);
    // Handle the error and possibly throw a custom exception
    throw error;
  }

  public getCompanies(): Observable<any[]> {
    console.log('Getting Partners');
    return this.http.get<any[]>(`${this.baseUrl}partner/all`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  // tslint:disable-next-line:variable-name
  public getPartnerApplicationsPerOpportunity(opportunity_uuid, status): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}application/opportunity_applications/` + opportunity_uuid + `/` + status)
        .pipe(
            // catchError(this.handleError) // Handle errors if necessary
        );
  }

  // tslint:disable-next-line:variable-name
  public getPartnerFirstOpportunity(partner_uuid): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}opportunity/first_opportunity/` + partner_uuid)
        .pipe(
            // catchError(this.handleError) // Handle errors if necessary
        );
  }

  // tslint:disable-next-line:variable-name
  public getProvincesCount(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}province/count`)
        .pipe(
            // catchError(this.handleError) // Handle errors if necessary
        );
  }

  public getGeographicsCount(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}smart-filter/geographics`)
        .pipe(
            // catchError(this.handleError) // Handle errors if necessary
        );
  }

  public getEthnicGroupsCount(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}smart-filter/ethnic-groups`)
        .pipe(
            // catchError(this.handleError) // Handle errors if necessary
        );
  }

  public getApplicationStatusesCount(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}smart-filter/application-statuses`)
        .pipe(
            // catchError(this.handleError) // Handle errors if necessary
        );
  }

  public getDisabilityCount(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}smart-filter/with-disability`)
        .pipe(
            // catchError(this.handleError) // Handle errors if necessary
        );
  }

  public gender(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}smart-filter/gender`)
        .pipe(
            // catchError(this.handleError) // Handle errors if necessary
        );
  }

  public getProfilesCount(status, partner): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}profile/by_status/${status}/${partner}`)
        .pipe(
            // catchError(this.handleError) // Handle errors if necessary
        );
  }
}
