import {Component, Input, OnInit, TemplateRef, Renderer2} from '@angular/core';
import {SchoolDetailsModel} from '../../../../../models/school-details.model';
import Swal from 'sweetalert2';
import {ApiService} from '../../../../../services/api.service';
import {NotificationService} from '../../../../../services/notification.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {MatricResultsModel} from '../../../../../models/matric-results.model';
import {AuthService} from '../../../../../services/auth.service';
import {Profile} from '../../../../../models/profile.model';

@Component({
  selector: 'app-school-details',
  templateUrl: './school-details.component.html',
  styleUrls: ['./school-details.component.scss']
})
export class SchoolDetailsComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  @Input() school_details: any;
  // tslint:disable-next-line:variable-name
  @Input() view_type: string;
  // tslint:disable-next-line:variable-name
  @Input() add_button = true;
  // tslint:disable-next-line:variable-name
  school_details_model: SchoolDetailsModel;
  // tslint:disable-next-line:variable-name
  profile_model: Profile;
  // tslint:disable-next-line:variable-name
  matric_results_model: MatricResultsModel;
  modalRef: BsModalRef;

  // members
  homeEX4Tab: any = true;
  profileEX4Tab: any;
  // tslint:disable-next-line:variable-name
  user_type: any;
  // tslint:disable-next-line:variable-name
  active_tab = 'school details';

  constructor(
    public apiService: ApiService,
    private notify: NotificationService,
    public modalService: BsModalService,
    public authService: AuthService,
    private renderer: Renderer2
  ) {
    this.user_type = localStorage.getItem('user_type');
    this.school_details_model = new SchoolDetailsModel(null, null);
  }

  ngOnInit(): void {
    this.loadUser();
  }
  saveSchoolDetails() {
    this.apiService.post_private('school_details/store', this.school_details_model).then((response) => {
      if (response?.status === 'success') {
        this.school_details = response.school_details;
        this.school_details_model = new SchoolDetailsModel(this.school_details, this.profile_model.uuid);
        this.matric_results_model = new MatricResultsModel(null, this.school_details.uuid);
        Swal.fire('School Details Saved!', response?.message || 'Nice! Your school has been safely saved!', 'success');
      } else {
        this.notify.confirmDialog(
          'Some Error Occurred. Do You Want to reload your profile and try again?',
          'Add School Details',
          'error'
        );
      }
    }, (error) => {
      console.log(error);
      this.notify.confirmDialog(
        'Some Error Occurred. Do You Want to reload your profile and try again?',
        'Add School Details',
        'error'
      );
    });
  }
  updateSchoolDetails() {
    this.apiService.post_private('school_details/update', this.school_details_model).then((response) => {
      if (response?.status === 'success') {
        Swal.fire('School Details Updated!', response?.message || 'Nice! Your school details has been safely updated!', 'success');
      } else {
        Swal.fire('School Details Failed To Update!', response?.message, 'error');
      }
    }, (error) => {
      this.notify.confirmDialog(
        'Some Error Occurred. Do You Want to reload your profile and try again?',
        'Update School Details',
        'error'
      );
    });
  }
  openMatricResultsModal(template6: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template6,
      {
        class: 'gray modal-md modal-dialog-centered',
        backdrop: 'static'
      }
    );
  }
  deleteMatricResult(uuid) {
    this.notify.confirmDialog('Do you want to delete this record?', () => {
      this.destroyRecord(uuid);
    });
  }
  destroyRecord(uuid) {
    this.apiService.get_private('matric_results/delete/' + uuid).then( data => {
      const modal_number = this.school_details.modal_number;
      this.school_details = data.school_details;
      this.school_details.modal_number = modal_number;
      Swal.fire('Record Deleted Successfully!', 'Results Delete', 'success');
    });
  }
  saveMatricResults() {
    this.apiService.post_private('matric_results/store', this.matric_results_model).then((response) => {
      console.log(response);
      if (response?.status === 'success') {
        this.school_details = response.school_details;
        this.matric_results_model = new MatricResultsModel(null, this.school_details.uuid);
        Swal.fire('Matric Results Saved!', response?.message || 'Nice! Your matric results has been safely saved!', 'success');
      } else {
        this.notify.confirmDialog(
          'Some Error Occurred. Do You Want to reload your profile and try again?',
          'Save Matric Results',
          'error'
        );
      }
    }, (error) => {
      console.log(error);
      this.notify.confirmDialog(
        'Some Error Occurred. Do You Want to reload your profile and try again?',
        'Save Matric Results',
        'error'
      );
    });
  }
  loadUser(): void {
    this.authService.fetchUser().then((user) => {
      this.profile_model = this.authService.getProfileModel();
      this.school_details_model = new SchoolDetailsModel(this.school_details, this.profile_model.uuid);
      this.matric_results_model = new MatricResultsModel(null, this?.school_details?.uuid);
    });
    if (this.user_type === 'ADMIN') {
      this.school_details_model = new SchoolDetailsModel(this.school_details, '');
    }
  }

  // tslint:disable-next-line:variable-name
  onTabEX4(number) {
    this.homeEX4Tab = false;
    this.profileEX4Tab = false;

    if (number === '1') {
      this.homeEX4Tab = true;
      this.active_tab = 'school details';
    } else if (number === '2') {
      this.profileEX4Tab = true;
      this.active_tab = 'matric results';
    }
  }
  closeModal(): void {
    this.renderer.addClass(document.body, 'enable-scroll');
    this.modalService.hide(this.school_details.modal_number);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-xl',
      backdrop: 'static',
    });
  }

}
