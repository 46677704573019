import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OpportunityService {
  baseUrl: any = '';

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.baseUrl = environment.baseUrl;
  }

  public getAllPublishedOpportunities(): Observable<any[]> {
    console.log('Getting All Published Opportunities');
    // tslint:disable-next-line:max-line-length
    return this.http.get<any[]>(`${this.baseUrl}opportunity/students_opportunity_by_status/published/${localStorage.getItem('profile_uuid')}`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }
  public getOpportunitiesByStatusAndType(status, type): Observable<any[]> {
    console.log('Getting Opportunities By Status And Type');
    // tslint:disable-next-line:max-line-length
    return this.http.get<any[]>(`${this.baseUrl}opportunity/students_opportunity_by_status_and_type/${status}/${type}/${localStorage.getItem('profile_uuid')}`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }
  public getAllOpportunities(): Observable<any[]> {
    console.log('Getting All Opportunities');
    // tslint:disable-next-line:max-line-length
    return this.http.get<any[]>(`${this.baseUrl}opportunity/all`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  private handleError(error: any) {
    console.error('An error occurred:', error);
    // Handle the error and possibly throw a custom exception
    throw error;
  }

  public getCompanies(): Observable<any[]> {
    console.log('Getting Partners');
    return this.http.get<any[]>(`${this.baseUrl}partner/all`)
      .pipe(
        // catchError(this.handleError) // Handle errors if necessary
      );
  }

  // tslint:disable-next-line:variable-name
  getPartnersOpportunitiesByType(company_uuid: string, type: string) {
    // tslint:disable-next-line:max-line-length
    return this.http.get<any[]>(`${this.baseUrl}opportunity/partner_opportunity_by_type/${company_uuid}/${type}`)
        .pipe(
            // catchError(this.handleError) // Handle errors if necessary
        );
  }
}
